import { Cog6ToothIcon, FireIcon, BoltIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { BatchCarousel } from "../../../components/BatchCarousel";
import { H2Heading, ArticleTitle } from "../../../components/Headers";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';
import { Loading } from "../../../components/Loading";
import { PageBody } from "../../../components/PageBody";
import { displayPercentage } from "../../../helpers";
import { basePlayerAttributes } from "./basePlayerAttributes";
import { batchSimulateFoulGame } from "./batchSimulateGame";
import { Accordion } from "./components/Accordion";
// import { BatchAccuracySummary } from "./components/BatchAccuracySummary";
import { GameShotSummary } from "./components/GameShotSummary";
import { PlayerAttributes } from "./components/PlayerAttributes";
import { QuoteBlock } from "./components/QuoteBlock";
import { Key, ShotByShotReportFouls } from "./components/ShotByShotReport";
import { ShotOverUnder } from "./components/ShotOverUnder";
import { Histogram } from "./graphs/Histogram";
import { ViolinDistribution } from "./graphs/ViolinDistribution";


const median = arr => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};


// Simulate a single shot with foul chance
const takeShotFoul = (accuracyPercentage, foulPercentage, foulMakeBlackPercentage) => {
	// Outcome = [madeShot, wasFoul, madeBlack]
	let shotOutcome = [false, false, false];

	// Does the player make the ball?
	if (Math.random() < accuracyPercentage) {
		shotOutcome[0] = true;
	};

	// Does the player foul?
	if (Math.random() < foulPercentage) {
		shotOutcome[1] = true;

		// Player has fouled, did they make the black as well?
		if (Math.random() < foulMakeBlackPercentage) {
			shotOutcome[2] = true;
		}
	};

	return shotOutcome;
};


// Given a player, return the opponent
const opponentPlayer = (player) => {
	return player === 'player1' ? 'player2' : 'player1';
};


// scores = {'player1': int, 'player2': int}
const updateScores = (
	scores,
	shootingPlayer,
	lastShotOutcome,
	lastShotFoul,
	lastShotFoulMadeBlack
) => {
	// The shot was made
	if (lastShotOutcome) {
		// On the black and foul
		if (scores[shootingPlayer] === 7 && lastShotFoul) {
			scores[opponentPlayer(shootingPlayer)] = 10;
		} else {
			// Increase score
			scores[shootingPlayer] += scores[shootingPlayer] === 7 ? 3 : 1;
		};
	};

	// Losing the game on foul
	if (lastShotFoul && lastShotFoulMadeBlack) {
		scores[opponentPlayer(shootingPlayer)] = 10;
	};

	return scores;
};


// [shootingPlayer, tableVisits]
const handleVisits = (
	shootingPlayer,
	playerTableVisits,
	lastShotOutcome,
	lastShotFoul
) => {
	// Give two visits to opponent if shooting player fouled
	if (lastShotFoul) {
		return [opponentPlayer(shootingPlayer), 2];
	};

	// If this shot was the first post-foul
	if (playerTableVisits === 2) {
		return [shootingPlayer, 1];
	};

	// Continue the visit if the shot was made
	if (lastShotOutcome) {
		return [shootingPlayer, 1];
	};

	// Else swap player at the table
	return [opponentPlayer(shootingPlayer), 1];
};


// Give attributes object structure, simulate full game
const simulateGame = (playerAttributes, startingPlayer) => {
	// Store the shots of the entire game
	let gameShots = [];

	// Track how many visits the player has at the table
	let playerTableVisits = 1;
	let shootingPlayer = startingPlayer;

	// Track scores
	let scores = { 'player1': 0, 'player2': 0 };

	// Last shot outcome
	let lastShotOutcome = false;
	let lastShotFoul = false;
	let lastShotFoulMadeBlack = false;

	// Break
	let breakSplitBenefit = 0;
	let breakSplitDuration = 0;
	let breakOutcome = null;

	// Track accuracy changes
	let nextShotAccuracy = 0;

	// Break
	[lastShotOutcome, lastShotFoul, lastShotFoulMadeBlack] = takeShotFoul(
		playerAttributes[shootingPlayer]['break']['make'],
		playerAttributes[shootingPlayer]['break']['foul'],
		0
	);

	scores = updateScores(
		scores,
		shootingPlayer,
		lastShotOutcome,
		lastShotFoul,
		lastShotFoulMadeBlack
	);

	// Store break outcome
	breakOutcome = lastShotOutcome;

	// Push shot details to game shots
	gameShots.push([
		shootingPlayer,
		lastShotOutcome,
		scores['player1'],
		scores['player2'],
		playerAttributes[shootingPlayer]['break']['make'],
		true,
		breakSplitBenefit,
		lastShotFoul,
		lastShotFoulMadeBlack
	]);

	// Update break benefits
	breakSplitBenefit += playerAttributes[shootingPlayer]['break']['splitBenefit'];
	breakSplitDuration += playerAttributes[shootingPlayer]['break']['splitDuration'];

	// Calculate who should be playing, and how many visits they get
	[shootingPlayer, playerTableVisits] = handleVisits(
		shootingPlayer,
		playerTableVisits,
		lastShotOutcome,
		lastShotFoul
	);

	// While neither player has won, continue playing
	while (playerTableVisits > 0 & scores['player1'] < 10 & scores['player2'] < 10) {
		nextShotAccuracy = playerAttributes[shootingPlayer]['regular']['make'];
		nextShotAccuracy += breakSplitDuration > 0 ? breakSplitBenefit : 0;

		// Outcome
		[lastShotOutcome, lastShotFoul, lastShotFoulMadeBlack] = takeShotFoul(
			nextShotAccuracy,
			playerAttributes[shootingPlayer]['regular']['foul'],
			playerAttributes[shootingPlayer]['foul']['makeBlack']
		);

		// Update score - will handle immediate foul loss
		scores = updateScores(
			scores,
			shootingPlayer,
			lastShotOutcome,
			lastShotFoul,
			lastShotFoulMadeBlack
		);

		// Push result of shot, and resulting score
		gameShots.push([
			shootingPlayer,
			lastShotOutcome,
			scores['player1'],
			scores['player2'],
			playerAttributes[shootingPlayer]['regular']['make'],
			false, // isBreak
			breakSplitDuration > 0 ? breakSplitBenefit : 0, // splitBenefit
			lastShotFoul,
			lastShotFoulMadeBlack
		]);

		// Calculate who should be playing, and how many visits they get
		[shootingPlayer, playerTableVisits] = handleVisits(
			shootingPlayer,
			playerTableVisits,
			lastShotOutcome,
			lastShotFoul
		);

		breakSplitDuration -= 1;
	};

	// We could take top streaks here and store that information as well
	let player1TopStreak = Math.max(...gameShots
		.filter(shot => shot[0] === 'player1')
		.map(shot => shot[1] ? 1 : 0)
		.reduce((res, n) =>
			// If n is true, increment the final position by one
			// eslint-disable-next-line
			(n ? res[res.length - 1]++ : res.push(0), res)
			, [0]));

	let player2TopStreak = Math.max(...gameShots
		.filter(shot => shot[0] === 'player2')
		.map(shot => shot[1] ? 1 : 0)
		.reduce((res, n) =>
			// If n is true, increment the final position by one
			// eslint-disable-next-line
			(n ? res[res.length - 1]++ : res.push(0), res)
			, [0]));

	const output = {
		'score': scores['player1'] + " - " + scores['player2'],
		'winner': scores['player1'] === 10 ? 'player1' : 'player2',
		'breakPlayer': startingPlayer,
		'breakOutcome': breakOutcome,
		'shots': gameShots,
		'totalShots': gameShots.length,
		'player1Score': scores['player1'],
		'player1Shots': gameShots.filter(shot => shot[0] === 'player1').length,
		'player1ShotsMade': gameShots.filter(shot => shot[0] === 'player1' & shot[1]).length,
		'player1TopStreak': player1TopStreak,
		'player1Fouls': gameShots.filter(shot => shot[0] === 'player1' & shot[7]).length,
		'player2Score': scores['player2'],
		'player2Shots': gameShots.filter(shot => shot[0] === 'player2').length,
		'player2ShotsMade': gameShots.filter(shot => shot[0] === 'player2' & shot[1]).length,
		'player2TopStreak': player2TopStreak,
		'player2Fouls': gameShots.filter(shot => shot[0] === 'player2' & shot[7]).length,
	};

	return output;
};


const displayDecimalOdds = ( percentageOdds ) => {
	return (1/percentageOdds).toFixed(2);
};

// This is the level we should update attributes, and then re-run the simulations
export const FoulSimulation = () => {
	const batchSize = 850;

	// Handle all player attributes at this level
	// This is the master set of attributes
	const [playerAttributes, updatePlayerAttributes] = useState(basePlayerAttributes);
	const [simulatedGame, updateSimulatedGame] = useState(null);
	const [batchSimulations, updateBatchSimulations] = useState({});
	const [gameLengthsPlayer, setGameLengthsPlayer] = useState('all');

	// Fill on first mount
	useEffect(() => {
		updateSimulatedGame(simulateGame(playerAttributes, Math.random() < 0.5 ? 'player1' : 'player2'));
		updateBatchSimulations(batchSimulateFoulGame(simulateGame, batchSize, playerAttributes));
		// eslint-disable-next-line
	}, []);

	// Need to pass a whole new set of attributes here
	const adjustPlayerAttributes = (player, newAttributes) => {
		playerAttributes[player] = newAttributes;
		updatePlayerAttributes({...playerAttributes});

		runSingleSimulation();
		runBatchSimulations();
	};

	const runBatchSimulations = () => {
		const simulatedBatch = batchSimulateFoulGame(simulateGame, batchSize, playerAttributes);
		updateBatchSimulations(simulatedBatch);
	};

	const runSingleSimulation = () => {
		updateSimulatedGame(simulateGame(playerAttributes, Math.random() < 0.5 ? 'player1' : 'player2'));
	};

	if (simulatedGame === null | batchSimulations === null) { // add batch simulations back here when implemented
		return <Loading></Loading>
	};

	return (
		<PageBody>
			<Helmet>
				<title>English Pool Monte Carlo Simulation - Foul Impact | VizBadger</title>
				<meta name="description" content="Simulate pool games using player attributes to predict expected results given player skill levels. Model the impact of fouling in billiard games and pool using Monte Carlo simulations."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/foul-impact" />
			</Helmet>

			<PoolSimulationHomeButton />
            <ArticleTitle title="Stage 3: Foul Impact" />
			<QuoteBlock quoteText="A reckless player that fouls often will give too many chances to their opponent." />
			<div className="text-sm sm:text-base text-center sm:text-left mb-8">
				<p className="mb-3">Introducing fouls introduces a few new and key elements. A player's 'recklessness', perhaps misfortune, but also the concept of two visits to the table, and the ability to lose the game in a new way.</p>
				<p>Following a foul, the opponent gets two visits to the table. They don't have to make the first shot to get a second. How you approach these shots is a personal preference. Some simply play as though they have one shot, others try to 'set up' the subsequent shots, or split out more difficult balls to improve their position for the second visit and aren't concerned with making the first shot. Table context once again drives a player's decision here ultimately, but I will enforce that a player simply takes two 'regular' shots.</p>
			</div>
			<div className="text-sm sm:text-base text-center sm:text-left">
				<H2Heading>Player Attributes</H2Heading>
				<p className="my-2">The complexity of our player attributes now includes foul chances. This would be based on historic data for each player. While this is generalised, it does capture a new element to the simulation. You can edit these and save the change to see how it impacts the simulated games.</p>
				<PlayerAttributes
					playerAttributes={playerAttributes}
					playerNames={["Player 1", "Player 2"]}
					playerKeys={["player1", "player2"]}
					updatePlayerAttributes={adjustPlayerAttributes} // Update the 'base' attributes, requires player key
				/>
			</div>

			<Accordion
				title="Simulate A Single Game"
				defaultActive={true}
			>
				<div className="relative">
					<div className="flex flex-col text-center md:text-left text-sm sm:text-base items-center justify-center mb-2">
						<p className="w-full mb-2">
							Using known (observed) player attributes we can simulate a game of pool, giving us a final score, accuracy percentages, fouls and shot streaks for a single example game.
						</p>
						<GameShotSummary
							simulatedGame={simulatedGame}
							showFouls={true}
						/>
					</div>
					<div className="flex items-center justify-center sticky top-18 mb-4">
						<button
							className="text-base my-2 flex items-center justify-center bg-badger-green font-bold text-white py-1 px-2 rounded-lg"
							onClick={() => runSingleSimulation()}
						>
							<Cog6ToothIcon className="h-6 w-6 mr-1" />
							Simulate A Single Game
						</button>
					</div>
					<div className="flex items-center justify-center flex-col text-sm sm:text-base text-center sm:text-left">
						<H2Heading>Shot by Shot Report</H2Heading>
						<p className="mb-2">This breakdown shows us how the game unfolded with the outcome of each shot being shown. Try changing the player accuracies (effectively skill level) and see how each simulation changes.</p>
						<Key />
						<ShotByShotReportFouls
							simulatedGame={simulatedGame}
							showBreakPlayer={true}
						/>
					</div>
				</div>
			</Accordion>

			<Accordion
				title={`Batch Simulate ${batchSize} Games`}
				defaultActive={true}
			>
				<div className="relative">
					<div className="flex items-center justify-center sticky top-18 pt-2">
						<button
							className="text-base my-2 flex items-center justify-center bg-badger-purple font-bold text-white py-1 px-2 rounded-lg"
							onClick={() => runBatchSimulations()}
						>
							<Cog6ToothIcon className="h-6 w-6 mr-1" />
							Simulate {batchSize} Games
						</button>
					</div>

					<div className="w-full flex flex-col items-center justify-center mb-8">
						<H2Heading>Overall Wins</H2Heading>
						<p className="text-sm sm:text-base text-center sm:text-left">Player 1: {batchSimulations.playerWins.player1}</p>
						<p className="text-sm sm:text-base text-center sm:text-left">Player 2: {batchSimulations.playerWins.player2}</p>
					</div>

					<div className="w-full flex flex-col items-center justify-center mb-8">
						<H2Heading>Generate Betting Odds</H2Heading>
						<p className="mb-2 text-sm sm:text-base text-center sm:text-left">Using our simulation we have created probabilities of events and outcomes happening; we can use these to create betting odds. I will start with the simplest of applications in the winner market.</p>

						<div className="flex flex-wrap items-center justify-center">
							<div className="flex flex-wrap items-center justify-center mb-2">
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Player</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">P1</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">P2</div>
								</div>
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Wins</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">{batchSimulations.playerWins.player1}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{batchSimulations.playerWins.player2}</div>
								</div>
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Win %</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">{displayPercentage(batchSimulations.playerWins.player1 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2))}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{displayPercentage(batchSimulations.playerWins.player2 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2))}</div>
								</div>
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">'True' Odds</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">{displayDecimalOdds(batchSimulations.playerWins.player1 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2))}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{displayDecimalOdds(batchSimulations.playerWins.player2 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2))}</div>
								</div>
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">1% Margin</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">{displayDecimalOdds((batchSimulations.playerWins.player1 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2) * 1.01))}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{displayDecimalOdds((batchSimulations.playerWins.player2 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2) * 1.01))}</div>
								</div>
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">5% Margin</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">{displayDecimalOdds((batchSimulations.playerWins.player1 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2) * 1.05))}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{displayDecimalOdds((batchSimulations.playerWins.player2 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2) * 1.05))}</div>
								</div>
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">10% Margin</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">{displayDecimalOdds((batchSimulations.playerWins.player1 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2) * 1.1))}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{displayDecimalOdds((batchSimulations.playerWins.player2 / (batchSimulations.playerWins.player1 + batchSimulations.playerWins.player2) * 1.1))}</div>
								</div>
							</div>
						</div>

						<p className="mb-2 text-sm sm:text-base text-center sm:text-left">The addition of 'Margin' in the odds is what bookmakers do to cover variation in results and generate profit. By inflating the implied chance of something happening the odds shorten and the payout decreases for the customer. If you run more simulations you can see the variation in our model, but this is ultimately 'covered' by the addition of a higher margin by the end bookmaker.</p>
					</div>

					<div className="w-full flex flex-col items-center justify-center mb-8">
						<H2Heading>Player Accuracy Distributions</H2Heading>
						<p className="mb-2 text-sm sm:text-base text-center sm:text-left">We know each player's observed accuracies (shown on graph as - - - -), but what is the chance of them achieving that on any given game? Below is each players accuracy in a given game across all the simulations. You see that most games hover around the observed accuracy, but the variation in performances for a given game is clear to see.</p>
						<ViolinDistribution
							chartSettings={{ height: 300, marginLeft: 30, marginBottom: 60 }}
							playerAccuracies={batchSimulations.playerAccuracies}
							player1Accuracy={playerAttributes['player1']['regular']['make'] * 100}
							player2Accuracy={playerAttributes['player2']['regular']['make'] * 100}
						/>
					</div>

					{/* <BatchAccuracySummary
						playerAttributes={playerAttributes}
						batchSimulations={batchSimulations}
					/> */}

					<div className="break-words mb-8">
						<H2Heading>Game Lengths (shots)</H2Heading>
						<p className="mb-2 text-sm sm:text-base text-center sm:text-left">Explore the distribution of game lengths. This shows the variation that we see. However with two players' general skills you can see that it clusters around a single point.</p>
						<p className="mb-2 text-sm sm:text-base text-center sm:text-left">I have marked the <span className="text-badger-blue">median value</span> on the graph, this would be the basis of an over/under betting market for example. Notice the impact on game length depending on which player breaks, this has to be considered.</p>
						<div className="text-xs sm:text-base text-center flex flex-row items-center justify-between border rounded-lg w-full sm:w-2/3 mx-auto mb-4">
							<div
								className={`flex items-center justify-center w-full p-1 ${gameLengthsPlayer === 'all' ? 'bg-badger-green text-white' : 'hover:bg-gray-200 hover:cursor-pointer'}`}
								onClick={() => setGameLengthsPlayer('all')}
							>
								All
							</div>
							<div
								className={`flex items-center justify-center w-full p-1 ${gameLengthsPlayer === 'player1' ? 'bg-badger-green text-white' : 'hover:bg-gray-200 hover:cursor-pointer'}`}
								onClick={() => setGameLengthsPlayer('player1')}
							>
								Player 1 Broke
							</div>
							<div
								className={`flex items-center justify-center w-full p-1 ${gameLengthsPlayer === 'player2' ? 'bg-badger-green text-white' : 'hover:bg-gray-200 hover:cursor-pointer'}`}
								onClick={() => setGameLengthsPlayer('player2')}
							>
								Player 2 Broke
							</div>
						</div>
						<Histogram
							chartSettings={{ height: 240, marginTop: 0, marginBottom: 60, marginRight: 0, marginLeft: 45 }}
							dataArray={batchSimulations.gameLengths.all}
							filteredDataArray={gameLengthsPlayer === 'all' ? batchSimulations.gameLengths.all : gameLengthsPlayer === 'player1' ? batchSimulations.gameLengths.player1 : batchSimulations.gameLengths.player2}
							xLabel="Game Length (total shots)"
							medianValue={median(gameLengthsPlayer === 'all' ? batchSimulations.gameLengths.all : gameLengthsPlayer === 'player1' ? batchSimulations.gameLengths.player1 : batchSimulations.gameLengths.player2)}
						/>
						<ShotOverUnder
							dataArray={gameLengthsPlayer === 'all' ? batchSimulations.gameLengths.all : gameLengthsPlayer === 'player1' ? batchSimulations.gameLengths.player1 : batchSimulations.gameLengths.player2}
							medianValue={median(gameLengthsPlayer === 'all' ? batchSimulations.gameLengths.all : gameLengthsPlayer === 'player1' ? batchSimulations.gameLengths.player1 : batchSimulations.gameLengths.player2)}
						/>
					</div>

					<div>
						<div className="mb-2">
							<H2Heading><BoltIcon className="h-6 w-6 text-badger-yellow" />&nbsp;Player Break Conversion</H2Heading>
							<p className="flex items-center justify-center text-sm sm:text-base text-center md:text-left mt-2">Now with a break element to the game, do we see a difference? Does one player rely on their break?</p>
						</div>
						<div className="flex flex-wrap items-center justify-center">
							<div className="flex flex-wrap items-center justify-center mb-2">
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Winner</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r">P1</div>
									<div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-b border-r pb-2">Win %</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">P2</div>
									<div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-r">Win %</div>
								</div>
							</div>

							<div className="flex flex-wrap items-center justify-center mb-2">
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center border-r">Break</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r flex">{batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer === 'player1').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer === 'player1').length}</span></div>
									<div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-b border-r pb-2">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer === 'player1').length / batchSimulations.simulations.filter(x => x.breakPlayer === 'player1').length)}</div>
									<div className="flex justify-center items-center h-8 px-2 italic border-r pt-2">{batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer === 'player2').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer === 'player2').length}</span></div>
									<div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-r">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer === 'player2').length / batchSimulations.simulations.filter(x => x.breakPlayer === 'player2').length)}</div>
								</div>
							</div>
							<div className="flex flex-wrap items-center justify-center mb-2">
								<div
									className="flex flex-col text-center my-2"
								>
									<div className="flex justify-center items-center h-8 px-2 text-sm italic text-gray-500 text-center">Didn't Break</div>
									<div className="flex justify-center items-center h-8 px-2 italic">{batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer !== 'player1').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer !== 'player1').length}</span></div>
									<div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic border-b pb-2">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player1' & x.breakPlayer !== 'player1').length / batchSimulations.simulations.filter(x => x.breakPlayer !== 'player1').length)}</div>
									<div className="flex justify-center items-center h-8 px-2 italic pt-2">{batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer !== 'player2').length}&nbsp;<span className="text-xs text-gray-500">/{batchSimulations.simulations.filter(x => x.breakPlayer !== 'player2').length}</span></div>
									<div className="flex justify-center items-center h-8 px-2 text-xs text-gray-500 italic">{displayPercentage(batchSimulations.simulations.filter(x => x.winner === 'player2' & x.breakPlayer !== 'player2').length / batchSimulations.simulations.filter(x => x.breakPlayer !== 'player2').length)}</div>
								</div>
							</div>
						</div>
						<div className="mb-6">
							<p className="flex items-center justify-center text-sm sm:text-base text-center md:text-left mt-2">Note here the benefit that breaks have in general, and the percentage of games that are won by the player that breaks. If your break is exceptional, it doesn't necessarily make up for your late game. Keep simluating and see the benefit that the player who breaks gets. If your break improves your position most of the time, then it benefits you. If you cannot win directly from your good break then improved overall accuracy allows for players to stage a comeback.</p>
						</div>
					</div>
					<div>
						<div className="mb-2">
							<H2Heading><FireIcon className="h-6 w-6 text-badger-orange" />&nbsp;Top Streaks</H2Heading>
							<p className="flex items-center justify-center text-sm sm:text-base text-center md:text-left mt-2">You can see in how many matches a player managed to put together a long streak. A slight shift in accuracy allows a player to avoid 'low streak' games and hugely increases the chance of them getting at least a streak of 4 at some point in the game.</p>
						</div>
						<div className="flex flex-wrap items-center justify-center mb-8">
							<div
								className="flex flex-col text-center my-2"
							>
								<div className="flex justify-center items-center h-8 w-8 text-sm italic text-gray-500 text-center border-r">-</div>
								<div className="flex justify-center items-center h-8 w-8 italic border-r">P1</div>
								<div className="flex justify-center items-center h-8 w-8 text-xs text-gray-500 italic border-b border-r pb-2">%</div>
								<div className="flex justify-center items-center h-8 w-8 italic border-r pt-2">P2</div>
								<div className="flex justify-center items-center h-8 w-8 text-xs text-gray-500 italic border-r">%</div>
							</div>
							{[0, 1, 2, 3, 4, 5, 6, 7, 8].map((shot, i) =>
								<div
									className="flex flex-col text-center my-2"
									key={i}
								>
									<div className="h-8 w-8 flex justify-center items-center text-sm italic text-gray-500">{i}</div>
									<div className="h-8 w-8 flex justify-center items-center text-sm">{batchSimulations.simulations.filter(simul => simul.player1TopStreak === i).length}</div>
									<div className="h-8 w-8 flex justify-center text-xs italic text-gray-500 items-center border-b pb-2">{displayPercentage(batchSimulations.simulations.filter(simul => simul.player1TopStreak === i).length / batchSimulations.simulations.length, false)}</div>
									<div className="h-8 w-8 flex justify-center items-center text-sm pt-2">{batchSimulations.simulations.filter(simul => simul.player2TopStreak === i).length}</div>
									<div className="h-8 w-8 flex justify-center text-xs italic text-gray-500 items-center">{displayPercentage(batchSimulations.simulations.filter(simul => simul.player2TopStreak === i).length / batchSimulations.simulations.length, false)}</div>
								</div>
							)}
						</div>
					</div>

					<div className="mt-8 text-center">
						<H2Heading>Batch Simulation Results</H2Heading>
						<p className="text-sm sm:text-base text-center sm:text-left mb-2">See a match summary of the first 50 simulated games.</p>
						<BatchCarousel
							slides={[...Array(batchSimulations.simulations.length).keys()].slice(0, 50)}
							content={batchSimulations.simulations}
						/>
					</div>
				</div>
			</Accordion>
		</PageBody>
	);
};
