export const generateNRoundRobinPairings = (teams, n_rounds = 1) => {
    var rounds = [];

    for (let i = 0; i < n_rounds; i++) {
        rounds = rounds.concat(makeRoundRobinPairings(teams));
    };

    return rounds;
};

const makeRoundRobinPairings = (teams) => {
    const players = [...teams]
    if (players.length % 2 === 1) {
        players.push(null);
    };

    const playerCount = players.length;
    const rounds = playerCount - 1;
    const half = playerCount / 2;

    const tournamentPairings = [];

    const playerIndexes = players.map((_, i) => i).slice(1);

    for (let round = 0; round < rounds; round++) {
        const roundPairings = [];

        const newPlayerIndexes = [0].concat(playerIndexes);

        const firstHalf = newPlayerIndexes.slice(0, half);
        const secondHalf = newPlayerIndexes.slice(half, playerCount).reverse();

        for (let i = 0; i < firstHalf.length; i++) {
            if (players[firstHalf[i]] !== null & players[secondHalf[i]] !== null) {
                roundPairings.push({
                    player_1: players[firstHalf[i]],
                    player_2: players[secondHalf[i]],
                });
            };
        };

        // rotating the array
        playerIndexes.push(playerIndexes.shift());
        tournamentPairings.push(shuffle(roundPairings));
    };

    return tournamentPairings;
};

function shuffle(array) {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    };

    return array;
};
