import { BackwardIcon } from '@heroicons/react/24/outline';
import { useNavigate } from "react-router-dom";
import { ArticleTitle } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';


export const PoolGlossary = () => {
    const navigate = useNavigate();

    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - Glossary | VizBadger</title>
                <meta name="description" content="Simulate and predict pool games using player shot accuracy and break efficiency. Define key terms in the sports model related to predicting the outcome in english pool."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/glossary" />
            </Helmet>
            <PoolSimulationHomeButton />
            <ArticleTitle
                title="English Pool Monte Carlo Simulation Glossary"
            />
            <div
                onClick={() => navigate(-1)}
                className="flex items-center justify-start hover:cursor-pointer rounded py-2 mb-2 text-gray-600 hover:text-badger-blue"
            >
                <BackwardIcon className="h-6 w-6" />
                <p className="p-1">Return to page</p>
            </div>
            <div className="flex flex-col text-sm sm:text-base">
                <p className="text-xl underline mb-1">Frame Scoring</p>
                <p className="mb-2">The scores of each frame are marked as such:</p>
                <p className="mb-2">The winner gets 10 points. This is regardless of how they win (all colours & black/opponent foul on black).</p>
                <p className="mb-2">The losing player gets a point for each colour that they have made i.e. a game that ends with both players on the black ball would end 10-7 as both players would have potted  all 7 of their balls (red/yellow).</p>
                <p className="mb-2"><span className="font-bold">An example game</span>: Player 1 breaks and claims red, Player 2 eventually wins the game/frame and there are 2 reds left on the table at the end. Player 2 gets 10 points for winning, and Player 1 gets 5 points for the 5 red balls that were made (7 minus 2 that were left at the end). The final score is Player 1 "5 - 10" Player 2.</p>
                <p className="mb-2 italic">NB: The reasoning behind this scoring system is that it indicates as to how big the victory was, it was something I started recording in my personal games with friends when building out power ranking systems that weighted larger wins over closer games when ranking us.</p>
            </div>
        </PageBody>
    );
};
