import { ArticleTitle, H2Heading } from "../../components/Headers";
import { PageBody } from "../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { GridDiv, GridDivContainer } from "../../components/GridDiv";
import { Link } from "react-router-dom";
import { BanknotesIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import { ArticleParagraph } from "../../components/Paragraphs";
import { BettingHomeButton } from "../../components/HomeLinks";


export const UnderstandSportsBetting = () => {
    return (
        <PageBody>
            <Helmet>
                <title>Understand Sports Betting: Build Intuition and Avoid Losing | VizBadger</title>
                <meta
                    name="description"
                    content="Learn all about sports betting, accumulators and how to understand value bets."
                ></meta>
                <meta name="keywords" content="Sports betting, Betting accumulators, Betting exchanges, Betting value, Accumulators, Betting guide, Betting UK, Football betting, Betting accumulator strategy, Betting value strategy, Betting odds value, Betting value bets, Value betting strategy"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/betting/understand-sports-betting" />
            </Helmet>

            <BettingHomeButton />

            <ArticleTitle
                title="Understand Sports Betting: Build Intuition and Avoid Losing"
            />

            <ArticleParagraph
                lines={[
                    "Sports betting is commonplace in many parts of the world, but most people don’t really understand how it works or the process behind the odds that you see. Bookmakers make lots of money every day taking bets from customers by offering them odds, or a price, to risk money in return for the chance to win money.",
                    "It is sold as a viable chance of consistently making money, but betting on sports is risky, and even riskier if you don't build an intuition and understanding of what you are looking at.",
                    "Understanding sports betting, odds and pricing will enable you to realise what a good bet looks like, as well as how difficult they are to find. Thinking probabilistically and making a decision on what a good price is alongside the knowledge of how most bookmakers make it even more difficult to win by padding their prices with margin will make you a smarter, more savvy punter.",
                    "This doesn't mean that you have to build statistical models, but understanding the rules of the sports betting game that you're playing can help you control your decision making. Note that I say 'avoid losing'; that should be your first step. Making money requires other factors that I will also write about.",
                ]}
            />

            <H2Heading classes="mb-0">More Sports Betting</H2Heading>
            <GridDivContainer>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="/betting/how-to-maximise-sports-betting-accumulators"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <DocumentTextIcon className="h-7 w-7 mr-2 text-badger-yellow" />
                        <span className="mr-2 text-center text-black w-full">Maximise Betting Accumulators</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="/betting/how-to-find-value-betting-on-sports"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <BanknotesIcon className="h-7 w-7 mr-2 text-badger-green" />
                        <span className="mr-2 text-center text-black w-full">Find Value Betting On Sports</span>
                    </Link>
                </GridDiv>
            </GridDivContainer>
        </PageBody>
    );
};
