import { ChartCanvas } from "./basicGraph";
import { useChartDimensions } from "./useChartDimensions";
import { arc } from "d3-shape";

export const Gauge = ({ groups, chartSettings }) => {
	const [ref, dimensions] = useChartDimensions(chartSettings);

	const squareDimensions = dimensions.boundedHeight < dimensions.boundedWidth ? dimensions.boundedHeight : dimensions.boundedWidth;
	const labelBlockWidth = 7;
	const blockSpacing = 5;
	const textSpacing = 5;
	const textX = dimensions.boundedWidth / 2 - (labelBlockWidth + textSpacing + blockSpacing);
	const labelBlockX = dimensions.boundedWidth / 2 - (labelBlockWidth + blockSpacing);
	const maxArcHeight = squareDimensions / 2;
	const arcWidth = 22;
	const arcSpacing = 7;

	groups.map((group, i) => {
		group['label_y'] = ((i + 1) * arcWidth + ((i) * arcSpacing)) - arcWidth / 2;
		group['label_block_y'] = ((i) * arcWidth) + ((i) * arcSpacing);
		group['min_arc'] = maxArcHeight - (i * arcWidth) - (i * arcSpacing) - arcWidth;
		group['max_arc'] = maxArcHeight - (i * arcWidth) - (i * arcSpacing);
		return null;
	});

	const generateArc = (inner, outer, endPerc) => {
		return arc()
			.innerRadius(inner)
			.outerRadius(outer)
			.startAngle(0)
			.endAngle((3 * Math.PI / 2) * endPerc)
			.cornerRadius(0)();
	};

	return (
		<div ref={ref} className="w-full">
			<ChartCanvas dimensions={dimensions}>
				{groups.map(group => (
					<g key={group.label}>
						<text
							fontSize={15}
							textAnchor="end"
							alignmentBaseline="middle"
							x={textX}
							y={group.label_y}
							fill={group.colour}
						>
							{group.label}
						</text>
						<rect
							x={labelBlockX}
							y={group.label_block_y}
							width={labelBlockWidth}
							height={arcWidth}
							fill={group.colour}
						></rect>
						<g transform={`translate(${dimensions.boundedWidth / 2}, ${maxArcHeight})`}>
							<path
								d={generateArc(group.min_arc, group.max_arc, 1)}
								fill="#bdc3c7"
								opacity={0.5}
							/>
							<path
								d={generateArc(group.min_arc, group.max_arc, group.value)}
								fill={group.colour}
							/>
						</g>
					</g>
				))}
			</ChartCanvas>
		</div>
	);
};
