import axios from 'axios';

export async function getPlayers() {
    return await axios.get("/api/cricket/players");
};

export async function getPlayerInfo(playerName) {
    return await axios.get("/api/cricket/player_detail/" + playerName);
};

export async function getPlayerInnings(playerName) {
    return await axios.get("/api/cricket/player_inning/" + playerName);
};
