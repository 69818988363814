import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { H2Heading, ArticleTitle } from "../../../components/Headers";
import { SportsModelsHomeButton } from "../../../components/HomeLinks";
import { PageBody } from "../../../components/PageBody";
import { ArticleParagraph } from "../../../components/Paragraphs";
import { generateNRoundRobinPairings } from "./utility/fixtureGeneration";


const availablePlayers = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

export const LeagueScheduleGenerator = () => {
    // eslint-disable-next-line
    const [teams, setTeams] = useState([]);
    const [fixtures, setFixtures] = useState([]);
    // eslint-disable-next-line
    const [nTeams, setNTeams] = useState(5);

    useEffect(() => {
        var selectedTeams = availablePlayers.slice(0, nTeams);
        setTeams(selectedTeams)
        setFixtures(generateNRoundRobinPairings(selectedTeams, 1))
    }, [nTeams]);

    return (
        <PageBody>
            <Helmet>
                <title>League Schedule Generation | VizBadger</title>
                <meta name="description" content="Given a set of players or teams, generate a schedule of fixtures so that each team plays each other team any number of times."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, monte carlo simulations, sport monte carlo simulations, sports schedule generator, league fixtures generator, sports schedules, round robin fixture generation"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/league-schedule-generator" />
            </Helmet>
            <SportsModelsHomeButton />
            <ArticleTitle title="League Schedule Generation" />
            <div className="text-sm sm:text-base text-center sm:text-left">
                <H2Heading>Fixture List Generation</H2Heading>
                <ArticleParagraph
                    lines={[
                        "As part of simulating sports, we will inevitably want to expand beyond a single event to simulating an entire league. To do this we are going to have to create a method for generating a series of fixtures to predict given N players that want to take part.",
                        "The question is how can we generate a league fixture list programmatically?",
                        "Round robin events are simple in that you make sure that each player plays every other player an equal amount of times. I've included a simple example of this below; I have made sure that the fixture list within each round is randomised and shuffled to avoid any particular order to play as I am imagining a position where the matches are played in a linear fashion and this should help with making sure that teams don't have to wait too long between games.",
                    ]}
                />
                <div className="text-center mb-4">
                    <div>
                        <p className="italic tracking-tight font-semibold">Teams</p>
                        <p>
                            {teams.map((team, i) => (
                                <span>{team}{i === teams.length - 1 ? '' : ', '}</span>
                            ))}
                        </p>
                    </div>
                    <div className="flex flex-wrap">
                        {fixtures.map((round, i) => (
                            <div className="my-2 w-1/2 sm:w-1/5">
                                <p className="italic tracking-tight font-semibold">Round {i + 1}</p>
                                <div className="mt-1" key={i}>
                                    {round.map((round, j) => (
                                        <p key={`${i} - ${j}`}>{round.player_1} vs. {round.player_2}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="my-2">
                        <p className="italic tracking-tight font-semibold">Linear matches</p>
                        {fixtures.map((round, i) => (
                            <div key={`linear ${i}`}>
                                {round.map((round, j) => (
                                    <p key={`${i} - ${j}`}>{round.player_1} vs. {round.player_2}</p>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ArticleParagraph
                lines={[
                    "If you wanted to expand this further then you can make sure everyone plays each other twice for example. This creates more a 'traditional' football league structure and will be great for establishing which traits are most effective in the long term league when simulating sports.",
                    "With real world examples the schedule would be something already defined i.e. who is going to win the EPL. It does serve as a good grounding for simulating leagues however.",
                ]}
            />
        </PageBody>
    );
};
