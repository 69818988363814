import { Link } from "react-router-dom";
import { CpuChipIcon, ChartBarIcon, MagnifyingGlassIcon, CircleStackIcon, DocumentTextIcon, PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { GridDiv, GridDivContainer } from "../components/GridDiv";
import { PageBody } from "../components/PageBody";
import { PoolIcon } from "../components/Icons";
import { DictionaryDefinition } from "../components/DictionaryDefinition";
import { Helmet } from "react-helmet-async";


export function Home() {
	return (
		<PageBody widthClass="max-w-screen-xl">
			<Helmet>
				<meta name="description" content="Discover the power of data-driven insights with my passion for sports analytics, machine learning, and dynamic visuals. Explore engaging articles and bespoke sports-related web pages that showcase my work and expertise in predictive analytics and sports betting industry. Visit now and share your thoughts to help me further develop my skills."></meta>
			</Helmet>
			<div className="flex flex-col items-center justify-center max-w-screen-xl mx-auto">
				{/* Explorations in data */}
				<h1 className="text-left sm:text-center tracking-tight font-extrabold text-5xl sm:text-6xl p-1 pb-4 sm:px-12 sm:mx-12 sm:pb-12 sm:pt-6 text-gray-800">Data-driven insights using <span className="text-badger-green block-inline">sports analytics</span>, <span className="text-badger-blue block-inline">machine learning</span> and <span className="text-badger-purple block-inline">dynamic visuals</span></h1>
			</div>
			<div className="flex flex-col items-center justify-center max-w-screen-md mx-auto p-1">
				<div className="w-full">
					<p className="pt-2">Explore work spanning a wide range of topics driven by my passion for sports data, predictive analytics, the sports betting industry, and the creation of dynamic, bespoke visualisations; how can you take player statistics and predict the outcome of a pool match? What does it mean when someone says a bet is 'good value'?</p>
					<p className="pt-2">Please take the time to explore and share; any ideas or recommendations are greatly appreciated and required for development (<a href="https://twitter.com/WCanniford" className="text-badger-blue hover:text-badger-green mx-1" target="_blank" rel="noreferrer">
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fab"
							data-icon="twitter"
							className="w-4 inline"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 512 512"
						>
							<path
								fill="currentColor"
								d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
							></path>
						</svg>
					</a>).</p>
				</div>
				<GridDivContainer className="mt-2 sm:mt-0">
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="sports-models"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<PresentationChartLineIcon className="h-7 w-7 mr-2 text-badger-green" />
							<span className="mr-2 text-center text-black w-full">Predictive Sports Modelling</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="betting"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<DocumentTextIcon className="h-7 w-7 mr-2 text-badger-yellow" />
							<span className="mr-2 text-center text-black w-full">Sports Betting</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="cricket"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<MagnifyingGlassIcon className="h-7 w-7 mr-2 text-badger-blue" />
							<span className="mr-2 text-center text-black w-full">Explore Test Match Batting Careers</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="data-visualisation/dynamic-d3-components"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<ChartBarIcon className="h-7 w-7 mr-2 text-badger-purple" />
							<span className="mr-2 text-center text-black w-full">Dynamic D3.js Graph</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="pool/matches"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<PoolIcon classes="h-7 w-7 mr-2 text-badger-red" />
							<span className="mr-2 text-center text-black w-full">Amateur Pool Rankings</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="analytics/designing-analytics-schemas"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<CircleStackIcon className="h-7 w-7 mr-2 text-gray-700" />
							<span className="mr-2 text-center text-black w-full">Designing Analytics Schemas</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="sports-models/english-pool-monte-carlo-simulation"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<CpuChipIcon className="h-7 w-7 mr-2 text-badger-green" />
							<span className="mr-2 text-center text-black w-full">English Pool Monte Carlo Simulation</span>
						</Link>
					</GridDiv>
					<GridDiv
						widthClasses="w-full md:w-1/2"
					>
						<Link
							to="betting/how-to-maximise-sports-betting-accumulators"
							className="sm:w-auto w-full border border-neutral-300 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
						>
							<DocumentTextIcon className="h-7 w-7 mr-2 text-badger-yellow" />
							<span className="mr-2 text-center text-black w-full">Maximise Betting Accumulators</span>
						</Link>
					</GridDiv>
				</GridDivContainer>
				<div className="mt-8 border-t pt-8">
					<DictionaryDefinition />
				</div>
			</div>
		</PageBody>
	);
};
