import { ArticleTitle } from "../../components/Headers";
import { PageBody } from "../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { GridDiv, GridDivContainer } from "../../components/GridDiv";
import { Link } from "react-router-dom";
import { AcademicCapIcon, BanknotesIcon, DocumentTextIcon, QueueListIcon } from "@heroicons/react/24/outline";
import { ArticleParagraph } from "../../components/Paragraphs";


export const BettingHome = () => {
    return (
        <PageBody>
            <Helmet>
                <title>Sports Betting | VizBadger</title>
                <meta
                    name="description"
                    content="Learn all about sports betting, accumulators and how to understand value bets. Improve your knowledge of the system and process to become a better sports bettor."
                ></meta>
                <meta name="keywords" content="Sports betting, Betting accumulators, Betting exchanges, Betting value, Accumulators, Betting guide, Betting UK, Football betting, Betting accumulator strategy, Betting value strategy, Betting odds value, Betting value bets, Value betting strategy"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/betting" />
            </Helmet>

            <ArticleTitle
                title="Sports Betting"
            />

            <ArticleParagraph
                lines={[
                    "Sports betting seems simple, and it is made simple by bookmakers to encourage betting. Sports betting can be fun, but it is also addictive and should be consumed in moderation with a knowledge of the system.",
                    "Below are some articles and interactive pages that outline how sports betting works, and how you can find value in some bets. In understanding the system you can improve your judgement on the risk that you are taking, but remember that the risk is always there.",
                    "These resources are to inform and teach, and are not financial advice; please gamble responsibly and within your own limits by managing your risk and bank."
                ]}
            />

            <GridDivContainer>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="/betting/understand-sports-betting"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <AcademicCapIcon className="h-7 w-7 mr-2 text-badger-purple" />
                        <span className="mr-2 text-center text-black w-full">A Guide to Understanding Sports Betting</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="/betting/sports-betting-bankroll-management-strategies"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <QueueListIcon className="h-7 w-7 mr-2 text-badger-blue" />
                        <span className="mr-2 text-center text-black w-full">Sports Betting Bankroll Management Strategies</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="/betting/how-to-find-value-betting-on-sports"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <BanknotesIcon className="h-7 w-7 mr-2 text-badger-green" />
                        <span className="mr-2 text-center text-black w-full">Find Value Betting On Sports</span>
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="/betting/how-to-maximise-sports-betting-accumulators"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <DocumentTextIcon className="h-7 w-7 mr-2 text-badger-yellow" />
                        <span className="mr-2 text-center text-black w-full">Maximise Betting Accumulators</span>
                    </Link>
                </GridDiv>
            </GridDivContainer>
        </PageBody>
    );
};
