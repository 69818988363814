import { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { AxisHorizontal, AxisLabelHorizontal, AxisLabelVertical, AxisVertical, ChartCanvas, BandedAxisHorizontal } from "./basicGraph";
import { useChartDimensions } from "./useChartDimensions";

// Accessor functions
const dateParser = d3.timeParse("%Y-%m-%d"); // Define helper function to convert date string
// eslint-disable-next-line
const xAccessor = d => dateParser(d.match_start_date);
const xBallsFaced = d => d.balls_faced;
const yAccessor = d => d.runs;

let dimensions = {
    width: window.innerWidth * 0.9, height: 400,
    margin: {
        top: 15,
        right: 15,
        bottom: 60,
        left: 60,
    },
};

dimensions.boundedWidth = dimensions.width
    - dimensions.margin.left
    - dimensions.margin.right;
dimensions.boundedHeight = dimensions.height
    - dimensions.margin.top
    - dimensions.margin.bottom;

export const BallsFacedGraph = ({
    domain = [0, 100],
    range = [10, 290],
    data
}) => {
    const ref = useRef();


    useEffect(() => {
        const svgElement = d3.select(ref.current);

        // Chart scales
        const xScale = d3.scaleLinear().domain(d3.extent(data.data, xBallsFaced)).range([0, dimensions.boundedWidth]);
        const yScale = d3.scaleLinear().domain(d3.extent(data.data, yAccessor)).range([dimensions.boundedHeight, 0]).nice();

        // Axes generation using above scales
        const xAxisGenerator = d3.axisBottom().scale(xScale);
        const yAxisGenerator = d3.axisLeft().scale(yScale);

        // Define colours shown in chart
        const lineColour = "#D64045";
        const dotColour = lineColour;

        const bounds = svgElement.append("g").style("transform", `translate(${dimensions.margin.left
            }px, ${dimensions.margin.top
            }px)`);

        bounds.append("g")
            .call(xAxisGenerator)
            .style("transform", `translateY(${dimensions.boundedHeight}px)`);
        bounds.append("g")
            .call(yAxisGenerator);

        // Add scatter points
        bounds.selectAll("circle").data(data.data)
            .enter().append("circle")
            .attr("cx", d => xScale(xBallsFaced(d)))
            .attr("cy", d => yScale(yAccessor(d)))
            .attr("fill", dotColour)
            .attr("r", 5)
            .attr("opacity", 0.6)

        bounds
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - dimensions.margin.left)
            .attr("x", 0 - (dimensions.boundedHeight - dimensions.margin.bottom) / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("Runs Scored");

        const translateA = (dimensions.boundedWidth + dimensions.margin.left) / 2;
        const translateB = dimensions.boundedHeight + dimensions.margin.top + (dimensions.margin.bottom / 2);
        const labelTranslate = `translate(${translateA}, ${translateB})`;

        bounds
            .append("text")
            .attr("transform", labelTranslate)
            .style("text-anchor", "middle")
            .text("Balls Faced");
    }, [data.data])

    return (
        <svg
            ref={ref}
            width={dimensions.width}
            height={dimensions.height}
        >
        </svg>
    )
};


export const CricketGraph = ({ data, xAccessor, yAccessor, chartSettings, divClasses }) => {
    const [ref, dimensions] = useChartDimensions(chartSettings);

    const xScale = d3.scaleLinear().domain([0, d3.max(data.data, xAccessor)]).range([0, dimensions.boundedWidth]);
    const yScale = d3.scaleLinear().domain([0, d3.max(data.data, yAccessor)]).range([dimensions.boundedHeight, 0]);

    // WIP to calculate edge point for graph
    // const xMax = d3.max(data.data, xAccessor);
    // const yMax = d3.max(data.data, yAccessor);
    // const exitPoint = xMax > yMax ? yMax : xMax;
    // console.log([xMax, yMax, exitPoint]);

    return (
        <div ref={ref} className={divClasses}>
            <ChartCanvas
                dimensions={dimensions}
            >
                <line y1={yScale(100)} y2={yScale(100)} x2={dimensions.boundedWidth} stroke="gray" strokeDasharray={(4)} opacity={0.5}></line>
                {/* <line x1={xScale(0)} y1={yScale(0)} x2={xScale(exitPoint)} y2={yScale(exitPoint)} stroke="gray" strokeDasharray={(4)} opacity={0.5}></line> */}
                <AxisHorizontal
                    range={[0, dimensions.boundedWidth]}
                    domain={[0, d3.max(data.data, xAccessor)]}
                    dimensions={dimensions}
                />
                <AxisVertical
                    range={[0, dimensions.boundedHeight]}
                    domain={[0, d3.max(data.data, yAccessor)]}
                    dimensions={dimensions}
                />
                <g>
                    {data.data.map((d, i) => (
                        <circle
                            key={i}
                            cx={xScale(xAccessor(d))}
                            cy={yScale(yAccessor(d))}
                            r="5"
                            className="text-badger-red fill-current"
                            opacity={0.5}
                        />
                    ))}
                </g>
                <AxisLabelHorizontal
                    label={"Balls Faced"}
                    dimensions={dimensions}
                    classes="text-sm sm:text-base"
                />
                <AxisLabelVertical
                    label={"Runs Scored"}
                    dimensions={dimensions}
                    classes="text-sm sm:text-base"
                />
            </ChartCanvas>
        </div>
    );
};




export const RunDistributionGraph = ({ data, baseData, xAccessor, yAccessor, chartSettings, divClasses }) => {
    const [ref, dimensions] = useChartDimensions(chartSettings);
    // const [filteredData, updateFilteredData] = useState(baseData);

    const xBarTicks = [
        "0-9",
        "10-19",
        "20-29",
        "30-39",
        "40-49",
        "50-59",
        "60-69",
        "70-79",
        "80-89",
        "90-99",
        "100+",
    ];

    const barPadding = 0.2;
    const yMax = Math.round(d3.max(data, yAccessor) * 1.1, 0);
    const xScale = d3.scaleBand().domain(xBarTicks).range([0, dimensions.boundedWidth]).padding(barPadding);
    const yScale = d3.scaleLinear().domain([0, yMax]).range([dimensions.boundedHeight, 0]);

	// const mouseOver = (e) => {console.log(d3.pointer(e))};
    // const mouseMove = (e) => {console.log(d3.pointer(e))};

    let ticks = [];
    ticks = xScale.bandwidth();

    const findTickPositions = (barTicks, xScale) => {
        return barTicks.map(value => ({
                value,
                xBarStart: xScale(value),
                bandwidth: xScale.bandwidth(),
                step: xScale.step(),
                padding: xScale.padding(),
                xOffset: xScale(value) + xScale.bandwidth() / 2
            }));
    };

    ticks = findTickPositions(xBarTicks, xScale);

    return (
        <div ref={ref} className={divClasses}>
            <ChartCanvas
                dimensions={dimensions}
            >
                {/* Add rect box to contain the mouse over events */}
                {/* <rect x="0" y="0" width={dimensions.boundedWidth} height={dimensions.boundedHeight} opacity={0.01} /> */}

                {/* <line y1={yScale(75)} y2={yScale(75)} x2={dimensions.boundedWidth} stroke="gray" strokeDasharray={(4)} opacity={0.5}></line> */}
                {/* <line x1={xScale(0)} y1={yScale(0)} x2={xScale(exitPoint)} y2={yScale(exitPoint)} stroke="gray" strokeDasharray={(4)} opacity={0.5}></line> */}
                <AxisVertical
                    range={[0, dimensions.boundedHeight]}
                    domain={[0, yMax]}
                    dimensions={dimensions}
                />
                {/* <g>
                    {[1].map((d, i) => (
                        <rect
                            key={i}
                            x={xScale("0-9") - ((xScale.step() - xScale.bandwidth()) / 2)}
                            y={yScale(75)}
                            width={xScale.step()}
                            height={dimensions.boundedHeight - yScale(75)}
                            className="text-badger-green fill-current"
                            opacity={0.2}
                        />
                    ))}
                </g> */}
                <Bars
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    xAccessor={xAccessor}
                    yAccessor={yAccessor}
                    dimensions={dimensions}
                />
                {/* <AnimatedBars
                    data={filteredData}
                    xScale={xScale}
                    yScale={yScale}
                    xAccessor={xAccessor}
                    yAccessor={yAccessor}
                    dimensions={dimensions}
                /> */}
                <BandedAxisHorizontal
                    domain={xBarTicks}
                    range={[0, dimensions.boundedWidth]}
                    bandTicks={xBarTicks}
                    dimensions={dimensions}
                    padding={barPadding}
                />
                <AxisLabelHorizontal
                    label={"Score Range"}
                    dimensions={dimensions}
                    classes="text-sm sm:text-base"
                />
                <AxisLabelVertical
                    label={"Innings"}
                    dimensions={dimensions}
                    classes="text-sm sm:text-base"
                />
            </ChartCanvas>
        </div>
    );
};



const Bars = ({data, xScale, yScale, xAccessor, yAccessor, dimensions}) => {
    if (data === null) {
        return null
    } else {
        return (
            <g>
                {data.map((d, i) => (
                    <rect
                        key={i}
                        x={xScale(xAccessor(d))}
                        y={yScale(yAccessor(d))}
                        width={xScale.bandwidth()}
                        height={dimensions.boundedHeight - yScale(yAccessor(d))}
                        className="text-badger-red fill-current"
                        opacity={0.7}
                    />
                ))}
            </g>
        )
    }
};


// Animated bar <rect> that will animate as data changes
const AnimatedBars = ({data, xScale, yScale, xAccessor, yAccessor, dimensions}) => {
    const ref = useRef();

    const drawBars = () => {
        // Placement of transition changes effect
        d3.select(ref.current)
            .selectAll("rect")
            .data(data)
            .join("rect")
            .attr("width", xScale.bandwidth())
            .transition()
            .duration(1500)
            .attr("x", (d) => xScale(xAccessor(d)))
            .attr("y", (d) => yScale(yAccessor(d)))
            .attr(
                "height",
                (d) => dimensions.boundedHeight - yScale(yAccessor(d))
            )
            .attr("class", "text-badger-red fill-current");
    };

    // Every time the data changes, update the graph
    useEffect(() => {
        drawBars();
    }, [data, xScale]);

    // Define base rectangles for each data point
    const bars = data.map((d, i) => <rect key={i}></rect>);

    return (
        <g ref={ref}>
            {bars}
        </g>
    );
};
