import { ArticleTitle } from "../components/Headers";
import { PageBody } from "../components/PageBody";
import { Helmet } from "react-helmet-async";
import { DictionaryDefinition } from "../components/DictionaryDefinition";


export const About = () => {
    return (
        <PageBody>
            <Helmet>
                <title>About | VizBadger</title>
                <meta
                    name="description"
                    content="Predict and report sports performance using analytics, machine learning and sports modelling."
                ></meta>
                <meta name="keywords" content="Sports models, sports analytics, sports analysis, sports predictions, sports tip, predictive modelling, predictive sports modelling, sports data, sports prediction"></meta>
            </Helmet>

            <ArticleTitle
                title="About"
            />

            <div className="flex flex-col items-center justify-center max-w-screen-md mx-auto pt-3">
                <DictionaryDefinition />
            </div>
        </PageBody>
    );
};
