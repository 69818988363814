import { useChartDimensions } from "./useChartDimensions";
import { useEffect, useState } from "react";
import { ChartCanvas, AxisHorizontal, AxisVertical } from "./basicGraph";
import * as d3 from "d3";
import { ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/24/outline';


// Generate a random dataset to display on the graph
const generateDataset = (maxX, maxY) => (
    Array(150).fill(0).map(() => ([
        Math.random() * maxX,
        Math.random() * maxY,
    ]))
);


const Circles = ({ dataset, xScale, yScale, colourScaleMultiple }) => {
    // let domainMax = Math.max(...dataset.map(o => o[0]));
    // let colourScale = d3.scaleSequential().domain([0, domainMax]).range(["#e74c3c", "#2ecc71"]);

    return (
        <g>
            {dataset.map(([x, y], i) => (
                <circle
                    key={i}
                    cx={xScale(x)}
                    cy={yScale(y)}
                    r="10"
                    fill={colourScaleMultiple(x)}
                    opacity={0.75}
                />
            ))}
        </g>
    )
}

const Greeting = ({ currentHeight, updateChartHeight }) => {
    if (currentHeight === 300) {
        return <ArrowUpCircleIcon className="h-5 ml-1 cursor-pointer" onClick={() => updateChartHeight()} />;
    }
    return <ArrowDownCircleIcon className="h-5 ml-1 cursor-pointer" onClick={() => updateChartHeight()} />;
}

export const DynamicGraph = ({ data, xAccessor, yAccessor, label, chartSettings, updateChartHeight, chartColours, centralColourBreakpoint }) => {
    const [ref, dimensions] = useChartDimensions(chartSettings);

    const [dataset, setDataset] = useState([[0, 0]]);
    const xScale = d3.scaleLinear().domain([0, 100]).range([0, dimensions.boundedWidth]);
    const yScale = d3.scaleLinear().domain([0, 100]).range([dimensions.boundedHeight, 0]).nice();
    const colourScaleMultiple = d3.scaleLinear().domain([0, centralColourBreakpoint, 100]).range(chartColours);

    useEffect(() => {
        // Generate dataset for the domain area
        let newDataset = generateDataset(100, 100);
        setDataset(newDataset);
    }, []);

    return (
        <div ref={ref}>
            <div>
                <div className="flex items-center">
                    <p>Height: {dimensions.height}</p>
                    <Greeting currentHeight={dimensions.height} updateChartHeight={updateChartHeight} />
                </div>
                <p>Width <span className="text-xs italic">(dynamic based on container/window size)</span>: {dimensions.width}</p>

            </div>
            <ChartCanvas dimensions={dimensions}>
                <Circles
                    dataset={dataset}
                    xScale={xScale}
                    yScale={yScale}
                    colourScaleMultiple={colourScaleMultiple}
                />
                {/* https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/alignment-baseline */}
                {/* <text x={0} y={10} fill="red" alignmentBaseline="central">{dimensions.width}, {dimensions.height}</text> */}
                <AxisHorizontal range={[0, dimensions.boundedWidth]} dimensions={dimensions} />
                <AxisVertical range={[0, dimensions.boundedHeight]} dimensions={dimensions} />
                <line x1={0} x2={dimensions.boundedWidth} stroke="gray" strokeDasharray={(4)} opacity={0.5}></line>
                <line x1={dimensions.boundedWidth} x2={dimensions.boundedWidth} y2={dimensions.boundedHeight} stroke="gray" strokeDasharray={(4)} opacity={0.5}></line>
            </ChartCanvas>
        </div>
    );
};
