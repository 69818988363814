import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { PoolSimulationHomeButton } from '../../../components/HomeLinks';
import { ArticleParagraph } from "../../../components/Paragraphs";
import { useEffect, useState } from "react";
import { generatePoolPlayers } from "./utility/createRandomPlayer";
import { generateNRoundRobinPairings } from "./utility/fixtureGeneration";
import { Loading } from "../../../components/Loading";
import { displayPercentage } from "../../../helpers";


const aggregateResults = (players, results) => {
    let wins;
    let matches;
    let score_for;
    let score_against;
    let player_stats = { 'table': [] };

    players.forEach((player, i) => {
        matches = results.filter((result) => result.player_ids.includes(player.player_id));
        wins = matches.filter((result) => result.winner_id === player.player_id).length;
        score_for = matches.map(match => match[player.player_id].score).reduce((a, b) => a + b, 0);
        score_against = matches.map(match => match[player.player_id].score_against).reduce((a, b) => a + b, 0);

        player_stats[player.player_id] = {
            'player_id': player.player_id,
            'played': matches.length,
            'wins': wins,
            'points': wins * 2,
            'score_for': score_for,
            'score_against': score_against,
            'score_difference': score_for - score_against,
            'matches': matches
        };

        player_stats['table'][i] = { 'position': -1, 'player_id': player.player_id, ...player_stats[player.player_id] }
    });

    player_stats.table.sort((a, b) => b.points - a.points || b.score_difference - a.score_difference);

    for (let j = 0; j < player_stats.table.length; j++) {
        player_stats.table[j]['position'] = j + 1;
        player_stats[player_stats.table[j]['player_id']]['position'] = j + 1;
    };

    return player_stats;
};


// Simulate a single shot with foul chance
const takeShotFoul = (accuracyPercentage, foulPercentage, foulMakeBlackPercentage) => {
    // Outcome = [madeShot, wasFoul, madeBlack]
    let shotOutcome = [false, false, false];

    // Does the player make the ball?
    if (Math.random() < accuracyPercentage) {
        shotOutcome[0] = true;
    };

    // Does the player foul?
    if (Math.random() < foulPercentage) {
        shotOutcome[1] = true;

        // Player has fouled, did they make the black as well?
        if (Math.random() < foulMakeBlackPercentage) {
            shotOutcome[2] = true;
        }
    };

    return shotOutcome;
};


// Given a player, return the opponent
const opponentPlayer = (player) => {
    return player === 'player_1' ? 'player_2' : 'player_1';
};


// scores = {'player_1': int, 'player_2': int}
const updateScores = (
    scores,
    shootingPlayer,
    lastShotOutcome,
    lastShotFoul,
    lastShotFoulMadeBlack
) => {
    // The shot was made
    if (lastShotOutcome) {
        // On the black and foul
        if (scores[shootingPlayer] === 7 && lastShotFoul) {
            scores[opponentPlayer(shootingPlayer)] = 10;
        } else {
            // Increase score
            scores[shootingPlayer] += scores[shootingPlayer] === 7 ? 3 : 1;
        };
    };

    // Losing the game on foul
    if (lastShotFoul && lastShotFoulMadeBlack) {
        scores[opponentPlayer(shootingPlayer)] = 10;
    };

    return scores;
};


// [shootingPlayer, tableVisits]
const handleVisits = (
    shootingPlayer,
    playerTableVisits,
    lastShotOutcome,
    lastShotFoul
) => {
    // Give two visits to opponent if shooting player fouled
    if (lastShotFoul) {
        return [opponentPlayer(shootingPlayer), 2];
    };

    // If this shot was the first post-foul
    if (playerTableVisits === 2) {
        return [shootingPlayer, 1];
    };

    // Continue the visit if the shot was made
    if (lastShotOutcome) {
        return [shootingPlayer, 1];
    };

    // Else swap player at the table
    return [opponentPlayer(shootingPlayer), 1];
};


// Give attributes object structure, simulate full game
const simulateGame = (playerAttributes, startingPlayer) => {
    // Store the shots of the entire game
    let gameShots = [];

    // Track how many visits the player has at the table
    let playerTableVisits = 1;
    let shootingPlayer = startingPlayer;

    // Track scores
    let scores = { 'player_1': 0, 'player_2': 0 };

    // Last shot outcome
    let lastShotOutcome = false;
    let lastShotFoul = false;
    let lastShotFoulMadeBlack = false;

    // Break
    let breakSplitBenefit = 0;
    let breakSplitDuration = 0;
    let breakOutcome = null;

    // Track accuracy changes
    let nextShotAccuracy = 0;
    // Break
    [lastShotOutcome, lastShotFoul, lastShotFoulMadeBlack] = takeShotFoul(
        playerAttributes[shootingPlayer]['break']['make'],
        playerAttributes[shootingPlayer]['break']['foul'],
        0
    );

    scores = updateScores(
        scores,
        shootingPlayer,
        lastShotOutcome,
        lastShotFoul,
        lastShotFoulMadeBlack
    );

    // Store break outcome
    breakOutcome = lastShotOutcome;

    // Push shot details to game shots
    gameShots.push([
        shootingPlayer,
        lastShotOutcome,
        scores['player_1'],
        scores['player_2'],
        playerAttributes[shootingPlayer]['break']['make'],
        true,
        breakSplitBenefit,
        lastShotFoul,
        lastShotFoulMadeBlack
    ]);

    // Update break benefits
    breakSplitBenefit += playerAttributes[shootingPlayer]['break']['splitBenefit'];
    breakSplitDuration += playerAttributes[shootingPlayer]['break']['splitDuration'];

    // Calculate who should be playing, and how many visits they get
    [shootingPlayer, playerTableVisits] = handleVisits(
        shootingPlayer,
        playerTableVisits,
        lastShotOutcome,
        lastShotFoul
    );

    // While neither player has won, continue playing
    while (playerTableVisits > 0 & scores['player_1'] < 10 & scores['player_2'] < 10) {
        nextShotAccuracy = playerAttributes[shootingPlayer]['regular']['make'];
        nextShotAccuracy += breakSplitDuration > 0 ? breakSplitBenefit : 0;

        // Outcome
        [lastShotOutcome, lastShotFoul, lastShotFoulMadeBlack] = takeShotFoul(
            nextShotAccuracy,
            playerAttributes[shootingPlayer]['regular']['foul'],
            playerAttributes[shootingPlayer]['foul']['makeBlack']
        );

        // Update score - will handle immediate foul loss
        scores = updateScores(
            scores,
            shootingPlayer,
            lastShotOutcome,
            lastShotFoul,
            lastShotFoulMadeBlack
        );

        // Push result of shot, and resulting score
        gameShots.push([
            shootingPlayer,
            lastShotOutcome,
            scores['player_1'],
            scores['player_2'],
            playerAttributes[shootingPlayer]['regular']['make'],
            false, // isBreak
            breakSplitDuration > 0 ? breakSplitBenefit : 0, // splitBenefit
            lastShotFoul,
            lastShotFoulMadeBlack
        ]);

        // Calculate who should be playing, and how many visits they get
        [shootingPlayer, playerTableVisits] = handleVisits(
            shootingPlayer,
            playerTableVisits,
            lastShotOutcome,
            lastShotFoul
        );

        breakSplitDuration -= 1;
    };

    // We could take top streaks here and store that information as well
    let player_1_top_streak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player_1')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // If n is true, increment the final position by one
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    let player_2_top_streak = Math.max(...gameShots
        .filter(shot => shot[0] === 'player_2')
        .map(shot => shot[1] ? 1 : 0)
        .reduce((res, n) =>
            // eslint-disable-next-line
            (n ? res[res.length - 1]++ : res.push(0), res)
            , [0]));

    const output = {
        'player_ids': [playerAttributes['player_1']['player_id'], playerAttributes['player_2']['player_id']],
        'winner_id': scores['player_1'] === 10 ? playerAttributes['player_1']['player_id'] : playerAttributes['player_2']['player_id'],
        'loser_id': scores['player_1'] === 10 ? playerAttributes['player_2']['player_id'] : playerAttributes['player_1']['player_id'],

        [playerAttributes['player_1']['player_id']]: {
            'score': scores['player_1'],
            'score_against': scores['player_2']
        },

        [playerAttributes['player_2']['player_id']]: {
            'score': scores['player_2'],
            'score_against': scores['player_1']
        },

        'score': scores['player_1'] + " - " + scores['player_2'],
        'winner': scores['player_1'] === 10 ? 'player_1' : 'player_2',
        'break_player': startingPlayer,
        'break_player_id': playerAttributes[startingPlayer]['player_id'],
        'break_outcome': breakOutcome,
        'shots': gameShots,
        'total_shots': gameShots.length,
        'player_1_score': scores['player_1'],
        'player_1_shots': gameShots.filter(shot => shot[0] === 'player_1').length,
        'player_1_shots_made': gameShots.filter(shot => shot[0] === 'player_1' & shot[1]).length,
        'player_1_top_streak': player_1_top_streak,
        'player_1_fouls': gameShots.filter(shot => shot[0] === 'player_1' & shot[7]).length,
        'player_2_score': scores['player_2'],
        'player_2_shots': gameShots.filter(shot => shot[0] === 'player_2').length,
        'player_2_shots_made': gameShots.filter(shot => shot[0] === 'player_2' & shot[1]).length,
        'player_2_top_streak': player_2_top_streak,
        'player_2_fouls': gameShots.filter(shot => shot[0] === 'player_2' & shot[7]).length,
    };

    return output;
};


const simulateLeagueFixtures = (players, fixtures) => {
    // Format: {player_id: {opponent_id: {'player_break': n (int), 'opponent_break': n (int)}}}
    // If 'player_break' === 'opponent_break' then 'player_id' breaks, else min to even break opportunities
    let breakTracker = {};
    const flatFixtures = fixtures.flat();
    let results = [];

    for (let i = 0; i < players.length; i++) {
        const player = players[i];
        breakTracker[player.player_id] = {};
        for (let j = 0; j < players.length; j++) {
            const opponent = players[j];
            if (opponent.player_id !== player.player_id) {
                breakTracker[player.player_id][opponent.player_id] = 0
            };
        };
    };

    let breakPlayer = -1;
    let player_1Breaks = -1;
    let player_2Breaks = -1;

    flatFixtures.forEach((fixture, i) => {
        player_1Breaks = breakTracker[fixture.player_1.player_id][fixture.player_2.player_id];
        player_2Breaks = breakTracker[fixture.player_2.player_id][fixture.player_1.player_id];

        if (player_1Breaks < player_2Breaks) {
            breakPlayer = 'player_1';
            breakTracker[fixture.player_1.player_id][fixture.player_2.player_id] = player_1Breaks + 1;
        } else if (player_1Breaks === player_2Breaks & Math.random() < 0.5) {
            breakPlayer = 'player_1';
            breakTracker[fixture.player_1.player_id][fixture.player_2.player_id] = player_1Breaks + 1;
        } else {
            breakPlayer = 'player_2';
            breakTracker[fixture.player_2.player_id][fixture.player_1.player_id] = player_2Breaks + 1;
        };

        // Simulate the game
        results[i] = simulateGame(fixture, breakPlayer)
    });

    return aggregateResults(players, results);
};

const simulateNLeagues = (players, fixtures, nSimulations) => {
    let arr = new Array(nSimulations).fill(null);

    for (let i = 0; i < arr.length; i++) {
        arr[i] = simulateLeagueFixtures(players, fixtures);
    };

    // let playerResultsLookup = {};
    let summarisedPlayerResults = [];
    let playerResults = [];
    players.forEach(player => {
        for (let i = 0; i < arr.length; i++) {
            const simulation = arr[i];
            playerResults.push(simulation[player.player_id]);
        };

        summarisedPlayerResults.push({
            'player_id': player.player_id,
            'averagePoints': playerResults.reduce((total, next) => total + next.points, 0) / playerResults.length,
            'averageWins': playerResults.reduce((total, next) => total + next.wins, 0) / playerResults.length,
            'averageScoreDifference': playerResults.reduce((total, next) => total + next.score_difference, 0) / playerResults.length,
            // 'positionChances': {},
            'firstPlaceChance': playerResults.filter(simulation => simulation.position === 1).length / playerResults.length,
            'lastPlaceChance': playerResults.filter(simulation => simulation.position === players.length).length / playerResults.length,
            // 'allResults': playerResults
        });
        // playerResultsLookup[player.player_id] = {
        //     'averagePoints': playerResults.reduce((total, next) => total + next.points, 0) / playerResults.length,
        //     'averageWins': playerResults.reduce((total, next) => total + next.wins, 0) / playerResults.length,
        //     'averageScoreDifference': playerResults.reduce((total, next) => total + next.score_difference, 0) / playerResults.length,
        //     // 'positionChances': {},
        //     'firstPlaceChance': playerResults.filter(simulation => simulation.position === 1).length / playerResults.length,
        //     'lastPlaceChance': playerResults.filter(simulation => simulation.position === players.length).length / playerResults.length,
        // };
        playerResults = [];
    });

    // console.log(summarisedPlayerResults);
    // console.log(playerResultsLookup);
    summarisedPlayerResults.sort((a, b) => b.averagePoints - a.averagePoints || b.firstPlaceChance - a.firstPlaceChance);
    return summarisedPlayerResults;
};


const PlayerAttributesTable = ({ players }) => {
    return (
        <div className="text-sm sm:text-base text-center sm:text-left items-start">
            <div className="w-full rounded mb-4">
                <div className="text-center flex flex-row border-b">
                    <p className="w-1/3 border-r py-2">Players</p>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - id`} className="border-r border-t flex-1 flex items-center justify-center">{player.player_id}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row border-b">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2">Regular Play</p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Make Chance</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - regmake`} className="border-r flex-1 flex items-center justify-center">{displayPercentage(player['regular']['make'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2">Break</p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Make Chance</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - breakmake`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['make'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Split Benefit</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - breaksplit`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['splitBenefit'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row border-b">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Split Duration</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - breaksplitdur`} className="border-r flex-1 flex items-center justify-center">{player['break']['splitDuration']}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2">Foul</p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Regular Play</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - foulreg`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['regular']['foul'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Break</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - foulbreak`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['break']['foul'])}</div>))}
                    </div>
                </div>
                <div className="text-center flex flex-row">
                    <div className="w-1/3 border-r py-2 flex flex-row">
                        <p className="w-1/2"></p>
                        <p className="flex items-center justify-center italic text-xs sm:text-sm w-1/2">Pot Black</p>
                    </div>
                    <div className="w-2/3 flex flex-row">
                        {players.map(player => (<div key={`${player.player_id} - foulblack`} className="border-r flex-1 flex items-center justify-center border-b">{displayPercentage(player['foul']['makeBlack'])}</div>))}
                    </div>
                </div>
            </div>
        </div>
    );
};


export const PoolLeagueSimulation = () => {
    const [randomPlayers, setRandomPlayers] = useState(null);
    const [nPlayers, setNPlayers] = useState(5);
    const [fixtures, setFixtures] = useState(null);
    const [results, setResults] = useState(null);
    const [multipleLeagueSimulations, setMultipleLeagueSimulations] = useState([]);

    useEffect(() => {
        const players = generatePoolPlayers(nPlayers);
        const fixtures = generateNRoundRobinPairings(players, 4);
        const simulationResults = simulateLeagueFixtures(players, fixtures);
        const multipleLeagueSimulationResults = simulateNLeagues(players, fixtures, 100);

        setRandomPlayers(players);
        setFixtures(fixtures);
        setResults(simulationResults.table);
        setMultipleLeagueSimulations(multipleLeagueSimulationResults);
    }, [nPlayers]);

    if (randomPlayers === null | fixtures === null | results === null) {
        return <Loading />
    };

    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - League Simulation | VizBadger</title>
                <meta name="description" content="Generate a set of random players programmatically and then use Monte Carlo simulations to work out the most successful skillset when played across an entire season."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators, athlete statistics, super computer predicts sports outcomes"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation/league-predictions" />
            </Helmet>

            <PoolSimulationHomeButton />

            <ArticleTitle title="League Prediction of Random Players" />

            <ArticleParagraph
                lines={[
                    "You see a lot of articles written about the super computers that are predicting the leagues. Companies like Opta will use their extensive statistics to build models and then come up with a predicted league based on thousands of simulations. FiveThirtyEight has my personal favourite demonstration of this.",
                    "To do this, you need to have a fixture list, be able to predict the outcome of games and then simulate the league multiple times. That is what I'm going to do below."
                ]}
            />

            <H2Heading>Generate random players</H2Heading>
            <div>
                <ArticleParagraph
                    lines={[
                        "For each of the IDs we have made a random player with a random skills and attributes as per the examples shown on the player model page.",
                    ]}
                />
            </div>

            <PlayerAttributesTable players={randomPlayers} />

            <ArticleParagraph
                lines={[
                    "Take a look at the players above, and try to intuitively guess who you think is the best and worst player in the lineup. Sometimes you'll find that it isn't as obvious as the top attribute, but all attributes play a part in contributing to the outcomes.",
                ]}
            />

            <H2Heading>Generate a fixture list</H2Heading>
            <div className="flex flex-wrap mb-2">
                {fixtures.map((round, i) => (
                    <div key={`round-${i}`} className="my-2 w-1/3 sm:w-1/5 text-center">
                        <p className="italic tracking-tight font-semibold">Round {i + 1}</p>
                        <div className="mt-1" key={i}>
                            {round.map((match, j) => (
                                <p key={`${i} - ${j}`}>{match.player_1.player_id} vs. {match.player_2.player_id}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <ArticleParagraph
                lines={[
                    "Once we have a set of players, we can generate a fixture list. Everyone will play the other players multiple times (each player getting a chance to break to remove break bias from the fixtures), with 2 points for a win, and 0 points for a loss. Those tied on points will be decided by a score difference (like goal difference in football).",
                ]}
            />

            <H2Heading>Simulated League</H2Heading>
            <ArticleParagraph
                lines={[
                    "I've simulated through each of the fixtures in the league to crown a winner/s. The real power of this method will be the ability to simulate through the league a number of times. As we have seen with the individual simulations, even weaker players can have reasonably good success rates against top players, especially with strong breaking skills and streaks.",
                ]}
            />
            <div className="flex flex-row items-center justify-center mb-5">
                <table className="w-full sm:w-1/2 text-sm sm:text-base rounded">
                    <thead className="text-center">
                        <tr className="tracking-tight italic font-semibold bg-gray-100">
                            <td className="py-1 px-3">#</td>
                            <td className="p-1">Player</td>
                            <td className="p-1">Played</td>
                            <td className="p-1">Wins</td>
                            <td className="p-1">Score For</td>
                            <td className="p-1">Score Against</td>
                            <td className="p-1">Score Diff</td>
                            <td className="p-1">Points</td>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {results.map((player_result, i) => (
                            <tr key={player_result.player_id}>
                                <td className="py-1 border-r border-gray-100">{i + 1}</td>
                                <td className="py-1">{player_result.player_id}</td>
                                <td className="py-1">{player_result.played}</td>
                                <td className="py-1">{player_result.wins}</td>
                                <td className="py-1">{player_result.score_for}</td>
                                <td className="py-1">{player_result.score_against}</td>
                                <td className="py-1">{player_result.score_difference}</td>
                                <td className="py-1">{player_result.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <H2Heading>Simulating 100 Leagues</H2Heading>
            <ArticleParagraph
                lines={[
                    "Take the above simulation and run that 100 times to see, generally, who is likely to win the league!",
                ]}
            />
            <div className="flex flex-row items-center justify-center">
                <table className="w-full sm:w-1/2 text-sm sm:text-base rounded">
                    <thead className="text-center">
                        <tr className="tracking-tight italic font-semibold bg-gray-100">
                            <td className="p-1">Player</td>
                            <td className="p-1">Avg. Points</td>
                            <td className="p-1">Avg. Wins</td>
                            <td className="p-1">Avg. Score Diff</td>
                            <td className="p-1">1st Chance</td>
                            <td className="p-1">Last Chance</td>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {multipleLeagueSimulations.map((player) => (
                            <tr key={player.player_id}>
                                <td className="p-1">{player.player_id}</td>
                                <td className="p-1">{player.averagePoints}</td>
                                <td className="p-1">{player.averageWins}</td>
                                <td className="p-1">{player.averageScoreDifference}</td>
                                <td className="p-1">{displayPercentage(player.firstPlaceChance, 2)}</td>
                                <td className="p-1">{displayPercentage(player.lastPlaceChance, 2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PageBody>
    );
};
