export const ArticleParagraph = ({
    lines,
    divClasses = "text-sm sm:text-base text-center sm:text-left mb-4",
    lineClasses = "mb-3 sm:mb-2"
}) => {
    return (
        <div className={divClasses}>
            {lines.map((line) => (
                <p key={line} className={lineClasses}>{line}</p>
            ))}
        </div>
    );
};
