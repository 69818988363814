import { Link } from "react-router-dom";

export const HomeButton = ({ children, linkDestination, classes }) => (
    <Link
        className={`rounded-full border px-2 py-1 tracking-tight text-sm border-current transition ease-in-out delay-50 ${ classes }`}
        to={linkDestination}
    >
        {children}
    </Link>
);

export const BettingHomeButton = () => (
    <div className="flex items-center justify-center">
        <HomeButton classes="text-gray-400 hover:text-white hover:bg-badger-blue hover:border-badger-blue" linkDestination={'/betting'}>Betting Home</HomeButton>
    </div>
);

export const SportsModelsHomeButton = () => (
    <div className="flex items-center justify-center">
        <HomeButton classes="text-gray-400 hover:text-white hover:bg-badger-blue hover:border-badger-blue" linkDestination={'/sports-models'}>Sports Models Home</HomeButton>
    </div>
);

export const PoolSimulationHomeButton = () => (
    <div className="flex items-center justify-center">
        <HomeButton classes="text-gray-400 hover:text-white hover:bg-badger-blue hover:border-badger-blue" linkDestination={'/sports-models/english-pool-monte-carlo-simulation'}>Pool Simulation Home</HomeButton>
    </div>
);

export const GolfModelsHomeButton = () => (
    <div className="flex items-center justify-center">
        <HomeButton classes="text-gray-400 hover:text-white hover:bg-badger-blue hover:border-badger-blue" linkDestination={'/sports-models/golf'}>Golf Models Home</HomeButton>
    </div>
);