import { PageBody } from "./PageBody";

export const Loading = ({ loadingText = "Loading..." }) => {
    return (
        <PageBody>
            <div className="flex flex-row items-center justify-center text-xl text-gray-700 animate-pulse">
                {/* <span className="animate-spin p-0 mr-1">
                    <RefreshIcon className="h-5 w-5 scale-x-[-1]" />
                </span> */}
                {loadingText}
            </div>
        </PageBody>
    );
};
