import { AxisHorizontal, AxisLabelHorizontal, ChartCanvas } from "../../../../components/graphs/basicGraph";
import { useChartDimensions } from "../../../../components/graphs/useChartDimensions";
import * as d3 from "d3";
import { useMemo } from "react";


const BandedAxisVertical = ({
	domain,
	range = [10, 290],
	bandTicks,
	dimensions,
	padding
}) => {
    // const domainRange = domain.join("-");
    // const dataRange = range.join("-");

	const ticks = useMemo(() => {
		const yScale = d3.scaleBand()
			.domain(domain)
			.range(range).padding(padding);

		return bandTicks.map(value => ({
			value,
            bandwidth: yScale.bandwidth(),
			yOffset: yScale(value) + yScale.bandwidth() / 2
		}))
	}, [padding, bandTicks, domain, range])

	return (
		<g
			// No need to transform axis here
			transform={`translate(${[
			0,
			0,
		].join(",")})`}>
			<line
				y2={dimensions.boundedHeight}
				stroke="currentColor"
			/>
			{ticks.map(({ value, yOffset }) => (
				<g
                    key={value}
                    transform={`translate(0,${yOffset})`}
                >
					<line
						x2="-6"
						stroke="currentColor"
					/>
					<text
						key={value}
						dominantBaseline="middle"
						textAnchor="end"
						style={{
							fontSize: "10px",
							transform: "translateX(-10px)"
						}}>
						{value}
					</text>
				</g>
			))}
		</g>
	)
};


// We want to replace the accuracy distribution to better deal with higher numbers of simulations
// Histogram example using bins: https://ncoughlin.com/posts/d3-histogram-dropdown-select-data/
export const ViolinDistribution = ({ chartSettings, playerAccuracies, player1Accuracy, player2Accuracy }) => {
    const [ref, dimensions] = useChartDimensions(chartSettings);
    const playerScalePadding = 0;
    const xDomain = [0, 100];
    const xAxisPadding = 30;
    const playerScale = d3.scaleBand().domain(["P1", "P2"]).range([0, dimensions.boundedHeight]).padding(playerScalePadding);

    const xScale = d3.scaleLinear().domain(xDomain).range([xAxisPadding, dimensions.boundedWidth-xAxisPadding]);
    const bins = d3.bin().domain(xScale.domain()).thresholds(50);
    const yAccessor = (d) => d.length;
    const binnedDataPlayer1 = bins(playerAccuracies["player1"]);
    const binnedDataPlayer2 = bins(playerAccuracies["player2"]);
    const yScale = d3.scaleLinear().domain([0, Math.max(d3.max(binnedDataPlayer1, yAccessor), d3.max(binnedDataPlayer2, yAccessor))]).range([0, playerScale.bandwidth()/2*0.75]);

    const binnedDataPlayer1Positions = binnedDataPlayer1.map((bin) => {
        bin['count'] = yAccessor(bin);
        bin['x'] = xScale(bin.x0);
        bin['width'] = xScale(bin.x1) - xScale(bin.x0) > 0 ? xScale(bin.x1) - xScale(bin.x0) : 0;
        bin['height'] = yScale(yAccessor(bin));
        bin['y_top'] = playerScale("P1")+playerScale.bandwidth()/2;
        bin['y_bottom'] = (playerScale("P1")+playerScale.bandwidth()/2)-yScale(yAccessor(bin));
        return bin
    });

    const binnedDataPlayer2Positions = binnedDataPlayer2.map((bin) => {
        bin['count'] = yAccessor(bin);
        bin['x'] = xScale(bin.x0);
        bin['width'] = xScale(bin.x1) - xScale(bin.x0) > 0 ? xScale(bin.x1) - xScale(bin.x0) : 0;
        bin['height'] = yScale(yAccessor(bin));
        bin['y_top'] = playerScale("P2")+playerScale.bandwidth()/2;
        bin['y_bottom'] = (playerScale("P2")+playerScale.bandwidth()/2)-yScale(yAccessor(bin));
        return bin
    });

    const player1LineStart = xScale(d3.min(binnedDataPlayer1Positions.filter((bin) => bin['count']), (d) => d.x0));
    const player1LineEnd = xScale(d3.max(binnedDataPlayer1Positions.filter((bin) => bin['count']), (d) => d.x1));

    const player2LineStart = xScale(d3.min(binnedDataPlayer2Positions.filter((bin) => bin['count']), (d) => d.x0));
    const player2LineEnd = xScale(d3.max(binnedDataPlayer2Positions.filter((bin) => bin['count']), (d) => d.x1));

    return (
        <div ref={ref} className="w-full flex flex-col items-center justify-center">
            <p className="mb-0">
                <span className="text-sm sm:text-base text-badger-blue">Player 1</span>
                <span className="text-xs sm:text-sm">&nbsp;vs.&nbsp;</span>
                <span className="text-sm sm:text-base text-badger-orange">Player 2</span>
            </p>
            <ChartCanvas dimensions={dimensions}>
                <line
                    x1={player1LineStart}
                    x2={player1LineEnd}
                    y1={playerScale("P1") + playerScale.bandwidth() / 2}
                    y2={playerScale("P1") + playerScale.bandwidth() / 2}
                    className="text-badger-blue"
                    opacity={0.5}
                    stroke="currentColor"
                    strokeWidth={0.7}
                />

                <line
                    x1={player2LineStart}
                    x2={player2LineEnd}
                    y1={playerScale("P2") + playerScale.bandwidth() / 2}
                    y2={playerScale("P2") + playerScale.bandwidth() / 2}
                    className="text-badger-orange"
                    opacity={0.5}
                    stroke="currentColor"
                    strokeWidth={0.7}
                />

                {binnedDataPlayer1Positions.map((binData, i) => (
                    <rect
                        key={i}
                        width={binData.width}
                        height={binData.height}
                        x={binData.x}
                        y={binData.y_top}
                        opacity={0.7}
                        className="fill-badger-blue"
                    ></rect>
                ))}

                {binnedDataPlayer1Positions.map((binData, i) => (
                    <rect
                        key={i}
                        width={binData.width}
                        height={binData.height}
                        x={binData.x}
                        y={binData.y_bottom}
                        opacity={0.59}
                        className="fill-badger-blue"
                    ></rect>
                ))}

                {binnedDataPlayer2Positions.map((binData, i) => (
                    <rect
                        key={i}
                        width={binData.width}
                        height={binData.height}
                        x={binData.x}
                        y={binData.y_top}
                        opacity={0.7}
                        className="fill-badger-orange"
                    ></rect>
                ))}

                {binnedDataPlayer2Positions.map((binData, i) => (
                    <rect
                        key={i}
                        width={binData.width}
                        height={binData.height}
                        x={binData.x}
                        y={binData.y_bottom}
                        opacity={0.59}
                        className="fill-badger-orange"
                    ></rect>
                ))}

                <AxisHorizontal
                    domain={xDomain}
                    range={[xAxisPadding, dimensions.boundedWidth-xAxisPadding]}
                    dimensions={dimensions}
                />
                <BandedAxisVertical
                    domain={["P1", "P2"]}
                    range={[0, dimensions.boundedHeight]}
                    dimensions={dimensions}
                    bandTicks={["P1", "P2"]}
                    padding={playerScalePadding}
                />
                <AxisLabelHorizontal
                    label={"Game Accuracy (%)"}
                    dimensions={dimensions}
                    classes="text-xs sm:text-sm"
                />
                <line
                    x2={dimensions.boundedWidth}
                    y1={dimensions.boundedHeight/2}
                    y2={dimensions.boundedHeight/2}
                    className="text-gray-200"
                    stroke="currentColor"
                />

                <line
                    x1={xScale(player1Accuracy)}
                    x2={xScale(player1Accuracy)}
                    y1={0}
                    y2={dimensions.boundedHeight/2}
                    className="text-badger-blue"
                    stroke="currentColor"
                    strokeDasharray="3"
                    strokeWidth={2}
                />
                <line
                    x1={xScale(player2Accuracy)}
                    x2={xScale(player2Accuracy)}
                    y1={dimensions.boundedHeight}
                    y2={dimensions.boundedHeight/2}
                    className="text-badger-orange"
                    stroke="currentColor"
                    strokeDasharray="3"
                    strokeWidth={2}
                />
            </ChartCanvas>
        </div>
    );
};
