export const batchSimulateGame = (simulateGame, totalSimulations, playerAccuracies) => {
    // console.time('batchSimulate');
    let simulationsArray = [];
    let players = ['player1', 'player2'];
    let simulation = {};

    for (let simulationIndex = 0; simulationIndex < totalSimulations; simulationIndex++) {
        simulation = simulateGame(
            playerAccuracies['player1']['regular']['make'],
            playerAccuracies['player2']['regular']['make'],
            players[simulationIndex % 2]
        );

        simulationsArray.push(simulation);
    };
    // console.timeEnd('batchSimulate');

    const gameLengths = simulationsArray.map(simul => simul.totalShots);
    const player1ShotsTaken = simulationsArray.map(simul => simul.player1Shots).reduce((partial, a) => partial + a, 0);
    const player2ShotsTaken = simulationsArray.map(simul => simul.player2Shots).reduce((partial, a) => partial + a, 0);
    const player1ShotsMade = simulationsArray.map(simul => simul.player1ShotsMade).reduce((partial, a) => partial + a, 0);
    const player2ShotsMade = simulationsArray.map(simul => simul.player2ShotsMade).reduce((partial, a) => partial + a, 0);

    return {
        'simulations': simulationsArray,
        'gameLengths': gameLengths,
        'shortestGame': Math.min(...gameLengths),
        'longestGame': Math.max(...gameLengths),

        // Overall accuracies
        'player1BatchAccuracy': player1ShotsMade / player1ShotsTaken,
        'player2BatchAccuracy': player2ShotsMade / player2ShotsTaken,

        // Accuracy breakdown for graphic
        'playerAccuracies': {
            'player1': simulationsArray.map(x => x.player1Shots > 0 ? 100.0 * x.player1ShotsMade / x.player1Shots : null).filter(x => x !== null),
            'player2': simulationsArray.map(x => x.player2Shots > 0 ? 100.0 * x.player2ShotsMade / x.player2Shots : null).filter(x => x !== null),
        },

        // Overall wins
        'playerWins': {
            'player1': simulationsArray.filter(simul => simul.winner === 'player1').length,
            'player2': simulationsArray.filter(simul => simul.winner === 'player2').length,
        },

        // Overall shot metrics
        'playerShots': {
            'player1Total': player1ShotsTaken,
            'player2Total': player2ShotsTaken,
            'player1Made': simulationsArray.map(simul => simul.player1ShotsMade).reduce((partial, a) => partial + a, 0),
            'player2Made': simulationsArray.map(simul => simul.player2ShotsMade).reduce((partial, a) => partial + a, 0),
        }
    };
};


export const batchSimulateBreakGame = (simulateGame, totalSimulations, players) => {
    // console.time('batchSimulate')
    let simulationsArray = [];
    let breakPlayers = ['player1', 'player2'];
    let simulation = {};

    for (let simulationIndex = 0; simulationIndex < totalSimulations; simulationIndex++) {
        simulation = simulateGame(
            players['player1'],
            players['player2'],
            breakPlayers[simulationIndex % 2]
        );

        simulationsArray.push(simulation);
    };
    // console.timeEnd('batchSimulate')

    const gameLengths = simulationsArray.map(simul => simul.totalShots);
    const player1ShotsTaken = simulationsArray.map(simul => simul.player1Shots).reduce((partial, a) => partial + a, 0);
    const player2ShotsTaken = simulationsArray.map(simul => simul.player2Shots).reduce((partial, a) => partial + a, 0);
    const player1ShotsMade = simulationsArray.map(simul => simul.player1ShotsMade).reduce((partial, a) => partial + a, 0);
    const player2ShotsMade = simulationsArray.map(simul => simul.player2ShotsMade).reduce((partial, a) => partial + a, 0);

    return {
        'simulations': simulationsArray,
        'gameLengths': gameLengths,
        'shortestGame': Math.min(...gameLengths),
        'longestGame': Math.max(...gameLengths),

        // Overall accuracies
        'player1Accuracy': player1ShotsMade / player1ShotsTaken,
        'player2Accuracy': player2ShotsMade / player2ShotsTaken,

        // Accuracy breakdown for graphic
        'playerAccuracies': {
            'player1': simulationsArray.map(x => x.player1Shots > 0 ? 100.0 * x.player1ShotsMade / x.player1Shots : null).filter(x => x !== null),
            'player2': simulationsArray.map(x => x.player2Shots > 0 ? 100.0 * x.player2ShotsMade / x.player2Shots : null).filter(x => x !== null),
        },

        // Overall wins
        'playerWins': {
            'player1': simulationsArray.filter(simul => simul.winner === 'player1').length,
            'player2': simulationsArray.filter(simul => simul.winner === 'player2').length,
        },

        // Overall shot metrics
        'playerShots': {
            'player1Total': player1ShotsTaken,
            'player2Total': player2ShotsTaken,
            'player1Made': simulationsArray.map(simul => simul.player1ShotsMade).reduce((partial, a) => partial + a, 0),
            'player2Made': simulationsArray.map(simul => simul.player2ShotsMade).reduce((partial, a) => partial + a, 0),
        }
    };
};


export const batchSimulateFoulGame = (simulateGame, totalSimulations, players) => {
    // console.time('batchSimulate')
    let simulationsArray = [];
    let breakPlayers = ['player1', 'player2'];
    let simulation = {};

    for (let simulationIndex = 0; simulationIndex < totalSimulations; simulationIndex++) {
        simulation = simulateGame(
            players,
            breakPlayers[simulationIndex % 2]
        );

        simulationsArray.push(simulation);
    };
    // console.timeEnd('batchSimulate')

    const gameLengths = simulationsArray.map(simul => simul.totalShots);
    const gameLengthsPlayer1 = simulationsArray.filter(simul => simul.breakPlayer === 'player1').map(simul => simul.totalShots);
    const gameLengthsPlayer2 = simulationsArray.filter(simul => simul.breakPlayer === 'player2').map(simul => simul.totalShots);
    const player1ShotsTaken = simulationsArray.map(simul => simul.player1Shots).reduce((partial, a) => partial + a, 0);
    const player2ShotsTaken = simulationsArray.map(simul => simul.player2Shots).reduce((partial, a) => partial + a, 0);
    const player1ShotsMade = simulationsArray.map(simul => simul.player1ShotsMade).reduce((partial, a) => partial + a, 0);
    const player2ShotsMade = simulationsArray.map(simul => simul.player2ShotsMade).reduce((partial, a) => partial + a, 0);

    return {
        'simulations': simulationsArray,
        'gameLengths': {
            'all': gameLengths,
            'player1': gameLengthsPlayer1,
            'player2': gameLengthsPlayer2,
        },
        'shortestGame': Math.min(...gameLengths),
        'longestGame': Math.max(...gameLengths),

        // Overall accuracies
        'player1BatchAccuracy': player1ShotsMade / player1ShotsTaken,
        'player2BatchAccuracy': player2ShotsMade / player2ShotsTaken,

        // Accuracy breakdown for graphic
        'playerAccuracies': {
            'player1': simulationsArray.map(x => x.player1Shots > 0 ? 100.0 * x.player1ShotsMade / x.player1Shots : null).filter(x => x !== null),
            'player2': simulationsArray.map(x => x.player2Shots > 0 ? 100.0 * x.player2ShotsMade / x.player2Shots : null).filter(x => x !== null),
        },

        // Overall wins
        'playerWins': {
            'player1': simulationsArray.filter(simul => simul.winner === 'player1').length,
            'player2': simulationsArray.filter(simul => simul.winner === 'player2').length,
        },

        // Overall shot metrics
        'playerShots': {
            'player1Total': player1ShotsTaken,
            'player2Total': player2ShotsTaken,
            'player1Made': simulationsArray.map(simul => simul.player1ShotsMade).reduce((partial, a) => partial + a, 0),
            'player2Made': simulationsArray.map(simul => simul.player2ShotsMade).reduce((partial, a) => partial + a, 0),
        }
    };
};
