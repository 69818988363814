import React, { useCallback, useEffect, useState } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import ClassNames from 'embla-carousel-class-names'
import { displayPercentage } from '../helpers';
import { BasicShotOutcomeIcon } from '../views/sportsModels/englishPool/components/GameShotSummary';
import { ArrowSmallLeftIcon, ArrowSmallRightIcon, ForwardIcon, FireIcon, BackwardIcon } from '@heroicons/react/24/outline';


export const BatchCarousel = ({ slides, content }) => {
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);

    const [emblaRef, emblaApi] = useEmblaCarousel(
        { loop: false, align: 'start', containScroll: 'trimSnaps', draggable: true, inViewThreshold: 1, dragFree: true, slidesToScroll: 2 },
        [ClassNames({ selected: 'my-selected-class', draggable: 'cursor-grab', dragging: 'cursor-grabbing' })]
    );

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        // Scroll finished
        setPrevButtonEnabled(emblaApi.canScrollPrev());
        setNextButtonEnabled(emblaApi.canScrollNext());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        emblaApi.on("select", onSelect);
        onSelect();
    }, [emblaApi, onSelect, slides]);

    const scrollPrev = useCallback(() => {
        if (emblaApi) {
            emblaApi.scrollPrev()
        }
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        if (emblaApi) {
            emblaApi.scrollNext();
        }
    }, [emblaApi]);

    const scrollForwardN = useCallback(() => {
        if (emblaApi) {
            emblaApi.scrollTo(emblaApi.selectedScrollSnap() + 5);
        }
    }, [emblaApi]);

    const scrollBackN = useCallback(() => {
        if (emblaApi) {
            emblaApi.scrollTo(emblaApi.selectedScrollSnap() - 5);
        }
    }, [emblaApi]);

    return (
        <div className="overflow-hidden">
            <div ref={emblaRef} className="relative pl-8">
                <div className="flex">
                    {slides.map((i) =>
                        <div
                            key={i}
                            className="flex flex-col border-r py-2 text-sm"
                        >
                            <div className="flex items-center justify-center h-6 text-sm italic text-gray-500">
                                {i + 1}
                            </div>
                            <div className="flex flex-row">
                                <div
                                    className="flex flex-col text-center"
                                >
                                    <div className={`h-6 w-11 flex flex items-center justify-center border-b ${content[i].winner === 'player1' ? "underline text-badger-green" : ""}`}>P1</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center border-b">{content[i].player1Score}</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center border-b">{content[i].player1ShotsMade}</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center border-b">{content[i].player1Shots - content[i].player1ShotsMade}</div>
                                    <div className="h-6 w-11 text-xs flex items-center justify-center text-center border-b">{displayPercentage(content[i].player1Shots > 0 ? content[i].player1ShotsMade / content[i].player1Shots : 0, false)}</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center">{content[i].player1TopStreak}</div>
                                </div>

                                <div
                                    className="flex flex-col text-center"
                                >
                                    <div className={`h-6 w-11 flex flex items-center justify-center border-b ${content[i].winner === 'player2' ? "underline text-badger-green" : ""}`}>P2</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center border-b">{content[i].player2Score}</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center border-b">{content[i].player2ShotsMade}</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center border-b">{content[i].player2Shots - content[i].player2ShotsMade}</div>
                                    <div className="h-6 w-11 text-xs flex items-center justify-center text-center border-b">{displayPercentage(content[i].player2Shots > 0 ? content[i].player2ShotsMade / content[i].player2Shots : 0, false)}</div>
                                    <div className="h-6 w-11 flex items-center justify-center text-center">{content[i].player2TopStreak}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <BlurSection display={prevButtonEnabled} leftToRight={false} />
                <BlurSection display={nextButtonEnabled} leftToRight={true} />
                <KeyColumn />
            </div>
            <div className="flex flex-wrap justify-between mt-4 w-full sm:w-1/3 items-center mx-auto">
                <PrevNButton prevButtonEnabled={prevButtonEnabled} scrollPrev={scrollBackN} />
                <PrevButton prevButtonEnabled={prevButtonEnabled} scrollPrev={scrollPrev} />
                {/* <hr className="sm:hidden w-full h-0 border-none" /> */}
                <NextButton nextButtonEnabled={nextButtonEnabled} scrollNext={scrollNext} />
                <NextNButton nextButtonEnabled={nextButtonEnabled} scrollNext={scrollForwardN} />
            </div>
        </div>
    );
};


const BlurSection = ({ display = true, leftToRight = true }) => {
    const gradientClasses = leftToRight ? "from-transparent via-transparent to-neutral-100" : "to-transparent via-transparent from-neutral-100";
    const positionClasses = leftToRight ? "top-0 right-0" : "top-0 left-8";

    return (
        <div className={`transition-opacity duration-700 ease-linear ${display ? 'opacity-80' : 'opacity-0'}`}>
            <div className={`hidden sm:block absolute bg-gradient-to-r w-48 z-10 pointer-events-none h-full ${positionClasses} ${gradientClasses}`}></div>
        </div>
    );
};


const PrevButton = ({ prevButtonEnabled, scrollPrev }) => (
    <button className={`${prevButtonEnabled ? 'text-gray-600 border-gray-600' : 'opacity-50 border-badger-red text-badger-red cursor-not-allowed'} mr-5 last:mr-0 border-2 rounded-full fill-current`} onClick={scrollPrev}>
        <ArrowSmallLeftIcon className="h-6 w-6 p-0.5" />
    </button>
);

const PrevNButton = ({ prevButtonEnabled, scrollPrev }) => (
    <button className={`${prevButtonEnabled ? 'text-gray-600 border-gray-600' : 'opacity-50 border-badger-red text-badger-red cursor-not-allowed'} mr-5 last:mr-0 border-2 rounded-full fill-current`} onClick={scrollPrev}>
        <BackwardIcon className="h-6 w-6 p-0.5" />
    </button>
);

const NextButton = ({ nextButtonEnabled, scrollNext }) => (
    <button className={`${nextButtonEnabled ? 'text-gray-600 border-gray-600' : 'opacity-50 border-badger-red text-badger-red cursor-not-allowed'} mr-5 last:mr-0 border-2 rounded-full fill-current`} onClick={scrollNext}>
        <ArrowSmallRightIcon className="h-6 w-6 p-0.5" />
    </button>
);

const NextNButton = ({ nextButtonEnabled, scrollNext }) => (
    <button className={`${nextButtonEnabled ? 'text-gray-600 border-gray-600' : 'opacity-50 border-badger-red text-badger-red cursor-not-allowed'} mr-5 last:mr-0 border-2 rounded-full fill-current`} onClick={scrollNext}>
        <ForwardIcon className="h-6 w-6 p-0.5" />
    </button>
);


const KeyColumn = () => (
    <div
        className="absolute top-0 left-0 flex flex-col text-center py-2 border-r bg-white"
    >
        <div className="h-6 w-8 italic text-center font-bold flex items-center justify-center"></div>
        <div className="h-6 w-8 italic text-center border-b font-bold flex items-center justify-center"></div>
        <div className="h-6 w-8 text-center italic border-b">#</div>
        <div className="h-6 w-8 flex items-center justify-center border-b"><BasicShotOutcomeIcon outcome={true} /></div>
        <div className="h-6 w-8 flex items-center justify-center border-b"><BasicShotOutcomeIcon outcome={false} /></div>
        <div className="h-6 w-8 text-center italic border-b">%</div>
        <div className="h-6 w-8 flex items-center justify-center"><FireIcon className="h-6 w-6 text-badger-orange" /></div>
    </div>
);
