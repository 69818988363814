import React from "react";
import { Helmet } from "react-helmet-async";
import { ArticleTitle, H2Heading } from "../components/Headers";
import { ArticleParagraph } from "../components/Paragraphs";
import { PageBody } from "../components/PageBody";


export const DesigningAnalyticsSchemas = () => {
    return (
        <PageBody>
            <Helmet>
                <title>Designing Analytics Schemas | VizBadger</title>
                <meta name="description" content="Learn how to design analytics schemas that enable impactful data-driven decision making. Discover the importance of a reliable and trustworthy data schema, and explore tips and considerations for building and maintaining one. This article covers topics such as defining the business case, considering historic states, and aggregating KPI metrics on entity tables. Improve your analytics engineering skills and boost the value of your data today!"></meta>
                <meta name="keywords" content="Data Engineering, Data Pipelines, Analytics, Data Analytics, Analytics engineering, Data-driven decision making, Data schema, Business intelligence, Data science projects, Analytics engineering principles, Self-serve systems, Source of truth, Slowly changing dimensions, Entity tables, Aggregate tables, KPI metrics, Business value, Reliable data, Trustworthy data"></meta>
            </Helmet>

            <ArticleTitle title="Designing Analytics Schemas" />

            <ArticleParagraph
                lines={[
                    "To perform impactful data-driven decision making, you need reliable and trustworthy data that is in a good format. A schema that holds this accurate, reliable and a ‘source of truth’ above the ‘raw’ source data is, therefore, vitally important for allowing impactful analytics. It simplifies integration with BI tools and business-wide rollout of data analytics, acting as a foundation for data science projects that can further boost the value of your data.",
                    "Data should be engineered in such a way to make using it simple and effective. Here are a few tips considerations and concepts for building and maintaining an analytics schema. There are tools and technologies that specialise in this, but this is more about analytics engineering principles that might help you start and get the MVP up and running.",
                    "A thorough understanding of the data that is coming in, when it is generated and how it is collected is vital in being able to effectively model data and build solutions."
                ]}
            />

            <H2Heading>Define The Business Case (The Why)</H2Heading>
            <ArticleParagraph
                lines={[
                    "The main goal of this data layer is to enable business value using your available data. It could be to create self-serve systems for subject matter experts or produce the “source of truth” from the complex source data for business-wide analysts to use (it is likely both).",
                    "Business requirements allow you to map out a schema using your source data, all with the focus of delivering those. How many sales were made last month? How many orders are currently “pending”? Which customers have spent more than £100 in the last 2 weeks?",
                    "Keeping the output and business KPI in your mind will help you prioritise the data that is going to have the largest impact, and in what format that is best served. This will be determined through talking to internal teams and stakeholders about what they need to achieve. The data professional will then translate this into a technical solution that solves their problem or drives their efficiency higher.",
                ]}
            />

            <H2Heading>Historic States Matter (Slowly Changing Dimensions)</H2Heading>
            <ArticleParagraph
                lines={[
                    "This is more of a general point that will likely come up in most solutions. Answering questions such as 'How many concurrent customers have been logged in the last year?' and 'What is the average time it takes for an order to go from “placed” to “shipped”?' require historic states of a particular entity. They are likely slowly changing dimensions, and storing that data allows you to answer questions from a point in time, as well as track the time it takes for things to complete or flow through states.",
                    "Updating entity records as they change may allow for good pulse-check statistics, but it doesn’t enable reproducible analysis. This may be an issue with your source data (it is more concerned with a record’s current state than its history), but it is worth ensuring state changes are available for later analysis; for example, to run reports as though it was a particular date which is useful for financial and regulatory reporting, but also validating machine learning accuracies and test datasets.",
                ]}
            />

            <H2Heading>Aggregate KPI Metrics on Entity Tables (Easy Output)</H2Heading>
            <ArticleParagraph
                lines={[
                    "You have full control over your data transformations, and nothing says you can’t have multiple layers within your analytics schema. Don’t get bogged down by the idea that a clean schema is a flat, relational one. The addition of aggregate tables with total values and summary flags allow for easy and accessible rates and headline statistics with simple calculations.",
                    "I’m sure you already do this, probably on your member table (last_login, last_purchase). There is nothing stopping an analyst joining all necessary tables together and running the aggregates when required, but by storing that definition and running it into a permanent table, you are reducing the chance of SQL errors made by the end user which is important when schemas are made available across teams within a business.",
                    "If commonly asked, then this logic can be housed in an aggregate table. These things are often handled by frontend BI tools that house the relationships between tables, but depending on your tech stack, this is an alternative. It does introduce processing dependencies, but if you’re incremental then the impact is ultimately small.",
                ]}
            />
        </PageBody>
    );
};
