import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useState } from "react";

export const AccordionIcon = ({ isActive, size = 6 }) => {
    if (isActive) {
        return (
            <ChevronDownIcon
                className={`h-${size} w-${size} fillCurrent cursor-pointer`}
            />
        );
    };
    return (
        <ChevronRightIcon
            className={`h-${size} w-${size} fillCurrent cursor-pointer`}
        />
    );
};


export const Accordion = ({ defaultActive = true, title, children }) => {
    const [isActive, updateIsActive] = useState(defaultActive);

    const toggleVisibility = () => {
        updateIsActive(isActive ? false : true);
    };

    return (
        <div className="w-full">
            <div
                className={`flex flex-row hover:cursor-pointer items-center justify-between py-4 px-2 ${isActive ? "border-b" : ""}`}
                onClick={() => toggleVisibility()}
            >
                <p className="sm:text-lg">{title}</p>
                <AccordionIcon
                    isActive={isActive}
                />
            </div>
            <div className={`${isActive ? "" : "hidden"} p-2 sm:p-4`}>
                {children}
            </div>
        </div>
    );
};


export const SmallAccordion = ({ defaultActive = false, title, children }) => {
    const [isActive, updateIsActive] = useState(defaultActive);

    const toggleVisibility = () => {
        updateIsActive(isActive ? false : true);
    };

    return (
        <div className="w-full">
            <div
                className={`flex flex-row hover:cursor-pointer items-center justify-between py-1 pr-2 text-gray-800`}
                onClick={() => toggleVisibility()}
            >
                <p className="text-sm italic">{title}</p>
                <AccordionIcon
                    isActive={isActive}
                    size={4}
                />
            </div>
            <div className={`${isActive ? "" : "hidden"}`}>
                {children}
            </div>
        </div>
    );
};
