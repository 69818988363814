import { ExclamationTriangleIcon, BoltIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from "react";
import { GridDiv, GridDivContainer } from "../../../../components/GridDiv";
import { Loading } from "../../../../components/Loading";
import { displayPercentage } from "../../../../helpers";


// Show the current state of the element and update that
const FormElement = ({
    editMode,
    playerAttributes,
    attributeLabel,
    attributeOptions,
    updateFunction,
    attributeGroup,
    attributeKey,
    isPercentage
}) => {
    if (editMode) {
        return (
            <div>
                <p className="text-xs italic text-gray-500 text-left ml-2">{attributeLabel}</p>
                <div className="flex flex-row border items-center justify-between rounded mx-2 mb-2 cursor-pointer text-xs">
                    {attributeOptions.map(option =>
                        <div
                            className={`${option === playerAttributes[attributeGroup][attributeKey] ? "text-white bg-badger-green" : "hover:bg-gray-200"} p-1 sm:p-0.5 w-full`}
                            key={option}
                            onClick={() => updateFunction({ ...playerAttributes, [attributeGroup]: { ...playerAttributes[attributeGroup], [attributeKey]: option } })}
                        >
                            {isPercentage ? option * 100 : option}{isPercentage ? '%' : ''}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    if (isPercentage) {
        return (
            <div>{attributeLabel}: {displayPercentage(playerAttributes[attributeGroup][attributeKey])}</div>
        );
    };

    return (
        <div>{attributeLabel}: {playerAttributes[attributeGroup][attributeKey]}</div>
    );
};


// Handles a single player's attributes
const SinglePlayerAttributes = ({
    playerAttributes, // Attributes to render
    playerName, // Name to render
    playerKey, // Key to use
    includeBreak = true,
    includeFoul = true,
    updatePlayerAttributes // Only call on save
}) => {
    const [editMode, updateEditMode] = useState(false);
    const [local, updateLocal] = useState(null);

    // Define the attributes to create FormElements for
    const regularPlayAttributes = [
        {
            'label': 'Regular Make Chance',
            'options': [0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
            'key': 'make',
            'isPercentage': true,
        }
    ];

    const breakAttributes = [
        {
            'label': 'Break Make Chance',
            'options': [0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            'key': 'make',
            'isPercentage': true,
        },
        {
            'label': 'Split Benefit',
            'options': [0.05, 0.1, 0.25, 0.5, 0.6],
            'key': 'splitBenefit',
            'isPercentage': true,
        },
        {
            'label': 'Split Duration',
            'options': [1, 2, 3, 4, 5],
            'key': 'splitDuration',
            'isPercentage': false,
        },
    ];

    const foulAttributes = [
        {
            'label': 'Regular Play',
            'options': [0.01, 0.02, 0.03, 0.04, 0.05],
            'group': 'regular',
            'key': 'foul',
            'isPercentage': true,
        },
        {
            'label': 'Break',
            'options': [0.01, 0.02, 0.03, 0.04, 0.05],
            'group': 'break',
            'key': 'foul',
            'isPercentage': true,
        },
        {
            'label': 'Pot Black On Foul',
            'options': [0.01, 0.02, 0.03, 0.04, 0.05],
            'group': 'foul',
            'key': 'makeBlack',
            'isPercentage': true,
        },
    ];

    // Run on first render
    useEffect(() => {
        updateLocal(playerAttributes);
    }, [playerAttributes]);

    // You don't want to update anything here, and revert local back to original values
    const revertAttributes = () => {
        updateLocal(playerAttributes);
        updateEditMode(false);
    };

    const saveDetails = () => {
        updatePlayerAttributes(playerKey, local);
        updateEditMode(false);
    };

    if (local === null) {
        return <Loading />
    };

    return (
        <GridDiv
            widthClasses="w-full sm:w-1/2 text-sm sm:text-base text-center sm:text-left items-start"
        >
            <div className="w-full border-l border-r border-t rounded mb-4">
                <div className="border-b p-2">
                    <div className="text-center flex items-center justify-center relative">
                        <p className={`text-base font-semibold tracking-tight ${editMode ? 'text-badger-blue opacity-50' : ''}`}>{playerName}</p>
                        <PencilSquareIcon
                            className={`h-5 w-5 sm:h-6 sm:w-6 absolute top-0 right-0 ${editMode ? 'text-badger-blue opacity-50' : 'text-gray-400 hover:text-gray-800 hover:cursor-pointer'}`}
                            onClick={() => updateEditMode(true)}
                        />
                    </div>
                </div>

                <div className="text-center flex flex-row border-b">
                    <p className="w-1/3 border-r py-2">Regular Play</p>
                    <div className="w-2/3 py-2">
                        {regularPlayAttributes.map((regAttribute) => (
                            <FormElement
                                key={JSON.stringify(regAttribute)}
                                editMode={editMode}
                                playerAttributes={local}
                                attributeLabel={regAttribute.label}
                                attributeOptions={regAttribute.options}
                                updateFunction={updateLocal}
                                attributeGroup="regular"
                                attributeKey={regAttribute.key}
                                isPercentage={regAttribute.isPercentage}
                            />
                        ))}
                    </div>
                </div>

                {includeBreak &&
                    <div className="text-center flex flex-row border-b">
                        <div className="w-1/3 border-r py-2">
                            <div className="flex items-center justify-center"><p className="mr-1">Break</p> <BoltIcon className="h-4 w-4 text-badger-yellow inline-block" /></div>
                        </div>
                        <div className="w-2/3 py-2">
                            {breakAttributes.map((regAttribute) => (
                                <FormElement
                                    key={JSON.stringify(regAttribute)}
                                    editMode={editMode}
                                    playerAttributes={local}
                                    attributeLabel={regAttribute.label}
                                    attributeOptions={regAttribute.options}
                                    updateFunction={updateLocal}
                                    attributeGroup="break"
                                    attributeKey={regAttribute.key}
                                    isPercentage={regAttribute.isPercentage}
                                />
                            ))}
                        </div>
                    </div>
                }

                {includeFoul &&
                    <div className="text-center flex flex-row border-b">
                        <div className="w-1/3 border-r py-2">
                            <div className="flex items-center justify-center"><p className="mr-1">Foul</p> <ExclamationTriangleIcon className="h-4 w-4 text-badger-red inline-block" /></div>
                        </div>
                        <div className="w-2/3 py-2">
                            {foulAttributes.map((regAttribute) => (
                                <FormElement
                                    key={JSON.stringify(regAttribute)}
                                    editMode={editMode}
                                    playerAttributes={local}
                                    attributeLabel={regAttribute.label}
                                    attributeOptions={regAttribute.options}
                                    updateFunction={updateLocal}
                                    attributeGroup={regAttribute.group}
                                    attributeKey={regAttribute.key}
                                    isPercentage={regAttribute.isPercentage}
                                />
                            ))}
                        </div>
                    </div>
                }

                {editMode &&
                    <div className="p-3 border-b flex justify-center items-center text-sm">
                        <div
                            className="text-gray-400 border rounded-lg py-1 px-2 border-gray-200 hover:shadow hover:cursor-pointer hover:border-badger-red mr-3 hover:text-badger-red"
                            onClick={() => revertAttributes()}
                        >
                            Revert
                        </div>
                        <div
                            className="border rounded-lg py-1 px-4 hover:shadow hover:cursor-pointer hover:border-badger-blue bg-badger-blue text-white"
                            onClick={() => saveDetails()}
                        >
                            Update Attributes
                        </div>
                    </div>
                }
            </div>
        </GridDiv>
    );
};


export const PlayerAttributes = ({
    playerAttributes,
    playerNames,
    playerKeys,
    includeBreak = true,
    includeFoul = true,
    updatePlayerAttributes
}) => {
    return (
        <GridDivContainer className="flex flex-col sm:flex-row items-start justify-center text-sm sm:text-base text-center sm:text-left mt-4">
            {playerKeys.map((key, i) => (
                <SinglePlayerAttributes
                    key={i}
                    playerAttributes={playerAttributes[key]}
                    playerName={playerNames[i]}
                    playerKey={key}
                    includeBreak={includeBreak}
                    includeFoul={includeFoul}
                    updatePlayerAttributes={updatePlayerAttributes}
                />
            ))}
        </GridDivContainer>
    );
};



// Handles a single player's attributes
export const SinglePlayerAttributeDisplay = ({
    playerAttributes, // Attributes to render
    playerName, // Name to render
    playerKey, // Key to use
    includeBreak = true,
    includeFoul = true,
    updatePlayerAttributes // Only call on save
}) => {
    const editMode = true;
    const [local, updateLocal] = useState(null);

    // Define the attributes to create FormElements for
    const regularPlayAttributes = [
        {
            'label': 'Regular Make Chance',
            'options': [0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
            'group': 'regular',
            'key': 'make',
            'isPercentage': true,
        }
    ];

    const breakAttributes = [
        {
            'label': 'Break Make Chance',
            'options': [0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
            'group': 'break',
            'key': 'make',
            'isPercentage': true,
        },
        {
            'label': 'Split Benefit',
            'options': [0.05, 0.1, 0.25, 0.5, 0.6],
            'group': 'break',
            'key': 'splitBenefit',
            'isPercentage': true,
        },
        {
            'label': 'Split Duration',
            'options': [1, 2, 3, 4, 5],
            'group': 'break',
            'key': 'splitDuration',
            'isPercentage': false,
        },
    ];

    const foulAttributes = [
        {
            'label': 'Regular Play',
            'options': [0.01, 0.02, 0.03, 0.04, 0.05],
            'group': 'regular',
            'key': 'foul',
            'isPercentage': true,
        },
        {
            'label': 'Break',
            'options': [0.01, 0.02, 0.03, 0.04, 0.05],
            'group': 'break',
            'key': 'foul',
            'isPercentage': true,
        },
        {
            'label': 'Pot Black On Foul',
            'options': [0.01, 0.02, 0.03, 0.04, 0.05],
            'group': 'foul',
            'key': 'makeBlack',
            'isPercentage': true,
        },
    ];

    const allAttributes = regularPlayAttributes.concat(breakAttributes, foulAttributes);

    // Run on first render
    useEffect(() => {
        updateLocal(playerAttributes);
    }, [playerAttributes]);

    // const saveDetails = () => {
    //     updatePlayerAttributes(playerKey, local);
    // };

    const randomiseAttributes = () => {
        // Loop through and change local variables
        allAttributes.forEach(attribute => {
            var newValue = attribute['options'][Math.floor(Math.random() * attribute['options'].length)];
            updateLocal(local => ({ ...local, [attribute.group]: { ...local[attribute.group], [attribute.key]: newValue } }));
        });
    };

    const updatePlayerAttributesRandomly = () => {
        randomiseAttributes();
        // saveDetails();
    };

    if (local === null) {
        return <Loading />
    };

    return (
        <GridDiv
            widthClasses="w-full sm:w-1/2 text-sm sm:text-base text-center sm:text-left items-start"
        >
            <div className="w-full border-l border-r border-t rounded mb-4">
                <div className="border-b p-2">
                    <div className="text-center flex items-center justify-center relative">
                        <p className={`text-base font-semibold tracking-tight ${editMode ? 'text-badger-blue opacity-80' : ''}`}>{playerName}</p>
                    </div>
                </div>

                <div className="text-center flex flex-row border-b">
                    <p className="w-1/3 border-r py-2">Regular Play</p>
                    <div className="w-2/3 py-2">
                        {regularPlayAttributes.map((regAttribute) => (
                            <FormElement
                                key={JSON.stringify(regAttribute)}
                                editMode={editMode}
                                playerAttributes={local}
                                attributeLabel={regAttribute.label}
                                attributeOptions={regAttribute.options}
                                updateFunction={updateLocal}
                                attributeGroup="regular"
                                attributeKey={regAttribute.key}
                                isPercentage={regAttribute.isPercentage}
                            />
                        ))}
                    </div>
                </div>

                {includeBreak &&
                    <div className="text-center flex flex-row border-b">
                        <div className="w-1/3 border-r py-2">
                            <div className="flex items-center justify-center"><p className="mr-1">Break</p> <BoltIcon className="h-4 w-4 text-badger-yellow inline-block" /></div>
                        </div>
                        <div className="w-2/3 py-2">
                            {breakAttributes.map((regAttribute) => (
                                <FormElement
                                    key={JSON.stringify(regAttribute)}
                                    editMode={editMode}
                                    playerAttributes={local}
                                    attributeLabel={regAttribute.label}
                                    attributeOptions={regAttribute.options}
                                    updateFunction={updateLocal}
                                    attributeGroup="break"
                                    attributeKey={regAttribute.key}
                                    isPercentage={regAttribute.isPercentage}
                                />
                            ))}
                        </div>
                    </div>
                }

                {includeFoul &&
                    <div className="text-center flex flex-row border-b">
                        <div className="w-1/3 border-r py-2">
                            <div className="flex items-center justify-center"><p className="mr-1">Foul</p> <ExclamationTriangleIcon className="h-4 w-4 text-badger-red inline-block" /></div>
                        </div>
                        <div className="w-2/3 py-2">
                            {foulAttributes.map((regAttribute) => (
                                <FormElement
                                    key={JSON.stringify(regAttribute)}
                                    editMode={editMode}
                                    playerAttributes={local}
                                    attributeLabel={regAttribute.label}
                                    attributeOptions={regAttribute.options}
                                    updateFunction={updateLocal}
                                    attributeGroup={regAttribute.group}
                                    attributeKey={regAttribute.key}
                                    isPercentage={regAttribute.isPercentage}
                                />
                            ))}
                        </div>
                    </div>
                }

                {editMode &&
                    <div className="p-3 border-b flex justify-center items-center text-sm">
                        <div
                            className="text-gray-400 border rounded-lg py-1 px-2 border-gray-200 hover:shadow hover:cursor-pointer hover:border-badger-blue mr-3 hover:text-badger-blue"
                            onClick={() => updatePlayerAttributesRandomly()}
                        >
                            Randomise
                        </div>
                    </div>
                }
            </div>
        </GridDiv>
    );
};
