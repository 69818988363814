import { createContext, useMemo } from "react";
import * as d3 from "d3";

const ChartContext = createContext();

export const AxisHorizontal = ({
	domain = [0, 100],
	range = [10, 290],
	dimensions
}) => {
	const ticks = useMemo(() => {
		const xScale = d3.scaleLinear()
			.domain(domain)
			.range(range)

		const width = range[1] - range[0]
		const pixelsPerTick = 40
		const numberOfTicksTarget = Math.max(
			1,
			Math.floor(
				width / pixelsPerTick
			)
		)

		return xScale.ticks(numberOfTicksTarget)
			.map(value => ({
				value,
				xOffset: xScale(value)
			}))
	}, [
		domain.join("-"),
		range.join("-")
	])

	return (
		<g transform={`translate(${[
			0,
			dimensions.boundedHeight,
		].join(",")})`}>
			{/* <path
				d={[
					"M", range[0], 6,
					"v", -6,
					"H", range[1],
					"v", 6,
				].join(" ")}
				fill="none"
				stroke="currentColor"
			/> */}
			<line
				x2={dimensions.boundedWidth}
				stroke="currentColor"
			/>
			{ticks.map(({ value, xOffset }) => (
				<g
					key={value}
					transform={`translate(${xOffset}, 0)`}
				>
					<line
						y2="6"
						stroke="currentColor"
					/>
					<text
						key={value}
						style={{
							fontSize: "10px",
							textAnchor: "middle",
							transform: "translateY(20px)"
						}}>
						{value}
					</text>
				</g>
			))}
		</g>
	);
};


export const ChartCanvas = ({ dimensions, children }) => (
	<ChartContext.Provider value={dimensions}>
		<svg
			// viewBox="0 0 100 100"
			width={dimensions.width}
			height={dimensions.height}
		>
			<g id="boundedCanvas"
				transform={`translate(${dimensions.marginLeft
					}, ${dimensions.marginTop
					})`}
			>
				{children}
			</g>
		</svg>
	</ChartContext.Provider>
);


export const AxisVertical = ({
	domain = [0, 100],
	range = [10, 290],
	dimensions
}) => {
	const ticks = useMemo(() => {
		const yScale = d3.scaleLinear()
			.domain(domain)
			.range(range)

		const height = range[1] - range[0]
		const pixelsPerTick = 25
		const numberOfTicksTarget = Math.max(
			1,
			Math.floor(
				height / pixelsPerTick
			)
		)

		return yScale.ticks(numberOfTicksTarget)
			.map(value => ({
				value,
				// Flip the offset to reverse the ticks
				yOffset: range[1] - yScale(value)
			}))
	}, [
		domain.join("-"),
		range.join("-")
	])

	return (
		<g
			// No need to transform axis here
			transform={`translate(${[
			0,
			0,
		].join(",")})`}>
			<line
				y2={dimensions.boundedHeight}
				stroke="currentColor"
			/>
			{ticks.map(({ value, yOffset }) => (
				<g
					key={value}
					transform={`translate(0,${yOffset})`}
				>
					<line
						x2="-6"
						stroke="currentColor"
					/>
					<text
						key={value}
						dominantBaseline="middle"
						textAnchor="end"
						style={{
							fontSize: "10px",
							transform: "translateX(-10px)"
						}}>
						{value}
					</text>
				</g>
			))}
			{/* <g
				key="toptick"
				transform={`translate(0,${0.5})`}
			>
				<line
					x2="-6"
					stroke="currentColor"
				/>
			</g> */}
		</g>
	);
};


export const AxisLabelHorizontal = ({ label, dimensions, classes }) => {
	const transformX = dimensions.boundedWidth / 2;
	const transformY = dimensions.boundedHeight + dimensions.marginTop + dimensions.marginBottom / 2;
	return (
		<g transform={`translate(${transformX}, ${transformY})`}>
			<text className={classes} alignmentBaseline="central" textAnchor="middle">{label}</text>
		</g>
	);
};


export const AxisLabelVertical = ({ label, dimensions, classes }) => {
	const transformX = 0 - dimensions.marginRight - dimensions.marginLeft / 2;
	const transformY = dimensions.boundedHeight / 2;
	return (
		<g transform={`translate(${transformX}, ${transformY})`}>
			<text className={classes} transform="rotate(-90)" alignmentBaseline="central" textAnchor="middle">{label}</text>
		</g>
	)
};


export const BandedAxisHorizontal = ({
	domain = [0, 100],
	range = [10, 290],
	bandTicks,
	dimensions,
	padding
}) => {
	const ticks = useMemo(() => {
		const xScale = d3.scaleBand()
			.domain(domain)
			.range(range).padding(padding);

		return bandTicks.map(value => ({
			value,
			xBarStart: xScale(value),
			bandwidth: xScale.bandwidth(),
			xOffset: xScale(value) + xScale.bandwidth() / 2
		}))
	}, [
		domain.join("-"),
		range.join("-")
	])

	return (
		<g transform={`translate(${[
			0,
			dimensions.boundedHeight,
		].join(",")})`}>
			<line
				x2={dimensions.boundedWidth}
				stroke="currentColor"
			/>
			{ticks.map(({ value, xOffset }) => (
				<g
					key={value}
					transform={`translate(${xOffset}, 0)`}
				>
					<line
						y2="6"
						stroke="currentColor"
					/>
					<g transform={`translate(3, 10)`}>
						<text
							key={value}
							style={{
								fontSize: "10px",
							}}
							transform="rotate(-45)"
							alignmentBaseline="central"
							textAnchor="end"
							>
							{value}
						</text>
					</g>
				</g>
			))}
		</g>
	);
};
