import { AxisHorizontal, AxisLabelHorizontal, AxisVertical, ChartCanvas } from "../../../../components/graphs/basicGraph";
import { useChartDimensions } from "../../../../components/graphs/useChartDimensions";
import * as d3 from "d3";

const LollipopPoints = ({ aggregateData, xScale, yScale, dimensions }) => (
    <g>
        {aggregateData.map((d) => (
            <circle
                key={d[0]}
                cx={xScale(d[0])}
                cy={yScale(d[1])}
                r={dimensions.boundedWidth < 480 ? 2 : 3}
                className="fill-badger-green"
            />
        ))}
    </g>
);

const LollipopLines = ({ aggregateData, xScale, yScale, dimensions }) => (
    <g>
        {aggregateData.map((d) => (
            <line
                key={d[0]}
                x1={xScale(d[0])}
                y1={yScale(0)}
                x2={xScale(d[0])}
                y2={yScale(d[1])}
                stroke="currentColor"
                className={`${dimensions.boundedWidth < 480 ? "stroke-2" : "stroke-4"} text-badger-green`}
            />
        ))}
    </g>
);


export const Histogram = ({ chartSettings, dataArray, filteredDataArray, xLabel, medianValue }) => {
    const [ref, dimensions] = useChartDimensions(chartSettings);
    const xPadding = 2;

    // Calculate number of times each number appears in array: [number, nTimes]
    const groupCounts = Array.from(d3.rollup(filteredDataArray, v => v.length, d => d));
    const overallGroupCounts = Array.from(d3.rollup(dataArray, v => v.length, d => d));
    const xMin = d3.min(dataArray);
    const yMax = d3.max(overallGroupCounts.map(d => d[1]));
    const xRange = d3.range(xMin - xPadding, d3.max(dataArray) + 1 + xPadding);
    const xScale = d3.scalePoint().domain(xRange).range([0, dimensions.boundedWidth]);
    const yScale = d3.scaleLinear().domain([0, Math.round(yMax * 1.25, 0)]).range([dimensions.boundedHeight, 0]);

    // const bins = d3.bin().thresholds(numberBins);
    // const buckets = bins(filteredDataArray);

    return (
        <div ref={ref} className="w-full">
            <ChartCanvas dimensions={dimensions}>
                <line
                    x1={xScale(medianValue)}
                    x2={xScale(medianValue)}
                    y1={8}
                    y2={dimensions.boundedHeight}
                    className="text-badger-blue"
                    stroke="currentColor"
                    strokeDasharray="3"
                    strokeWidth={2}
                />
                <text
                    fontSize={12}
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    x={xScale(medianValue)}
                    y={0}
                    className="fill-badger-blue"
                >
                    {medianValue}
                </text>
                <LollipopLines
                    aggregateData={groupCounts}
                    xScale={xScale}
                    yScale={yScale}
                    dimensions={dimensions}
                />
                <LollipopPoints
                    aggregateData={groupCounts}
                    xScale={xScale}
                    yScale={yScale}
                    dimensions={dimensions}
                />
                <AxisHorizontal
                    dimensions={dimensions}
                    domain={[d3.min(xRange), d3.max(xRange)]}
                    range={[0, dimensions.boundedWidth]}
                />
                <AxisVertical
                    dimensions={dimensions}
                    domain={[0, Math.round(yMax * 1.25, 0)]}
                    range={[0, dimensions.boundedHeight]}
                />
                <AxisLabelHorizontal
                    label={xLabel}
                    dimensions={dimensions}
                    classes="text-xs sm:text-sm"
                />
            </ChartCanvas>
        </div>
    );
};
