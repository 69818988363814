import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { ArticleParagraph } from "../../../components/Paragraphs";
import { BettingHomeButton } from "../../../components/HomeLinks";
import { QueueListIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";


export const KellyCriterion = () => {
    return (
        <PageBody>
            <Helmet>
                <title>Kelly Criterion for Managing Sports Betting Bankroll | VizBadger</title>
                <meta name="description" content="Learn about the Kelly Criterion, a popular method for managing your bankroll when sports betting. This article covers why bankroll management is important, how the Kelly Criterion works, its pros and cons, and alternate bankroll management methods."></meta>
                <meta name="keywords" content="Kelly Criterion, sports betting, sports staking, betting accumulators, expected value betting, implied probability, bankroll management, football betting, responsible gambling, value betting, sports betting strategy"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/betting/kelly-criterion-bankroll-management" />
            </Helmet>

            <BettingHomeButton />

            <ArticleTitle
                title="Kelly Criterion for Managing Sports Betting Bankroll"
            />

            <ArticleParagraph
                lines={[
                    "Sports betting is a popular activity for many people around the world. While it can be an enjoyable activity, it's important to remember that it is inherently risky, and managing your bankroll is key to ensuring that you don't lose more money than you can afford.",
                    "In this article, we'll discuss the Kelly Criterion, a popular method for managing your bankroll when sports betting.",
                ]}
            />

            <H2Heading>Why is it important to manage your bankroll when sports betting?</H2Heading>
            <ArticleParagraph
                lines={[
                    "Managing your bankroll is essential when sports betting, as it helps you avoid losing more money than you can afford. Without proper bankroll management, you may be tempted to place larger bets than you should, which can lead to significant losses. By managing your bankroll, you can limit your risk when betting and ensure that you have enough funds to continue betting over the long term.",
                ]}
            />

            <H2Heading>Kelly Criterion: Strengths, weaknesses, and how it works</H2Heading>
            <ArticleParagraph
                lines={[
                    "The Kelly Criterion is a popular method for managing your bankroll when sports betting. It was developed in the 1950s and is based on the idea of maximizing your long-term growth rate. The formula for the Kelly Criterion is:",
                ]}
            />
            <div className="text-sm sm:text-base">
                <div className="flex flex-col items-center justify-center text-sm sm:text-base my-3">
                    <p className="mb-2 py-2 px-6 bg-gray-100 rounded rounded-lg text-center tracking-wider italic">f* = (bp - q) / b</p>
                </div>
                <p>Where:</p>
                <ul class="list-disc ml-4 sm:ml-8 mb-4">
                    <li>f* is the optimal fraction of your bankroll to bet</li>
                    <li>b is the decimal odds - 1</li>
                    <li>p is the probability of winning (0 - 1)</li>
                    <li>q is the probability of losing, which is equal to 1 - p</li>
                </ul>
            </div>

            <ArticleParagraph
                lines={[
                    "Note that to apply the Kelly Criterion, you need to know the implied probability of the bet you're making. Implied probability is the probability that the odds suggest a particular outcome will occur. For example, if the odds of a football team winning are 2.0, the implied probability is 1/2.0, or 50%.",
                    "One of the strengths of the Kelly Criterion is that it can help you maximise your long-term growth rate by taking into account the size of your bankroll and the probability of winning. Using a method like this can take away some of the thought process of how much to bet and can aid in taking some of the emotion of the decision or chasing losses (assuming you don’t let that impact your win estimate).",
                    "However, a weaknesses is that it can be difficult to apply in practice, as it requires accurate estimations of the probabilities of winning and losing; these estimations may come from a prediction model using sports analytics or from an analysis of the market across various bookmakers to find one that has a price that is ‘out of line’. It can also come from your own judgment, but that opens you up to inconsistencies and emotional decision making.",
                ]}
            />

            <H2Heading>How to calculate using the Kelly Criterion</H2Heading>
            <ArticleParagraph
                lines={[
                    "To use the Kelly Criterion, you need to calculate the optimal fraction of your bankroll to bet using the formula explained above. For example, if the odds of a football team winning are 2.0, and you estimate the probability of winning to be 60%, the optimal fraction to bet would be:",
                ]}
            />
            <div className="flex flex-col items-center justify-center text-sm sm:text-base my-3">
                <div className="mb-2 rounded rounded-lg text-center tracking-wider italic bg-gray-100">
                    <p className="pt-2 px-6">f* = (2.0 x 0.6 - 0.4) / (2.0 - 1)</p>
                    <p className="py-2 px-6">f* = 0.2</p>
                </div>
            </div>

            <ArticleParagraph lines={["This means that you should bet 20% of your bankroll on this bet. You can see above how heavily this relies on you having this preconceived estimate of how likely a particular outcome is. Another example of Kelly criterion bankroll calculation is below (with an even larger discrepancy in the odds, or greater betting value). "]} />

            <H2Heading>Large EV Example</H2Heading>
            <ArticleParagraph lines={["Let's say you have a bankroll of £100, and you want to bet on a football match where the odds are 2.50 for the home team to win. After analyzing the match, you estimate that the probability of the home team winning is 60%.", "In this case, b = 2.50 - 1 = 1.50, p = 0.60, and q = 1 - 0.60 = 0.40. Plugging these values into the formula, we get:"]} />
            <div className="flex flex-col items-center justify-center text-sm sm:text-base my-3">
                <div className="mb-2 rounded rounded-lg text-center tracking-wider italic bg-gray-100">
                    <p className="pt-2 px-6">f* = (2.50 x 0.60 - 0.40) / 1.50</p>
                    <p className="py-2 px-6">f* = 0.40</p>
                </div>
            </div>
            <ArticleParagraph
                lines ={[
                    "This means that the optimal fraction of your bankroll to bet on this football match is 40%. To calculate the actual amount you should bet, simply multiply 40% by your bankroll of £100, which gives you £40.",
                    "So according to the Kelly Criterion, you should bet £40 on the home team to win in this football match. Keep in mind that the Kelly Criterion is a method for maximizing your long-term growth rate, so you may not always want to bet the full ‘optimal’ fraction on every bet.",
                    "It's important to use your own judgment and adapt the Kelly Criterion to your own preferences and risk tolerance; 40% of your entire bankroll is probably too steep for most players, and you’re unlikely to find an example with such a large value proposition so perhaps you cap your maximum percentage at something you are more comfortable with. ",
                ]}
            />

            <H2Heading>No Value Example</H2Heading>
            <ArticleParagraph
                lines={[
                    "The Kelly Criterion helps find the optimal stake size for bets that have value (the odds offered differ advantageously from your likelihood estimate), so if you run an example where the odds aren’t good value you get the example below.",
                    "Let's say you have a bankroll of £100 and you want to bet on a tennis match where the odds are 1.50 for Player A to win. After analyzing the match yourself, you estimate that the probability of Player A winning is 45%. ",
                    "Using the Kelly Criterion formula, in this case, b = 1.50 - 1 = 0.50, p = 0.45, and q = 1 - 0.45 = 0.55. Plugging these values into the formula, we get:",
                ]}
            />
            <div className="flex flex-col items-center justify-center text-sm sm:text-base my-3">
                <div className="mb-2 rounded rounded-lg text-center tracking-wider italic bg-gray-100">
                    <p className="pt-2 px-6">f* = (1.50 x 0.45 - 0.55) / 0.50</p>
                    <p className="py-2 px-6">f* = -0.05</p>
                </div>
            </div>
            <ArticleParagraph
                lines={[
                    "This means that the optimal fraction of your bankroll to bet on this tennis match is -5%. A negative percentage means that you should not bet at all, because the expected value of the bet is negative. In other words, the odds are not in your favor, and the risk of losing money is greater than the potential reward.",
                    "So according to the Kelly Criterion, you should not bet on this tennis match, because the expected value of the bet is negative. Instead, you should look for other opportunities where the Kelly Criterion returns a positive percentage and the expected value is positive. This mathematical clarification can help you to make better bets that will help you in the long run.",
                ]}
            />

            <H2Heading>Alternate bankroll management methods</H2Heading>
            <div className="flex items-center justify-center text-sm sm:text-base">
                <ul className="list-decimal sm:ml-8 mb-2">
                    <li>Fixed Unit Betting</li>
                    <li>Percentage of Bankroll Betting</li>
                    <li>Unit or Point Betting</li>
                    <li>Expected Value Betting</li>
                </ul>
            </div>

            <div className="flex items-center justify-center text-sm sm:text-base">
                <Link
                    to="/betting/sports-betting-bankroll-management-strategies"
                    className="sm:w-auto w-full sm:w-2/3 border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 flex justify-center items-center cursor-pointer hover:shadow-lg mb-8"
                >
                    <QueueListIcon className="h-7 w-7 mr-2 text-badger-blue" />
                    <span className="mr-2 text-center text-black w-full">Sports Betting Bankroll Management Strategies</span>
                </Link>
            </div>

            <H2Heading>Responsible gambling</H2Heading>
            <ArticleParagraph lines={["No matter which bankroll management method you choose, it's important to remember to gamble responsibly. This means only betting with money that you can afford to lose, setting limits on how much you bet, and never chasing losses. Remember that sports betting should be fun and shouldn’t be considered a reliable source of income."]} />
        </PageBody>
    );
};
