import { lusolve } from 'mathjs';

// Fixtures can be given a weight as to how much they count:
// Number of games ago for the group
// Size of win
// Period of time since the game was played
const sumFixtureWeight = (acc, obj) => acc += (obj.fixture_weight * obj.win_weight);

export const summariseMatches = (players, matches) => {
    let summary = Array(players.length);
    let wins = 0;
    let losses = 0;
    let games = 0;
    let weightedWins = 0;
    let weightedFixtures = 0;

    for (let i = 0; i < players.length; i++) {
        const player = players[i].id;
        wins = matches.filter(match => match.winner_player_id === player).length;
        games = matches.filter(match => match.player_1_id === player || match.player_2_id === player).length;
        losses = games - wins;

        weightedWins = matches.filter(match => match.winner_player_id === player).reduce(sumFixtureWeight, 0);
        weightedFixtures = matches.filter(match => match.player_1_id === player || match.player_2_id === player).reduce(sumFixtureWeight, 0);

        summary[i] = [games, wins, losses, weightedWins, weightedFixtures]
    }
    return summary;
};


export const generateRatings = (fixtureMatrix, resultsVector) => {
    return lusolve(fixtureMatrix, resultsVector).map(ranking => (ranking[0] * 100.0).toFixed(2));
};


export const createResultsVectors = (players, matches) => {
    let wins = Array(players.length).fill(0);
    let losses = Array(players.length).fill(0);
    let resultScores = Array(players.length).fill(0);

    for (let i = 0; i < players.length; i++) {
        wins[i] = matches.filter(match => match.winner_player_id === players[i].id).length;
        losses[i] = matches.filter(match => match.winner_player_id !== players[i].id && (match.player_1_id === players[i].id || match.player_2_id === players[i].id)).length;
        resultScores[i] = 1 + ((wins[i] - losses[i])) / 2;
    };

    return [resultScores, wins, losses];
};


export const createWeightedResultsVectors = (players, matches) => {
    let wins = Array(players.length).fill(0);
    let losses = Array(players.length).fill(0);
    let resultScores = Array(players.length).fill(0);

    for (let i = 0; i < players.length; i++) {
        wins[i] = matches.filter(match => match.winner_player_id === players[i].id).reduce(sumFixtureWeight, 0);
        losses[i] = matches.filter(match => match.winner_player_id !== players[i].id && (match.player_1_id === players[i].id || match.player_2_id === players[i].id)).reduce(sumFixtureWeight, 0);
        resultScores[i] = 1 + ((wins[i] - losses[i])) / 2;
    };

    return resultScores;
};


export const createFixtureMatrix = (players, matches) => {
    let fixtureMatrix = Array.from(Array(players.length), () => Array(players.length).fill(0));
    let totalFixtures = 0;

    // Loop through player combinations and count fixtures
    // Do you scale the fixture matrix? If you don't, do all scores just trend to 0.5?
    for (let i = 0; i < players.length; i++) {
        for (let j = 0; j < players.length; j++) {
            if (i === j) {
                fixtureMatrix[i][j] = matches.filter(match => (match.player_1 === players[i].name || match.player_2 === players[i].name)).length + 2;
            } else {
                totalFixtures = matches.filter(match => (match.player_1 === players[i].name || match.player_1 === players[j].name) && (match.player_2 === players[i].name || match.player_2 === players[j].name)).length * -1;
                fixtureMatrix[i][j] = totalFixtures;
                fixtureMatrix[j][i] = totalFixtures;
            };
        };
    };

    return fixtureMatrix;
};


export const createWeightedFixtureMatrix = (players, matches) => {
    let fixtureMatrix = Array.from(Array(players.length), () => Array(players.length).fill(0));
    let totalFixtures = 0;

    // Loop through player combinations and count fixtures
    // Do you scale the fixture matrix? If you don't, do all scores just trend to 0.5?
    for (let i = 0; i < players.length; i++) {
        for (let j = 0; j < players.length; j++) {
            if (i === j) {
                fixtureMatrix[i][j] = matches.filter(match => (match.player_1 === players[i].name || match.player_2 === players[i].name)).reduce(sumFixtureWeight, 0) + 2;
            } else {
                totalFixtures = matches.filter(match => (match.player_1 === players[i].name || match.player_1 === players[j].name) && (match.player_2 === players[i].name || match.player_2 === players[j].name)).reduce(sumFixtureWeight, 0) * -1;
                fixtureMatrix[i][j] = totalFixtures;
                fixtureMatrix[j][i] = totalFixtures;
            };
        };
    };

    return fixtureMatrix;
};