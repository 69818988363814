import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getPlayers } from '../../api/cricket';
import { PageHeader } from "../../components/Headers";
import { Helmet } from "react-helmet-async";
import { GridDiv, GridDivContainer } from "../../components/GridDiv";
import { PageBody } from "../../components/PageBody";
import { Loading } from "../../components/Loading";


const PlayerCompare = ({ display, selectedPlayers }) => {
	if (display) {
		return (
			<div>
				<p className="italic text-gray-600 mb-1 sm:mb-2 text-center tracking-tight">Players to Compare</p>
				<div className="flex">
					<div className="mb-4 flex-1">
						<GridDivContainer>
							<GridDiv widthClasses="w-full sm:w-1/2">
								<div className={`mb-3 sm:mb-0 flex items-center justify-center border px-4 py-2 rounded ${selectedPlayers.length > 0 ? 'border-badger-blue text-badger-blue' : 'border-gray-400 text-gray-400 border-dashed'}`}>
									{selectedPlayers.length > 0 ? selectedPlayers[0].name : 'Select First Player...'}
								</div>
							</GridDiv>
							<GridDiv widthClasses="w-full sm:w-1/2">
								<div className={`flex items-center justify-center border px-4 py-2 rounded ${selectedPlayers.length > 1 ? 'border-badger-blue text-badger-blue' : 'border-gray-400 text-gray-400 border-dashed'}`}>
									{selectedPlayers.length > 1 ? selectedPlayers[1].name : 'Select Second Player...'}
								</div>
							</GridDiv>
						</GridDivContainer>
					</div>
					<div className={`px-4 rounded flex items-center justify-center mb-4 sm:ml-4 ${selectedPlayers.length === 2 ? 'bg-badger-green text-white cursor-pointer' : 'border border-gray-400 text-gray-400'}`}>
						{selectedPlayers.length === 2 ? 'Compare' : 'Selecting...'}
					</div>
				</div>
			</div>
		);
	};
};


const PlayerTile = ({
	player,
	isComparisonMode,
	isComparisonSelected,
	handleComparisonClick,
	selectedPlayerCount
}) => {
	if (!isComparisonMode) {
		return (
			<Link
				className="flex border border-gray-200 shadow rounded cursor-pointer hover:shadow-lg p-3 my-2"
				to={`player/${player.url_slug}`}
			>
				{player.name}
			</Link>
		);
	};

	return (
		<div
			className={`flex border shadow rounded ${isComparisonSelected ? 'text-badger-blue opacity-75 shadow border-badger-blue' : selectedPlayerCount === 2 ? 'text-badger-red opacity-50 border-badger-red' : 'border-gray-200 cursor-pointer hover:shadow-lg'} p-3 my-2`}
			onClick={() => handleComparisonClick(player)}
		>
			{player.name}
		</div>
	);
};

export function TestCricketPlayers() {
	const { isLoading, isError, data, error } = useQuery("cricketPlayers", getPlayers);
	const [searchTerm, updateSearchTerm] = useState('');
	const [filteredPlayers, updateFilteredPlayers] = useState([]);
	const [compareModeActive, setCompareModeActive] = useState(false);
	const [selectedPlayers, setSelectedPlayers] = useState([]);

	// Wait for query to complete
	useEffect(() => {
		// If the query hasn't errored then update the array
		if (!isError && !isLoading) {
			updateFilteredPlayers(data.data);
		}
	}, [data, isError, isLoading]); // React to these variables changing

	if (isLoading) {
		return <Loading></Loading>
	};

	if (isError) {
		return <span>Error: {error.message}</span>
	};

	const handleComparisonClick = (player) => {
		if (selectedPlayers.filter(selectedPlayer => selectedPlayer.player_id === player.player_id).length === 0) {
			// Add Player
			setSelectedPlayers([...selectedPlayers, player]);
		};
	};

	const handleCompareModeToggle = () => {
		if (compareModeActive) {
			// Clear out the selected players
			setSelectedPlayers([]);
		};

		setCompareModeActive(compareModeActive ? false : true);
	};

	// Search the players
	function searchPlayers(searchTerm) {
		updateSearchTerm(searchTerm);
		let newFilteredPlayers = data.data.filter((player) => {
			return player.name.toLowerCase().includes(searchTerm.toLowerCase());
		});
		updateFilteredPlayers(newFilteredPlayers);
	};

	return (
		<PageBody>
			<Helmet>
				<title>Explore Cricket Test Match Batting Careers | VizBadger</title>
				<meta name="description" content="Search and explore men's cricket test match players batting careers through visualisations and data analytics."></meta>
			</Helmet>
			<PageHeader title="Explore Test Match Batting Careers" />
			<PlayerCompare
				display={compareModeActive}
				selectedPlayers={selectedPlayers}
			/>
			<div className="flex sm:flex-row flex-col items-center">
				<input
					value={searchTerm}
					onChange={e => searchPlayers(e.target.value)}
					placeholder="Search Player Name..."
					className="input border border-gray-400 rounded flex-1 w-full px-3 py-2 focus:border-badger-purple focus:outline-none active:outline-none active:border-badger-purple"
				/>
				{/* <div
					className={`text-xs text-center sm:text-base w-full sm:w-auto mt-2 sm:mt-0 sm:ml-4 py-1 sm:py-2 px-4 border rounded hover:cursor-pointer ${compareModeActive ? 'border-badger-blue text-badger-blue' : 'border-gray-300 text-gray-300 hover:text-gray-500 hover:border-gray-500'}`}
					onClick={() => handleCompareModeToggle()}
				>
					{!compareModeActive ? 'Compare?': 'Comparing'}
				</div> */}
			</div>
			{searchTerm === "" &&
				<p className="italic text-gray-500 text-sm mt-3">
					Total players available: {data.data.length}
				</p>
			}
			{filteredPlayers.length !== data.data.length &&
				<p className="italic text-gray-500 text-sm mt-3">
					Search returned {filteredPlayers.length} player{filteredPlayers.length === 1 ? '' : 's'}
				</p>
			}
			<GridDivContainer>
				{filteredPlayers.map(player =>
					<GridDiv
						key={player.player_id}
						widthClasses="w-full sm:w-1/2 md:w-1/3"
					>
						<PlayerTile
							player={player}
							isComparisonMode={compareModeActive}
							isComparisonSelected={selectedPlayers.filter(selectedPlayer => selectedPlayer.player_id === player.player_id).length > 0}
							handleComparisonClick={handleComparisonClick}
							selectedPlayerCount={selectedPlayers.length}
						/>
					</GridDiv>
				)}
			</GridDivContainer>
		</PageBody>
	)
};
