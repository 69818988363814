const regularPlayAttributes = [
    {
        'label': 'Regular Make Chance',
        'options': [0.2, 0.3, 0.4, 0.5, 0.6, 0.7],
        'group': 'regular',
        'key': 'make',
        'isPercentage': true,
    }
];

const breakAttributes = [
    {
        'label': 'Break Make Chance',
        'options': [0.3, 0.4, 0.5, 0.6, 0.7, 0.8],
        'group': 'break',
        'key': 'make',
        'isPercentage': true,
    },
    {
        'label': 'Split Benefit',
        'options': [0.05, 0.1, 0.25, 0.5, 0.6],
        'group': 'break',
        'key': 'splitBenefit',
        'isPercentage': true,
    },
    {
        'label': 'Split Duration',
        'options': [1, 2, 3, 4, 5],
        'group': 'break',
        'key': 'splitDuration',
        'isPercentage': false,
    },
];

const foulAttributes = [
    {
        'label': 'Regular Play',
        'options': [0.01, 0.02, 0.03, 0.04, 0.05],
        'group': 'regular',
        'key': 'foul',
        'isPercentage': true,
    },
    {
        'label': 'Break',
        'options': [0.01, 0.02, 0.03, 0.04, 0.05],
        'group': 'break',
        'key': 'foul',
        'isPercentage': true,
    },
    {
        'label': 'Pot Black On Foul',
        'options': [0.01, 0.02, 0.03, 0.04, 0.05],
        'group': 'foul',
        'key': 'makeBlack',
        'isPercentage': true,
    },
];

const allAttributes = regularPlayAttributes.concat(breakAttributes, foulAttributes);

export const createRandomPlayer = () => {
    var playerAttributes = {};
    allAttributes.forEach(attribute => {
        var newValue = attribute['options'][Math.floor(Math.random() * attribute['options'].length)];
        playerAttributes = { ...playerAttributes, [attribute.group]: { ...playerAttributes[attribute.group], [attribute.key]: newValue } }
    });
    return playerAttributes;
};

export const generatePoolPlayers = (nPlayers) => {
    var players = Array(nPlayers).fill(null);

    players.forEach((player, i) => {
        players[i] = {...createRandomPlayer(), 'player_id': i+1};
    });

    return players;
};
