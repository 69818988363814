import * as d3 from "d3";
import { AxisHorizontal, AxisVertical, ChartCanvas } from "../components/graphs/basicGraph";
import { useChartDimensions } from "../components/graphs/useChartDimensions";
import { PageHeader } from "../components/Headers";

// https://stackoverflow.com/questions/849211/shortest-distance-between-a-point-and-a-line-segment
const pDistance = (x, y, x1, y1, x2, y2) => {

    var A = x - x1;
    var B = y - y1;
    var C = x2 - x1;
    var D = y2 - y1;

    var dot = A * C + B * D;
    var len_sq = C * C + D * D;
    var param = -1;
    if (len_sq !== 0) //in case of 0 length line
        param = dot / len_sq;

    var xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    var dx = x - xx;
    var dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
  }

// line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// Determine the intersection point of two line segments
// Return FALSE if the lines don't intersect
function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {

    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false
    }

    let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
        return false
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)

    return { x, y }
}


// y = mx + c
const findLinesBetweenTwoPoints = (x1, x2, y1, y2) => {
    let slope = (y2 - y1) / (x2 - x1);
    let c = y1 - (slope * x1);
    let line = { 'm': slope, 'c': c };
    return line;
};

// We can calculate the point at which the line will come within N of another line, as the cushion lines are going to be straight horizontal or vertical
const findBallImpactCoords = () => {
    return null;
};

// Impact distance here represents ballRadius
const findBallImpactCoordsHorizontal = (horizontalY, lineM, lineC, impactDistance) => {
    return null;
};

const findBallImpactCoordsVertical = (verticalX, lineM, lineC, impactDistance) => {
    return null;
};

// Above may not be required depending on the intersect code


// x = (y-c)/m
const getXIntersectPoint = (y, m, c) => {
    return (y - c) / m;
};


const getYIntersectPoint = (x, m, c) => {
    return (m * x) + c;
};

// c = y - mx
const calculateC = (x, y, m) => {
    return y - (m * x);
};

const parallelBallOffset = (ballRadius) => {
    return Math.sqrt(Math.pow(ballRadius, 2)/2);
}

const StraightLine = ({ x1, x2, y1, y2 }) => {
    return (
        <line
            x1={x1}
            x2={x2}
            y1={y1}
            y2={y2}
            stroke="gray"
            strokeDasharray="2"
            opacity={0.5}
            strokeWidth={2}
        ></line>
    );
};


export const InterestingLines = ({ }) => {
    const chartSettings = { height: 300, marginTop: 10, marginBottom: 30, marginLeft: 30, marginRight: 10 };

    return (
        <div>
            <PageHeader title="Intersecting Lines" />
            <IntersectingLineChart
                chartSettings={chartSettings}
            />
        </div>
    );
};

const boundedYScale = (yScale, yValue) => {
    let scaleMin = d3.min(yScale.domain());
    let scaleMax = d3.max(yScale.domain());
    return yValue > scaleMax ? yScale(scaleMax) : yValue < scaleMin ? yScale(scaleMin) : yScale(yValue);
};


const TangentLine = ({ tangentStartCoords, tangentLength, tangentM, xScale, yScale }) => {
    // If C is between the dimensions for X then it will cross the X axis, else it will hit one of the other axis
    // console.log(intersect(0, 0, 0, 280, 125, 150, 90, 90));

    let x0 = tangentStartCoords[0];
    let y0 = tangentStartCoords[1];
    let c = calculateC(x0, y0, tangentM);
    let x1 = tangentM < 0 ? 0 : 100;
    let y1 = getYIntersectPoint(x1, tangentM, c);

    if (y1 > d3.max(yScale.domain())) {
        y1 = d3.max(yScale.domain());
        x1 = getXIntersectPoint(y1, tangentM, c);
    };

    // console.log(intersect(0, 0, 0, 500, x0, y0, x1, y1));

    return (
        <>
            <StraightLine
                x2={xScale(x1)}
                y2={yScale(y1)}

                // Intersect point on main line
                x1={xScale(x0)}
                y1={yScale(y0)}
            />
            <circle
                cx={xScale(x1)}
                cy={yScale(y1)}
                r={4}
                fill="#2980b9"
                opacity={0.6}>
            </circle>
        </>
    );
};


const ParallelBallPoint = ({ ballX, ballY, tangentM, xScale, yScale, ballRadius=8 }) => {
    // Calculates the position for a point that is the ball radius away from a line in the perpendicular direction
    // TODO: flips when the gradient of the line changes from negative to positive
    let c = calculateC(ballX, ballY, tangentM);

    // Calculate the adjacent angle of the tangent line to the X axis, then apply SOHCAHTOA to calculate adjacent X distance to adjust by
    let adjacent = Math.cos(Math.atan(tangentM))*ballRadius;

    return (
        <circle
            cx={xScale(ballX-adjacent)}
            cy={yScale(tangentM === -Infinity ? ballY+ballRadius : getYIntersectPoint(ballX-adjacent, tangentM, c))}
            r={4}
            fill="green"
            opacity={0.6}>
        </circle>
    );
};


const CardinalBallContactPoints = ({ ballX, ballY, ballRadius, xScale, yScale }) => {
    return (
        <g>
            <circle
                cx={xScale(ballX)}
                cy={yScale(ballY + ballRadius)}
                r={4}
                fill="#9b59b6"
                opacity={0.6}>
            </circle>
            <circle
                cx={xScale(ballX + ballRadius)}
                cy={yScale(ballY)}
                r={4}
                fill="#9b59b6"
                opacity={0.6}>
            </circle>
            <circle
                cx={xScale(ballX)}
                cy={yScale(ballY - ballRadius)}
                r={4}
                fill="#9b59b6"
                opacity={0.6}>
            </circle>
            <circle
                cx={xScale(ballX - ballRadius)}
                cy={yScale(ballY)}
                r={4}
                fill="#9b59b6"
                opacity={0.6}>
            </circle>
        </g>
    );
}

const IntersectingLineChart = ({ chartSettings }) => {
    const [ref, dimensions] = useChartDimensions(chartSettings);

    const xDomain = [0, dimensions.boundedWidth];
    const yDomain = [0, dimensions.boundedHeight];

    const xScale = d3.scaleLinear().domain(xDomain).range([0, dimensions.boundedWidth]);
    const yScale = d3.scaleLinear().domain(yDomain).range([dimensions.boundedHeight, 0]);

    let x1 = 50;
    let y1 = 50;

    let x2 = 250;
    let y2 = 150;

    let y0 = 0;
    let line = {};

    let xIntercept = (x2+x1)/2;

    let ballRadius = 15;

    if (x1 && x2 && y1 && y2){
        line = findLinesBetweenTwoPoints(x1, x2, y1, y2);
        y0 = getYIntersectPoint(xIntercept, line['m'], line['c']);
    };

    // Calculate a radius width in the direction of the perpendicular line
    let cx0 = x1;
    let cy0 = y1;
    let c = calculateC(cx0, cy0, -1/line['m']);

    // https://math.stackexchange.com/questions/2572564/coordinate-geometry-move-a-line-x-units-along-its-normal
    // y = mx + c + ballRadius*Math.sqrt(1+Math.pow(m, 2))

    cy0 = line['m']*cx0 + c + ballRadius*Math.sqrt(1+Math.pow(line['m'], 2));
    cx0 = getXIntersectPoint(cy0, -1/line['m'], c);

    // Do I need to calculate the angle of the line?
    // m = tan(theta)
    // degrees = radians*180/pi
    // https://www.quora.com/How-can-you-find-the-angle-of-line-by-using-its-slope
    // console.log(Math.atan(1/line['m'])*180/Math.PI)

    // Now we have the angle that the tangent line will meet the xaxis
    // We can work out the relevant shift in the point now to move calculate a hypotenuse of length ballRadius

    // https://mathworld.wolfram.com/SOHCAHTOA.html#:~:text=%22SOHCAHTOA%22%20is%20a%20helpful%20mnemonic,(1)
    // cos(angle)*hypotenuse = adjacent (shift in x) => we can then calculate the y intercept using the tangent line gradient and c
    // let adjacent = Math.cos(Math.atan(-1/line['m']))*ballRadius;
    // console.log(xIntercept-adjacent);
    // console.log(getYIntersectPoint((xIntercept-adjacent), -1/line['m'], calculateC(xIntercept, y0, -1/line['m'])));

    return (
        <div ref={ref}>
            <ChartCanvas dimensions={dimensions}>
                <rect x={0} y={0} stroke="black" height={dimensions.boundedHeight} width={dimensions.boundedWidth} fill="none"></rect>
                <AxisHorizontal
                    range={xDomain}
                    domain={[0, dimensions.boundedWidth]}
                    dimensions={dimensions}
                />
                <AxisVertical
                    range={[0, dimensions.boundedHeight]}
                    domain={yDomain}
                    dimensions={dimensions}
                />
                <circle
                    cx={xScale(x1)}
                    cy={yScale(y1)}
                    r={ballRadius}
                    fill="none"
                    stroke="#2c3e50"
                    strokeWidth={2}>
                </circle>
                <circle
                    cx={xScale(x1)}
                    cy={yScale(y1)}
                    r={1}
                    fill="#2c3e50">
                </circle>

                <circle
                    cx={xScale(x2)}
                    cy={yScale(y2)}
                    r={ballRadius}
                    fill="none"
                    stroke="#2c3e50"
                    strokeWidth={2}>
                </circle>
                <circle
                    cx={xScale(x2)}
                    cy={yScale(y2)}
                    r={1}
                    fill="#2c3e50">
                </circle>
                <StraightLine x1={xScale(x1)} x2={xScale(x2)} y1={yScale(y1)} y2={yScale(y2)} />
                {/* This isn't exactly right, doesn't work when the gradient changes, needs to adjust parallel to the line */}
                <StraightLine x1={xScale(x1+parallelBallOffset(ballRadius))} x2={xScale(x2+parallelBallOffset(ballRadius))} y1={yScale(y1-parallelBallOffset(ballRadius))} y2={yScale(y2-parallelBallOffset(ballRadius))} />
                <StraightLine x1={xScale(x1-parallelBallOffset(ballRadius))} x2={xScale(x2-parallelBallOffset(ballRadius))} y1={yScale(y1+parallelBallOffset(ballRadius))} y2={yScale(y2+parallelBallOffset(ballRadius))} />
                <TangentLine
                    tangentStartCoords={[xIntercept, y0]}
                    tangentLength={100}
                    tangentM={-1/line['m']}
                    xScale={xScale}
                    yScale={yScale}
                />

                <circle
                    cx={xScale(xIntercept)}
                    cy={yScale(y0)}
                    r={4}
                    fill="#e74c3c"
                    opacity={0.6}>
                </circle>

                {/* Perpendicular dot that is ballRadius away from the line */}
                <ParallelBallPoint
                    ballX={x1}
                    ballY={y1}
                    tangentM={-1/line['m']}
                    xScale={xScale}
                    yScale={yScale}
                    ballRadius={ballRadius}
                />
                <CardinalBallContactPoints
                    ballX={x1}
                    ballY={y1}
                    ballRadius={ballRadius}
                    xScale={xScale}
                    yScale={yScale}
                />

                <ParallelBallPoint
                    ballX={x2}
                    ballY={y2}
                    tangentM={-1/line['m']}
                    xScale={xScale}
                    yScale={yScale}
                    ballRadius={ballRadius}
                />
                <CardinalBallContactPoints
                    ballX={x2}
                    ballY={y2}
                    ballRadius={ballRadius}
                    xScale={xScale}
                    yScale={yScale}
                />


                {/* <StraightLine
                    x2={xScale(getXIntersectPoint(265, -1/line['m'], calculateC(xIntercept, y0, -1/line['m'])))}
                    y2={boundedYScale(yScale, getYIntersectPoint(50, -1/line['m'], calculateC(xIntercept, y0, -1/line['m'])))}

                    // Intersect point on main line
                    x1={xScale(xIntercept)}
                    y1={yScale(y0)}
                /> */}

            </ChartCanvas>
        </div>
    );
};
