import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { ArticleParagraph } from "../../../components/Paragraphs";
import { useEffect, useState, useRef } from "react";
import { Loading } from "../../../components/Loading";
import * as d3 from 'd3';
import { GolfModelsHomeButton } from "../../../components/HomeLinks";


// Function to simulate a single hole score based on average and distribution
function simulateHoleScore(average, distribution) {
    // The distribution parameter represents the spread or variability of scores around the average. It is typically specified as a percentage of the average. By dividing the distribution value by 100, we convert it to a decimal value expected by the formula to calculate the standard deviation.
    // The standard deviation is a measure of how spread out the scores are from the average. It is commonly used to determine the shape and width of a probability distribution. In this case, we use it to generate random hole scores that follow a normal distribution around the average score.
    // To summarize, dividing the distribution value by 100 is done to convert it from a percentage to a decimal so that it aligns with the expected form for calculating the standard deviation.

    // This assumes that the distribution metric is a percentage of the variation
    const standardDeviation = average * (distribution / 100);

    // Generate a random hole score using the normal distribution
    const holeScore = normalDistribution(average, standardDeviation);

    // Round the hole score to the nearest integer
    return Math.round(holeScore);
}

// Function to generate a random number from a normal distribution
function normalDistribution(mean, standardDeviation) {
    let u = 0,
        v = 0;
    while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    const z = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    return mean + z * standardDeviation;
}

// Function to simulate a round of golf
function simulateGolfRound(average, distribution) {
    const scores = [];

    // Simulate 18 holes - this actually simulates a round
    for (let hole = 1; hole <= 500; hole++) {
        const holeScore = simulateHoleScore(average, distribution);
        scores.push(holeScore);
    }

    return scores;
}

function groupAndCountScores(scores, includeAllScores = false) {
    // Create an object to store the count for each score
    const scoreCounts = {};

    // Find the minimum and maximum scores
    const minScore = Math.min(...scores);
    const maxScore = Math.max(...scores);

    // Count the occurrences of each score
    scores.forEach((score) => {
        if (scoreCounts[score]) {
            scoreCounts[score]++;
        } else {
            scoreCounts[score] = 1;
        }
    });

    // Calculate the total number of scores
    const totalCount = scores.length;

    // Create an array of objects with score and count information
    const scoreInfo = [];

    if (includeAllScores) {
        // Include all possible scores between min and max observed
        for (let score = minScore; score <= maxScore; score++) {
            const count = scoreCounts[score] || 0;
            const percentage = (count / totalCount) * 100;
            scoreInfo.push({ score, count, percentage });
        }
    } else {
        // Use the scores present in the round only
        scoreInfo.push(
            ...Object.entries(scoreCounts).map(([score, count]) => ({
                score: parseInt(score),
                count,
                percentage: (count / totalCount) * 100,
            }))
        );
    }

    // Sort the score info array by score (lowest to highest)
    scoreInfo.sort((a, b) => a.score - b.score);

    return scoreInfo;
}


const ScoreDistributionTable = ({ scoreInfo, colourScale }) => {
    return (
        <div className="flex items-center justify-center flex-col text-sm sm:text-base text-center sm:text-left mt-4">
            <H2Heading>Score Distribution</H2Heading>
            <table className="w-full sm:w-4/5 text-sm sm:text-base border border-gray-200">
                <thead className="bg-gray-200">
                    <tr className="text-center tracking-tight">
                        <th className="px-1 py-2 sm:px-2">Score</th>
                        <th className="px-1 py-2 sm:px-2">Count</th>
                        <th className="px-1 py-2 sm:px-2">Percentage</th>
                    </tr>
                </thead>
                <tbody className="text-center">
                    {scoreInfo.map(({ score, count, percentage }) => (
                        <tr key={score}>
                            <td className="p-1">{score}</td>
                            <td className="p-1">{count}</td>
                            <td
                                className="p-1"
                                style={{ backgroundColor: colourScale.current(percentage) }}
                            >{percentage.toFixed(2)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


const MySliderComponent = ({ averageScore, updateAverageScore, distributionScore, updateDistributionScore }) => {
    const handleAverageScoreChange = event => {
        const value = parseFloat(event.target.value);
        updateAverageScore(isNaN(value) ? 0 : value);
    };

    const handleDistributionScoreChange = event => {
        const value = parseFloat(event.target.value);
        updateDistributionScore(isNaN(value) ? 0 : value);
    };

    return (
        <div className="flex mb-4 flex-col sm:flex-row text-sm sm:text-base">
            <div className="w-full sm:w-1/2 sm:px-5">
                <label htmlFor="averageScore" className="block mb-2 font-bold">
                    Average Round Score
                </label>
                <input
                    type="range"
                    id="averageScore"
                    className="w-full"
                    min="67"
                    max="77"
                    step="0.5"
                    value={averageScore}
                    onChange={handleAverageScoreChange}
                />
                <div className="text-center">{averageScore}</div>
            </div>

            <div className="w-full sm:w-1/2 sm:px-5">
                <label htmlFor="distributionScore" className="block mb-2 font-bold">
                    Distribution Score
                </label>
                <input
                    type="range"
                    id="distributionScore"
                    className="w-full"
                    min="0"
                    max="10"
                    step="1"
                    value={distributionScore}
                    onChange={handleDistributionScoreChange}
                />
                <div className="text-center">{distributionScore}</div>
            </div>
        </div>
    );
};


export const GolfRound = () => {
    const [averageScore, updateAverageScore] = useState(72); // Golfer's average score
    const [distributionScore, updateDistributionScore] = useState(5); // Distribution metric (e.g., 5 for a tight distribution, 15 for a wider distribution)
    const [roundScores, updateRoundScores] = useState(null);
    const [groupScores, updateGroupScores] = useState(null);
    const colourScale = useRef(null);

    // Fill on first mount
    useEffect(() => {
        // Simulate a round of golf for the golfer
        const roundScores = simulateGolfRound(averageScore, distributionScore);
        const roundInfo = groupAndCountScores(roundScores);
        const percentages = roundInfo.map(x => x.percentage);

        updateRoundScores(roundScores);
        updateGroupScores(roundInfo);

        // Generate color scale
        colourScale.current = d3.scaleSequential().domain([d3.min(percentages), d3.max(percentages)]).interpolator(d3.interpolateRgb('white', '#62A87C'));
        // eslint-disable-next-line
    }, [averageScore, distributionScore]);

    if (roundScores === null) {
        return <Loading></Loading>
    }

    return (
        <PageBody>
            <Helmet>
                <title>Predicting Golf Results | VizBadger</title>
                <meta
                    name="description"
                    content="Predict the outcome of a golf event using monte carlo simulations and modeling a round of golf programmatically. Apply statistics and knowledge of players to calculate the likelihood of things happening and results."
                ></meta>
                <meta name="keywords" content="Sports betting, Betting accumulators, Betting exchanges, Betting value, Accumulators, Betting guide, Betting UK, Football betting, Betting accumulator strategy, Betting value strategy, Betting odds value, Betting value bets, Value betting strategy"></meta>
            </Helmet>

            <GolfModelsHomeButton />

            <ArticleTitle
                title="Predicting Golf Results"
            />

            <ArticleParagraph
                lines={[
                    "At the very simplest level, you have a golfer that has an average score. Beyond that, they will vary in their scores; some will be more consistent than others, some will have a lower best score than their competitors.",
                    "Using Monte Carlo simulation, you can input some very simple parameters for each golfer, and see their distribution of scores. Take the example below of a golfer that has an average score of 72 (par), and a distribution of shots of 5.",
                    "This simulation naively assumes that the distribution is normal but, as I said, this is the simplest terms you can view a round of golf for simulation. With more detailed golf statistics, and accompanied with course and weather details, you could better estimate a golfer's chance. Whether this would be good enough to find a betting edge is hard to tell but it may provide insight into the player themselves.",
                ]}
            />

            <H2Heading>Player Statistics</H2Heading>
            <ArticleParagraph
                lines={[
                    "Adjust the player statistics below to simulate a new set of rounds and see the distribution of scores.",
                ]}
            />

            <MySliderComponent
                averageScore={averageScore}
                updateAverageScore={updateAverageScore}
                distributionScore={distributionScore}
                updateDistributionScore={updateDistributionScore}
            />

            <ScoreDistributionTable scoreInfo={groupScores} colourScale={colourScale} />
        </PageBody>
    );
};
