import { H2Heading } from "../../../../components/Headers";
import { displayPercentage } from "../../../../helpers";

export const BatchAccuracySummary = ({ playerAttributes, batchSimulations }) => {
    return (
        <div className="text-center mt-4">
            <H2Heading>Batch Accuracy Summary</H2Heading>
            <p className="text-sm sm:text-base text-center sm:text-left">Compare the observed accuracies for each player, and then what the simulations expect the overall accuracy to be across all simulations. They expected accuracies should be close to the observed ones with an increased number of trials 'smoothing' out the variation that is seen within each game.</p>
            <div className="flex flex-wrap items-center justify-center mb-8">
                <div
                    className="flex flex-col text-center my-2"
                >
                    <div className="flex justify-center items-center h-8 w-8 text-sm italic text-gray-500 text-center border-r"></div>
                    <div className="flex justify-center items-center h-8 w-8 pr-2 italic border-r">P1</div>
                    <div className="flex justify-center items-center h-8 w-8 pr-2 italic border-r pt-2">P2</div>
                </div>
                <div
                    className="flex flex-col text-center my-2 px-2 border-r"
                >
                    <div className="h-8 flex justify-center items-center text-sm italic text-gray-500">Shots Made</div>
                    <div className="h-8 flex justify-center items-center">{batchSimulations.playerShots.player1Made.toLocaleString()}</div>
                    <div className="h-8 flex justify-center items-center pt-2">{batchSimulations.playerShots.player2Made.toLocaleString()}</div>
                </div>
                <div
                    className="flex flex-col text-center my-2 px-2 border-r"
                >
                    <div className="h-8 flex justify-center items-center text-sm italic text-gray-500">Shots Taken</div>
                    <div className="h-8 flex justify-center items-center">{batchSimulations.playerShots.player1Total.toLocaleString()}</div>
                    <div className="h-8 flex justify-center items-center pt-2">{batchSimulations.playerShots.player2Total.toLocaleString()}</div>
                </div>
                <hr className="h-0 w-full border-none sm:hidden"></hr>
                <div
                    className="flex flex-col text-center my-2 sm:hidden"
                >
                    <div className="flex justify-center items-center h-8 w-8 text-sm italic text-gray-500 text-center border-r"></div>
                    <div className="flex justify-center items-center h-8 w-8 pr-2 italic border-r">P1</div>
                    <div className="flex justify-center items-center h-8 w-8 pr-2 italic border-r pt-2">P2</div>
                </div>
                <div
                    className="flex flex-col text-center my-2 px-2 border-r"
                >
                    <div className="h-8 flex justify-center items-center text-sm italic text-gray-500">Expected %</div>
                    <div className="h-8 flex justify-center items-center">{displayPercentage(batchSimulations.player1BatchAccuracy)}</div>
                    <div className="h-8 flex justify-center items-center pt-2">{displayPercentage(batchSimulations.player2BatchAccuracy)}</div>
                </div>
                <div
                    className="flex flex-col text-center my-2 px-2 border-r"
                >
                    <div className="h-8 flex justify-center items-center text-sm italic text-gray-500">Observed %</div>
                    <div className="h-8 flex justify-center items-center">{displayPercentage(playerAttributes['player1']['regular']['make'])}</div>
                    <div className="h-8 flex justify-center items-center pt-2">{displayPercentage(playerAttributes['player2']['regular']['make'])}</div>
                </div>
            </div>
        </div>
    );
};
