import axios from 'axios';

export async function getPoolMatches() {
    return await axios.get("/api/pool/matches");
};

// Player-level API calls
export async function getPoolPlayer(playerID) {
    return await axios.get("/api/pool/player/" + playerID);
};

export async function getPoolPlayerMatches(playerID) {
    return await axios.get("/api/pool/player/" + playerID + "/matches");
};

export async function getPlayerGroups(playerID) {
    return await axios.get("/api/pool/player/" + playerID + "/groups");
};

export async function getPlayerOpponentSummary(playerID) {
    return await axios.get("/api/pool/player/" + playerID + "/opponent_summary");
};

export async function getPlayerRecentForm(playerID) {
    return await axios.get("/api/pool/player/" + playerID + "/recent_form");
};

// Group-level API calls
export async function getGroup(groupUrlSlug) {
    return await axios.get("/api/pool/group/" + groupUrlSlug);
};

export async function getGroupPlayers(groupUrlSlug) {
    return await axios.get("/api/pool/group/" + groupUrlSlug + "/players");
};

export async function getGroupMatches(groupUrlSlug) {
    return await axios.get("/api/pool/group/" + groupUrlSlug + "/matches");
};
