export const basePlayerAttributes = {
	'player1': {
		'regular': {
			'make': 0.50,
			'foul': 0.04,
		},
		'break': {
			'make': 0.50,
			'splitBenefit': 0.05,
			'splitDuration': 1,
			'foul': 0.02,
		},
		'foul': {
			'makeBlack': 0.01
		}
	},
	'player2': {
		'regular': {
			'make': 0.40,
			'foul': 0.05
		},
		'break': {
			'make': 0.80,
			'splitBenefit': 0.60,
			'splitDuration': 3,
			'foul': 0.02,
		},
		'foul': {
			'makeBlack': 0.01
		}
	},
};
