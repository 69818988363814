// A single instance of a shot
class Shot {

}

// Base pool simulation that has players, scores, a winner etc
class PoolSimulation {
    playerA;
    playerB;

    // Player activity during the match
    playerAShots = [];
    playerBShots = [];
    scoreA;
    scoreB;
    winner;
    finalScore;
    breakingPlayer;

    accuracyMetrics;

    constructor(playerA, playerB) {
        this.playerA = playerA;
        this.playerB = playerB;
    };

    updateScore = () => {
        return null;
    };

    // Calculating the player accuracies shouldn't change
    playerAShotAccuracy = () => {
        return this.playerAShots;
    };

    playerBShotAccuracy = () => {
        return this.playerBShots;
    };

    takeShot = () => {
        //console.log('shot taken');
        return null;
    };

    // Define consistent way of aggregating metrics post-match
    aggregateSimulationMetrics = () => {
        this.accuracyMetrics = {
            'matchAccuracy': {
                'PlayerA': this.playerAShotAccuracy(),
                'PlayerA': this.playerBShotAccuracy()
            },
        };
    };
};


// Simulate functions here will be defined as different versions of the logic with additional detail
export class PoolSimulationAccuracy extends PoolSimulation {
    // Take in player instances that should have skill metrics
    constructor(playerA, playerB) {
        super(playerA, playerB);
    };

    // Run the simulation
    simulate = () => {
        return null;
    };
};


// Simply extend the most recent iteration of the pool simulation
export class PoolSimulationLatest extends PoolSimulationAccuracy {
    constructor(playerA, playerB) {
        super(playerA, playerB);
    };
};
