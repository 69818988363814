import { displayPercentage } from "../../../../helpers";

export const ShotOverUnder = ({ dataArray, medianValue }) => {
    return (
        <div className="flex flex-wrap items-center justify-center mb-8">
            <div
                className="flex flex-col text-center my-2"
            >
                <div className="flex justify-center items-center h-8 w-16 text-sm italic text-gray-500 text-center border-r"></div>
                <div className="flex justify-center items-center h-8 w-16 pr-2 italic border-r">Games</div>
                <div className="flex justify-center items-center h-4 w-16 pr-2 italic border-r italic text-xs text-gray-500">%</div>
            </div>
            <div
                className="flex flex-col text-center my-2 px-2 border-r"
            >
                <div className="h-8 w-12 flex justify-center items-center text-sm italic text-gray-500">0-{medianValue-1}</div>
                <div className="h-8 w-12 flex justify-center items-center">{dataArray.filter((n) => n < medianValue).length}</div>
                <div className="h-4 w-12 flex justify-center items-center italic text-xs text-gray-500">{displayPercentage(dataArray.filter((n) => n < medianValue).length/dataArray.length)}</div>
            </div>
            <div
                className="flex flex-col text-center my-2 px-2 border-r"
            >
                <div className="h-8 w-12 flex justify-center items-center text-sm italic text-badger-blue">{medianValue}</div>
                <div className="h-8 w-12 flex justify-center items-center">{dataArray.filter((n) => n === medianValue).length}</div>
                <div className="h-4 w-12 flex justify-center items-center italic text-xs text-gray-500">{displayPercentage(dataArray.filter((n) => n === medianValue).length/dataArray.length)}</div>
            </div>
            <div
                className="flex flex-col text-center my-2 px-2 border-r"
            >
                <div className="h-8 w-12 flex justify-center items-center text-sm italic text-gray-500">{medianValue+1}+</div>
                <div className="h-8 w-12 flex justify-center items-center">{dataArray.filter((n) => n > medianValue).length}</div>
                <div className="h-4 w-12 flex justify-center items-center italic text-xs text-gray-500">{displayPercentage(dataArray.filter((n) => n > medianValue).length/dataArray.length)}</div>
            </div>
        </div>
    );
};
