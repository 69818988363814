export const updateScore = (currentScore, outcome) => {
    if (!outcome) {
        return currentScore
    };

    if (currentScore === 7) {
        return 10;
    };
    return currentScore += 1;
};


export const takeShot = (accuracyPercentage) => {
    if (Math.random() < accuracyPercentage) {
        return true;
    };

    return false;
};
