import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { ArticleTitle, H2Heading } from "../../components/Headers";
import { ArticleParagraph } from "../../components/Paragraphs";
import { BettingHomeButton } from "../../components/HomeLinks";
import { PageBody } from "../../components/PageBody";

const OddsConvertor = () => {
	const [decimalOdds, updateDecimalOdds] = useState(1.7);
	const [decimalOdds2, updateDecimalOdds2] = useState(1.9);
	const [percentageOdds, updatePercentageOdds] = useState(100.0 * 1 / decimalOdds);
	const [percentageOdds2, updatePercentageOdds2] = useState(100.0 * 1 / decimalOdds2);

	const handleConversion = (value, isDecimalInput) => {
		if (isDecimalInput) {
			updateDecimalOdds(parseFloat(value));
			updateDecimalOdds2(parseFloat(value) + 0.2);
			updatePercentageOdds(100.0 * 1 / parseFloat(value));
			updatePercentageOdds2(100.0 * 1 / (parseFloat(value) + 0.2));
		} else {
			updatePercentageOdds(parseFloat(value));
			updateDecimalOdds(1 / (parseFloat(value) / 100.0));
			updateDecimalOdds2((1 / (parseFloat(value) / 100.0)) + 0.2);
			updatePercentageOdds2(100.0 * 1 / ((1 / (parseFloat(value) / 100.0)) + 0.2));
		};
	};

	const handleConversion2 = (value) => {
		updateDecimalOdds(parseFloat(value) - 0.2);
		updateDecimalOdds2(parseFloat(value));
		updatePercentageOdds(100.0 * 1 / (parseFloat(value) - 0.2));
		updatePercentageOdds2(100.0 * 1 / parseFloat(value));
	};

	return (
		<div className="flex flex-col sm:flex-row text-sm sm:text-base sm:my-4">
			<div className="flex flex-col mt-4 sm:w-1/2 sm:mx-8 border rounded">
				<p className="text-sm sm:text-base text-center py-2 bg-gray-200 font-semibold tracking-tight">1st Provider</p>
				<div className="my-3 px-3">
					<p className="text-sm text-gray-800">Decimal Odds</p>
					<input
						value={decimalOdds.toLocaleString()}
						onChange={e => handleConversion(e.target.value, true)}
						placeholder="Insert Decimal Odds..."
						className="input border border-gray-400 rounded w-full px-3 py-2 focus:border-badger-red focus:outline-none active:outline-none active:border-badger-red"
						type="number"
						step="0.1"
						min="1"
					/>
				</div>
				<div className="px-3 mb-4">
					<p className="text-sm text-gray-800">Percentage Odds</p>
					<input
						value={percentageOdds.toLocaleString()}
						onChange={e => handleConversion(e.target.value, false)}
						placeholder="Insert Percentage Odds..."
						className="input border border-gray-400 rounded w-full px-3 py-2 focus:border-red-red focus:outline-none active:outline-none active:border-badger-red"
						type="number"
						step="1"
						min="1"
						max="100"
					/>
				</div>
			</div>
			<div className="flex flex-col mt-4 sm:w-1/2 sm:mx-8 border rounded">
				<p className="text-sm sm:text-base text-center py-2 bg-gray-200 font-semibold tracking-tight">2nd Provider</p>
				<div className="my-3 px-3">
					<p className="text-sm text-gray-800">Decimal Odds (<span className="text-badger-green">+0.2</span>)</p>
					<input
						value={decimalOdds2.toLocaleString()}
						onChange={e => handleConversion2(e.target.value, true)}
						placeholder="Insert Decimal Odds..."
						className="input border border-gray-400 rounded w-full px-3 py-2 focus:border-badger-red focus:outline-none active:outline-none active:border-badger-red"
						type="number"
						step="0.1"
						min="1"
					/>
				</div>
				<div className="px-3 mb-4">
					<p className="text-sm text-gray-800">Percentage Odds (<span className="text-badger-green">{(percentageOdds - percentageOdds2).toLocaleString()}%</span>)</p>
					<input
						value={percentageOdds2.toLocaleString()}
						onChange={e => handleConversion2(e.target.value, false)}
						placeholder="Insert Percentage Odds..."
						className="input border border-gray-400 rounded w-full px-3 py-2 focus:border-red-red focus:outline-none active:outline-none active:border-badger-red"
						type="number"
						step="1"
						min="1"
						max="100"
						disabled={true}
					/>
				</div>
			</div>
		</div>
	);
};


export const HowToMaximiseBettingAccumulators = () => {
	const selectionOdds = [1.5, 2.5, 4, 6];
	const selectionOdds2 = [1.7, 2.3, 4.1, 5.7];
	const stake = 2;

	const renderHTML = (rawHTML) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });

	return (
		<PageBody>
			<Helmet>
				<title>How To Maximise Sports Betting Accumulators | VizBadger</title>
				<meta
					name="description"
					content="What is the best way to maximise profit from sports betting accumulators? An article explaining how timing and choosing the right bookmaker makes the difference. Understand sports betting prices and what gives you the best chance of winning a good return on your betting accumulators and multiples."
				></meta>
				<meta name="keywords" content="Sports betting, Best bookmakers, Betting accumulators, Betting exchanges, Betting value, Accumulators, Betting guide, Betting UK, Football betting, Betting accumulator strategy, Betting value strategy, Betting odds value, Betting value bets, Value betting strategy, implied probability, sports betting probability, betting odds convertor"></meta>
				<link rel="canonical" href="https://www.vizbadger.com/betting/how-to-maximise-sports-betting-accumulators" />
			</Helmet>

			<BettingHomeButton />

			<ArticleTitle title="How To Maximise Sports Betting Accumulators" />

			<ArticleParagraph
				lines={[
					"You've picked your selections for this week's football matches, and are ready to place a bet, but how do you make sure you're getting the best return? Prices vary between bookmakers and over time as well, and being savvy about your bookmaker choice can help increase your potential payout.",
				]}
			/>

			<H2Heading>A brief refresher on betting accumulators</H2Heading>
			<ArticleParagraph
				lines={[
					"An accumulator bet is one where you have multiple selections in the same bet. All of which have to win to return. These selections can be across multiples events or sports and include anywhere from 2 to ~10 selections. If all the selections are within the same event then the industry tends to call this a bet builder. Multiples and bet builders are effectively the same thing.",
					"The 'benefit', or appeal, to the bettor is that the odds multiply with more selections, taking the winnings from the first bet and placing it all on the second selection. It is liked by punters worldwide as it often leads to large overall odds and huge returns from smaller stakes.",
				]}
			/>

			<div className="text-sm sm:text-base text-center sm:text-left">
				<div className="my-6 flex flex-col items-center">
					<table className="w-full sm:w-4/5 text-sm sm:text-base border border-gray-200">
						<thead className="bg-gray-200">
							<tr className="text-center tracking-tight">
								<th className="px-1 py-2 sm:px-2">Outcome</th>
								<th className="px-1 py-2 sm:px-2">Odds (Decimal)</th>
								<th className="px-1 py-2 sm:px-2">Cumulative Odds</th>
								<th className="px-1 py-2 sm:px-2">Stake 'Progression' (£{stake})</th>
							</tr>
						</thead>
						<tbody className="text-center">
							{selectionOdds.map((selection, i) =>
								<tr key={selection}>
									<td className="p-1">{i + 1}</td>
									<td className="p-1">{selection}</td>
									<td className="p-1">{(selectionOdds.slice(0, i + 1).reduce((a, b) => a * b))}</td>
									<td className="p-1"><span className="text-sm sm:text-sm italic text-gray-500">£{i === 0 ? Number(stake).toFixed(2) : Number(stake * selectionOdds.slice(0, i).reduce((a, b) => a * b)).toFixed(2)} x {selection} =</span> £{Number(stake * selectionOdds.slice(0, i + 1).reduce((a, b) => a * b)).toFixed(2)}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<p className="mb-2">You can see how winning all 4 outcomes grants you final odds of {selectionOdds.reduce((a, b) => a * b)}. The winnings from the first outcome get staked on the second, and so on and so forth. Through this cascade of bets you can see that the multiple allows you to bet more <span>stake</span> on the final outcomes; You are able to stake much higher amounts on a single event by making the stake amount conditional on the success of other bets at the same time.</p>
				<ArticleParagraph
					lines={[
						"Placing accumulators enables you to stake more than your starting stake as the money 'flows' through the selections. The risk-reward of accumulators is that you can win much larger proportionally than you would if you bet on the selections individually, but you have to win 100% of your selections to get anything at all. Multiples are better for the bookmaker because as your stake multiplies, so does the advantage within the bookmaker price. Even if a multiple pays out £1000s it doesn't mean that it is a 'value' or good/fair bet.",
					]}
				/>

				<H2Heading classes="mt-8">Choosing the right bookmaker</H2Heading>
				<ArticleParagraph
					lines={[
						"Odds and prices change over time, especially on betting exchanges (where better prices are often found), and there are many companies that are willing to take your bet. Maximising an accumulator is no different conceptually to waiting for a sale or looking for a better price elsewhere when shopping.",
						"Timing the placement of the bet is considerably more difficult than finding a better price with another provider (bookmaker or betting exchange). While some may have the best price on one outcome, it is the overall price that we are interested in. You can use odds comparison sites to check your selections for the overall best price.",
					]}
				/>
				<div className="my-4 flex flex-col items-center">
					<table className="w-full sm:w-4/5 text-sm sm:text-base border rounded border-gray-200">
						<thead className="bg-gray-200 rounded">
							<tr className="text-center tracking-tight">
								<th className="p-1 sm:p-2">Selection</th>
								<th className="p-1 sm:p-2">1st Provider</th>
								<th className="p-1 sm:p-2">Cumulative Odds</th>
								<th className="p-1 sm:p-2">2nd Provider</th>
								<th className="p-1 sm:p-2">Cumulative Odds</th>
							</tr>
						</thead>
						<tbody className="text-center">
							{selectionOdds.map((selection, i) =>
								<tr key={selection}>
									<td className="p-1 sm:p-2">{i + 1}</td>
									<td className="p-1 sm:p-2">{selection}</td>
									<td className="p-1 sm:p-2">{selectionOdds.slice(0, i + 1).reduce((a, b) => a * b)}</td>
									<td className="p-1 sm:p-2"><span>{selectionOdds2[i]}</span>
										<span className={"ml-1 " + (selectionOdds2[i] > selectionOdds[i] ? 'text-badger-green' : 'text-badger-red')}>{selectionOdds2[i] > selectionOdds[i] ? renderHTML('&uarr;') : renderHTML('&darr;')}{Number(selectionOdds2[i] - selectionOdds[i]).toFixed(1)}</span>
									</td>
									<td className="p-1 sm:p-2">{Number(selectionOdds2.slice(0, i + 1).reduce((a, b) => a * b).toFixed(2))}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<H2Heading classes="mt-8">Not all odds differences are equal</H2Heading>
				<ArticleParagraph
					lines={[
						"This might seem like an obvious statement, of course the differences aren't equal, but mathematically it matters which selections are different in determining which bookmaker is better for your multiple. In the above example, a seemingly balanced differences across selections, the final price is significantly different. The difference in the overall prices is due to the prices at which the differences appear.",
						"A movement of 0.2 on shorter odds has a greater proportional impact to the percentage chance of the outcome happening. Let me show you what I mean with a simple calculator.",
					]}
				/>

				<div className="my-8">
					<H2Heading>Prioritise bookmakers with better prices on short odd selections</H2Heading>
					<ArticleParagraph
						lines={[
							"Odds are set based on how likely something is to happen. Higher odds implies something is less likely to happen and therefore should pay out more if it does happen. Bookmakers make money by adding margin into their prices; pricing something as more likely to happen than it 'truly' is. ",
							"By offering odds at a higher implied likelihood they offer a shorter price which offers a smaller payout across all outcomes. Ultimately this increase the bookmaker's expected value from functioning.",
							"When comparing two bookmakers on a single price, the shorter the price is, the bigger the difference 0.2 swings have on the implied probability. You can see this using the interactive calculator below. Note that the higher the decimal odds, the smaller the difference 0.2 makes to the inferred probability. This is why you'll see that around heavy favourites bookmakers may vary by just 0.1 across all providers, but the outsider will vary by 2 or 3 e.g. 1.7 -> 1.5 implies an event is +7.8% but 18.0 -> 14.0 is just a 1.5% gain in the implied probability.",
							"If a provider offers better odds on the shorter prices (~1.5) of your accumulator then they are likely to end up with a better overall price even if they are slightly worse on longer odds e.g. 14 vs 16.",
						]}
					/>
					<OddsConvertor />

					<H2Heading classes="mt-8">Bookmaker margin and overround</H2Heading>
					<ArticleParagraph
						lines={[
							"By moving these values, you see that is 50% likely to happen should be priced at 2, or 'double your money'. Something that is actually 50/50 will be priced lower, as though the event is more likely to happen i.e. 1.8 which equals 55.5%. You can also see that a 0.2 difference between 1.5 and 1.7 is a much bigger difference than 4 and 4.2.",
							"This concept produces a margin across all outcomes of a result. You win 50% of the time, but when you do you get a price that isn't reflective of the risk you offered; a £1 should return £1 to be a 'true' price, you receive 80p. Selections that are priced at 50% actually need to be won at a higher rate to account for the margin that providers add.",
							"Offering both sides of a 50/50 event like this at 1.8 produces an 'overround' of 111%. An event cannot be 111% likely to happen! It is in this additional percentage that favours the bookmaker.",
						]}
					/>
				</div>
			</div>
		</PageBody>
	);
};
