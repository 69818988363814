import { PageBody } from "../../components/PageBody";

export function NoMatch() {
	return (
		<PageBody>
			<div className="flex flex-col items-center justify-center">
				<p className="text-xl mb-2">Lost?</p>
				<p className="text-lg mb-2">There's nothing to see here...</p>
			</div>
		</PageBody>
	);
};
