import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueries } from "react-query";
import { useParams } from "react-router-dom";
import { getPlayerOpponentSummary, getPlayerRecentForm, getPoolPlayer, getPoolPlayerMatches, getPlayerGroups } from "../../api/pool";
import { GridDiv, GridDivContainer } from "../../components/GridDiv";
import { H2Heading, PageHeader } from "../../components/Headers";
import { Loading } from "../../components/Loading";
import { PageBody } from "../../components/PageBody";
import { GroupRankingTable } from "../../components/pool/GroupRankingTable";
import { ArrowLongRightIcon } from "@heroicons/react/24/solid";

export const PoolPlayer = () => {
    let { playerID } = useParams();
    const [playerMatches, setPlayerMatches] = useState([]);
    const [player, setPlayer] = useState(null);
    const [groups, setGroups] = useState(null);
    const [opponentSummary, setOpponentSummary] = useState(null);
    const [recentForm, setRecentForm] = useState(null);

    const results = useQueries([
        { queryKey: ["poolPlayerMatches", playerID], queryFn: () => getPoolPlayerMatches(playerID) },
        { queryKey: ["poolPlayer", playerID], queryFn: () => getPoolPlayer(playerID) },
        { queryKey: ["poolPlayerGroups", playerID], queryFn: () => getPlayerGroups(playerID) },
        { queryKey: ["poolPlayerOpponentSummary", playerID], queryFn: () => getPlayerOpponentSummary(playerID) },
        { queryKey: ["poolPlayerRecentForm", playerID], queryFn: () => getPlayerRecentForm(playerID) },
    ]);

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });

    useEffect(() => {
        if (!isError && !isLoading) {
            let matches = results[0]['data']['data'].map(match => {
                return { ...match, game_datetime: new Date(match.game_datetime.replace(/-/g, "/")) }
            }).sort((a, b) => b.new_id - a.new_id);
            setPlayerMatches(matches);
            setPlayer(results[1].data.data);
            setGroups(results[2].data.data);
            setOpponentSummary(results[3].data.data);
            setRecentForm(results[4].data.data);
        }
    // eslint-disable-next-line
    }, [isError, isLoading]);

    if (isLoading) {
        return <Loading></Loading>
    };

    if (isError) {
        return <span>Error: {errors[0].message}</span>
    };

    if (!isError && !isLoading && playerMatches.length === 0) {
        return <div>No matches found for that player ID.</div>
    };

    return (
        <PageBody>
            <Helmet>
                <title>Amateur Pool Player Results and Ratings | VizBadger</title>
                <meta name="description" content="Recorded games between amateur pool players to create dynamic ranking table to crown the winner."></meta>
            </Helmet>
            <PageHeader
                title={player.name}
                subtitle="Amateur Pool Matches"
                subtitleIsLink={true}
                linkDestination="/pool/matches"
            />

            <H2Heading>Recent Form</H2Heading>
            <div className="flex flex-col mb-4">
                <div className="flex justify-center align-center mb-1">
                    <div className="flex flex-grow"></div>
                    <div className="flex flex-col">
                        <div className="flex">
                            {recentForm.map((matchResult, index) => (
                                <div key={matchResult.recency_ids} className={`w-7 sm:w-8 h-7 sm:h-8 text-sm sm:text-base ${matchResult.win_varchar === 'W' ? 'bg-badger-green' : 'bg-badger-red'} text-white flex align-center justify-center ${index === recentForm.length - 1 ? 'mr-0' : 'mr-1'}`}>
                                    <p className="self-center">{matchResult.win_varchar}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end align-center text-gray-600 italic opacity-80">
                            <div className="text-xs self-center">
                                most recent
                            </div>
                            <ArrowLongRightIcon className="h-4 ml-0.5" />
                        </div>
                    </div>
                    <div className="flex flex-grow"></div>
                </div>
            </div>

            <div className="text-sm sm:text-base mb-4 w-2/3 mx-auto sm:w-full sm:mx-0">
                <H2Heading>Groups</H2Heading>
                <GridDivContainer className="flex flex-col sm:flex-row">
                    {groups.map(group => (
                        <GridDiv key={group.url_slug}>
                            <GroupRankingTable
                                groupUrlSlug={group.url_slug}
                                divClasses="mb-4 sm:mb-0"
                                highlightedPlayerID={parseInt(playerID)}
                            />
                        </GridDiv>
                    ))}
                </GridDivContainer>
            </div>

            <div className="text-xs sm:text-base mb-8">
                <H2Heading>Head To Head</H2Heading>
                <table className="w-full text-center">
                    <thead>
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="p-1">Opponent</th>
                            <th className="p-1 hidden sm:table-cell">Matches</th>
                            <th className="p-1 hidden sm:table-cell">Wins</th>
                            <th className="p-1 hidden sm:table-cell">Losses</th>
                            <th className="p-1 table-cell sm:hidden">W/L</th>
                            <th className="p-1">Best Streak</th>
                            <th className="p-1">Worst Streak</th>
                            <th className="p-1">Current Streak</th>
                            <th className="p-1">Best Win</th>
                            <th className="p-1">Worst Loss</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opponentSummary.map((opponent, index) => (
                            <tr key={opponent.opponent} className={`${index % 2 ? 'bg-gray-200' : ''}`}>
                                <td className="p-2 sm:p-1">{opponent.opponent}</td>
                                <td className="hidden sm:table-cell">{opponent.n_matches_played}</td>
                                <td className="hidden sm:table-cell">{opponent.n_wins}</td>
                                <td className="hidden sm:table-cell">{opponent.n_losses}</td>
                                <td className="table-cell sm:hidden">{opponent.n_wins}/{opponent.n_losses}</td>
                                <td className="text-badger-green">{opponent.max_winning_streak}</td>
                                <td className="text-badger-red">{opponent.max_losing_streak}</td>
                                <td className={`${opponent.current_streak_type === 'Winning' ? 'text-badger-green' : 'text-badger-red'}`}>{opponent.current_streak_length}</td>
                                <td><span className={`${opponent.n_wins > 0 ? 'text-badger-green' : 'hidden'}`}>10-{opponent.biggest_win}</span></td>
                                <td><span className={`${opponent.n_losses > 0 ? 'text-badger-red' : 'hidden'}`}>10-{opponent.biggest_defeat}</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="text-xs sm:text-base">
                <H2Heading>Matches</H2Heading>
                <table className="w-full text-center">
                    <thead>
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="p-1">Game #</th>
                            <th className="p-1">Player 1</th>
                            <th className="p-1">Score 1</th>
                            <th className="p-1">Score 2</th>
                            <th className="p-1">Player 2</th>
                            <th className="p-1">Game Date</th>
                            <th className="p-1">Fixture Weight</th>
                        </tr>
                    </thead>
                    <tbody>
                        {playerMatches.map(match => (
                            <tr key={match.new_id}>
                                <td>{match.new_id}</td>
                                <td>{match.player_1}</td>
                                <td className={`${match.winner_player_id === match.player_1_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_1}</td>
                                <td className={`${match.winner_player_id === match.player_2_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_2}</td>
                                <td>{match.player_2}</td>
                                <td>{match.game_datetime.toLocaleDateString()}</td>
                                <td>{match.fixture_weight}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </PageBody>
    );
};
