import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { GridDiv, GridDivContainer } from "../../../components/GridDiv";
import { H2Heading, ArticleTitle } from "../../../components/Headers";
import { ArticleParagraph } from "../../../components/Paragraphs";
import { PageBody } from "../../../components/PageBody";
import { SportsModelsHomeButton } from "../../../components/HomeLinks";


const mostRecentSimulationVersion = {
    'name': 'Stage 3: Foul Impact',
    'to': 'foul-impact',
    'description': "At my amateur level, fouls are commonplace and create plenty of opportunities. Let's add them to the system.",
};

export const PoolSimulationIntroduction = () => {
    return (
        <PageBody>
            <Helmet>
                <title>English Pool Monte Carlo Simulation - Introduction | VizBadger</title>
                <meta name="description" content="Design a sports model to simulate and predict pool games using player shot accuracy and break efficiency. Apply Monte Carlo simulation to model out key aspects of the game and produce predictions of results between two players."></meta>
                <meta name="keywords" content="Sports analytics, sports models, sports predictions, english pool, pool, billiards, monte carlo simulations, sport monte carlo simulations, pool simulator, sports simulators"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/sports-models/english-pool-monte-carlo-simulation" />
            </Helmet>

            <SportsModelsHomeButton />
            <ArticleTitle title="English Pool Monte Carlo Simulation" />

            <div className="my-4 sm:mb-8 sm:mx-4 rounded-lg border p-4 border-gray-200 align-right hover:border-badger-blue hover:shadow">
                <Link as="div" to={mostRecentSimulationVersion.to} className="flex flex-col items-center text-center">
                    <p className="text-xs sm:text-sm italic text-gray-400">Go straight to most recent simulation:</p>
                    <p className="my-2 sm:my-1 text-lg sm:text-xl tracking-tight">{mostRecentSimulationVersion.name}</p>
                    <p className="text-xs sm:text-sm">"{mostRecentSimulationVersion.description}"</p>
                </Link>
            </div>

            <div className="text-sm sm:text-base text-center sm:text-left">
                <div>
                    <H2Heading>What am I trying to do?</H2Heading>
                    <p className="mb-4">I want to predict the outcome of a game of pool between two players. What if I wanted to be able to create multiple betting markets for that game before it began, or even in-play? To achieve both of those things I'd have to work out an expected outcome or probability of something happening, whether it be a run of 4 pots, or the final result.</p>
                    <p className="mb-4">Monte Carlo simulations involve repeated sampling to obtain a likelihood for a particular outcome (exactly what I need); that includes final sports results and certain individual events happening within a game. </p>
                    <p className="mb-4">Monte Carlo models are constructed to run a system thousands or millions of times, and then uses the results of those simulations to create predictions. Multiple simulations generate a ‘smoothed’ sample that shows the range of outcomes to expect, and how often you should expect them.</p>
                    <p>Simulations are a good way to predict outcomes of complex systems; it also enables understanding of the system and the impact of uncertain or variable random elements within that system. A single event has so much variation associated with it, but imagine being able to have 10,000 events all played at the same time. Perhaps the result that you saw would happen half the time, or perhaps it was an upset, a result you would never see again. Such simulations are, perhaps predominantly, associated with risk modelling but my focus, and this example, will be on a game of pool.</p>
                </div>
                <hr className="pb-6 mx-12 mt-6 sm:pb-8 sm:mt-8" />
                <div>
                    <H2Heading>Simulating Results To Predict Sports</H2Heading>
                    <p className="mb-4">Sports events are subject to considerable variation, much of it immeasurable. There are, almost always, general feelings that one team is better than the other, but upsets exist. These can be based on form, stats, the news or personal affiliation with a team or player. Without this variation the result would be known before it starts. In sports betting, these likelihoods and potential outcomes drive the odds of each outcome; the more likely an outcome, the shorter the odds.</p>
                    <p className="mb-4">Therefore, given two players playing a game of pool, and using their observed performance statistics and 'skill' levels, what is the most likely outcome? Who is more likely to win? And by how much? Is having a powerful break more important than consistent potting throughout? These are all questions that I'll aim to answer using Monte Carlo simulations.</p>
                    <p>To be able to run simulations, I first have to design and build a system that contains variable elements and uses those to produce an outcome. I can show you the impact of improving a player's skill on the outcome of a single game, and expected outcomes given hundreds or thousands of simulations.</p>
                </div>
                <hr className="pb-6 mx-12 mt-6 sm:pb-8 sm:mt-8" />
                <div className="mb-4 sm:mb-0">
                    <H2Heading>An Incremental Approach</H2Heading>
                    <p className="mb-4">Such Monte Carlo models are often complex, building out the system incrementally and increasing the complexity of the simulation at each stage provides a palatable and explainable process to the results we produce.</p>
                    <p>Through adding interesting new features that model the nuances of the game and players we increase our predictive power (theoretically) and, most definitely, variation to the likelihood of results.</p>
                </div>
            </div>
            <GridDivContainer>
                <GridDiv
                    key={1}
                    widthClasses="w-full sm:w-1/2 md:w-1/3 text-sm sm:text-base text-center sm:text-left"
                >
                    <Link
                        to="shot-accuracy"
                        className="tracking-tight text-base sm:w-auto hover:border-badger-blue w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-2 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        Stage 1: Shot Accuracy
                    </Link>
                    <div>
                        <p className="mb-4 mt-2 text-center">We begin with the most basic of assumptions: a player either makes or misses a shot.</p>
                    </div>
                </GridDiv>
                <GridDiv
                    key={2}
                    widthClasses="w-full sm:w-1/2 md:w-1/3 text-sm sm:text-base text-center sm:text-left"
                >
                    <Link
                        to="break-efficiency"
                        className="tracking-tight text-base sm:w-auto hover:border-badger-blue w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-2 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        Stage 2: Break Efficiency
                    </Link>
                    <div>
                        <p className="mb-4 mt-2 text-center">The first shot of a game is very different to all others. Who wins between the player with a big powerful break and a more consistent player?</p>
                    </div>
                </GridDiv>
                <GridDiv
                    key={3}
                    widthClasses="w-full sm:w-1/2 md:w-1/3 text-sm sm:text-base text-center sm:text-left"
                >
                    <Link
                        to="foul-impact"
                        className="tracking-tight text-base sm:w-auto hover:border-badger-blue w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-2 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        Stage 3: Foul Impact
                    </Link>
                    <div>
                        <p className="mb-4 mt-2 text-center">At my amateur level, fouls are commonplace and create plenty of opportunities. Let's add them to the system.</p>
                    </div>
                </GridDiv>
            </GridDivContainer>
            <hr className="pb-6 mx-12 mt-6 sm:pb-8 sm:mt-8" />
            <div className="sm:mb-0 flex justify-center flex-col items-center">
                <H2Heading>Programming Player Attributes and Skills</H2Heading>
                <ArticleParagraph lines={[
                    "Alongside the game itself, we will also be building out the model structure of a player. By setting the variability of a player's skills and attributes we enhance the model to be able to simulate between player archetypes and calculate which attributes have a greater impact on the outcome of the game.",
                    "We can generate a set of random players, and then use those players and our modeled system to simulate the outcome of an entire league played between them.",
                ]} />
            </div>
            <GridDivContainer className="mt-2 sm:mt-0 mb-4">
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="player-model"
                        className="tracking-tight text-base sm:w-auto hover:border-badger-blue w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-2 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        View Player Model
                    </Link>
                </GridDiv>
                <GridDiv
                    widthClasses="w-full md:w-1/2"
                >
                    <Link
                        to="league-predictions"
                        className="tracking-tight text-base sm:w-auto hover:border-badger-blue w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-2 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        Simulate League Predictions
                    </Link>
                </GridDiv>
            </GridDivContainer>
        </PageBody>
    );
};
