import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { Link, useParams } from "react-router-dom";
import { getGroupMatches, getGroupPlayers, getGroup } from "../../api/pool";
import { PageHeader, H2Heading, H3Heading } from "../../components/Headers";
import { Loading } from "../../components/Loading";
import { PageBody } from "../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { createFixtureMatrix, createResultsVectors, createWeightedFixtureMatrix, createWeightedResultsVectors, generateRatings, summariseMatches } from "../../components/utils/ColleyCalculations";
import { GridDivContainer, GridDiv } from "../../components/GridDiv";


export const PoolGroup = () => {
    let { groupUrlSlug } = useParams();
    const [group, setGroup] = useState(null);
    const [players, setPlayers] = useState(null);
    const [matches, setMatches] = useState(null);
    const [fixtureMatrix, setFixtureMatrix] = useState(null);
    const [weightedFixtureMatrix, setWeightedFixtureMatrix] = useState(null);
    const [resultsVector, setResultsVector] = useState(null);
    const [weightedResultsVector, setWeightedResultsVector] = useState(null);
    const [ratings, setRatings] = useState(null);
    const [weightedRatings, setWeightedRatings] = useState(null);

    const results = useQueries([
        { queryKey: ["poolGroup", groupUrlSlug], queryFn: () => getGroup(groupUrlSlug) },
        { queryKey: ["poolGroupPlayers", groupUrlSlug], queryFn: () => getGroupPlayers(groupUrlSlug) },
        { queryKey: ["poolGroupMatches", groupUrlSlug], queryFn: () => getGroupMatches(groupUrlSlug) },
    ]);

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });

    useEffect(() => {
        if (!isError && !isLoading) {
            setGroup(results[0].data.data);
            setPlayers(results[1].data.data);
            let matches = results[2]['data']['data'].map(match => {
                return {
                    ...match,
                    game_datetime: new Date(match.game_datetime.replace(/-/g, "/")), // Define game_datetime as Date
                    win_weight: 1,
                    // fixture_weight: match.new_id <= results[2]['data']['data'].length - 50 ? match.new_id <= results[2]['data']['data'].length - 100 ? 0.8 : 0.9 : 1
                }
            }).sort((a, b) => b.new_id - a.new_id);

            // Unweighted results array of array: resultScore, wins, losses
            const resultsRatingsArray = createResultsVectors(results[1].data.data, matches);

            setMatches(matches);
            setFixtureMatrix(createFixtureMatrix(results[1].data.data, matches));
            setWeightedFixtureMatrix(createWeightedFixtureMatrix(results[1].data.data, matches));
            setResultsVector(resultsRatingsArray[0]);
            setWeightedResultsVector(createWeightedResultsVectors(results[1].data.data, matches));
            setRatings(generateRatings(createFixtureMatrix(results[1].data.data, matches), resultsRatingsArray[0]));
            setWeightedRatings(generateRatings(createWeightedFixtureMatrix(results[1].data.data, matches), createWeightedResultsVectors(results[1].data.data, matches)));
        }
    // eslint-disable-next-line
    }, [isError, isLoading]);

    if (isLoading) {
        return <Loading />
    };

    if (isError) {
        return <span>Error: {errors[0].message}</span>
    };

    if (group === null || players === null || matches === null) {
        return <Loading />
    };

    if (!isError && !isLoading && group === null) {
        return <div>Group not found.</div>
    };

    // Lets calculate general details about a set of games for each player
    const weightings = [...new Set(matches.map(match => match.fixture_weight))];
    const weightPerformance = {};
    for (let i = 0; i < weightings.length; i++) {
        weightPerformance[weightings[i]] = summariseMatches(players, matches.filter(match => match.fixture_weight === weightings[i]));
    };

    const playerPWL = summariseMatches(players, matches);
    const playerSummaries = Array(players.length);
    for (let i = 0; i < players.length; i++) {
        playerSummaries[i] = {
            "player_id": players[i]["id"],
            "name": players[i]["name"],
            "games_played": playerPWL[i][0],
            "wins": playerPWL[i][1],
            "losses": playerPWL[i][2],
            "win_percentage": (100.0*playerPWL[i][1]/playerPWL[i][0]).toFixed(1),
            "weighted_win_percentage": (100.0*playerPWL[i][3]/playerPWL[i][4]).toFixed(1),
            "rating": weightedRatings[i],
        };
    };
    playerSummaries.sort((a, b) => b.rating - a.rating);

    return (
        <PageBody>
            <Helmet>
                <title>{group.name} Pool Group Ratings | VizBadger</title>
                <meta name="description" content="Recorded games between amateur pool players to create dynamic ranking table to crown the winner."></meta>
            </Helmet>
            <PageHeader
                title={group.name}
                subtitle="Amateur Pool Matches"
                subtitleIsLink={true}
                linkDestination="/pool/matches"
            />
            <div className="w-full mb-4 text-sm sm:text-base text-center sm:text-left">
                <H3Heading>Current Standings</H3Heading>
                <table className="w-full table-fixed">
                    <thead className="text-center">
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="w-6 sm:w-12 p-1">#</th>
                            <th>Player</th>
                            <th className="hidden sm:table-cell">Games</th>
                            <th>Wins</th>
                            <th>Losses</th>
                            <th>Win %</th>
                            <th className="hidden sm:table-cell">Weighted Win %</th>
                            <th>Rating</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {playerSummaries.map((player, i) => (
                            <tr key={i} className={i === 0 ? "border-b border-badger-green bg-badger-green/10" : i === players.length - 1 ? "border-t border-badger-red bg-badger-red/10" : ""}>
                                <td className="p-1">{i + 1}.</td>
                                <td className="p-1 hover:cursor-pointer hover:text-badger-blue hover:underline">
                                    <Link
                                        to={"/pool/player/" + player.player_id}>{player.name}
                                    </Link>
                                </td>
                                <td className="p-1 hidden sm:table-cell">{player.games_played}</td>
                                <td className="p-1">{player.wins}</td>
                                <td className="p-1">{player.losses}</td>
                                <td className="p-1">{player.win_percentage}%</td>
                                <td className="p-1 hidden sm:table-cell">{player.weighted_win_percentage}%</td>
                                <td className="p-1">{player.rating}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="text-sm sm:text-base text-center sm:text-left">
                <div className="mb-6">
                    <H3Heading>Group Players</H3Heading>
                    <GridDivContainer>
                        {players.map(player =>
                            <GridDiv
                                key={player.name}
                                widthClasses="px-1 w-1/4 sm:w-1/4"
                            >
                                <Link
                                    className="flex border border-gray-200 rounded text-badger-blue border-badger-blue border-2 font-semibold cursor-pointer p-2 sm:p-3 my-1 text-center align-center justify-center tracking-tight hover:bg-badger-blue hover:text-white"
                                    to={`/pool/player/${player.id}`}
                                >
                                    {player.name}
                                </Link>
                            </GridDiv>
                        )}
                    </GridDivContainer>
                </div>
                <div className="mb-6">
                    <H2Heading>How To Calculate Colley Ratings</H2Heading>
                    <H3Heading>Fixture Matrix</H3Heading>
                    <p className="mb-1 italic">A major part of the calculation of the rankings is the fixture matrix, that tracks the number of games between each of the players in the group.</p>
                    <p className="mb-1 italic">Note: for N player, the Nth position in their array will represent their total games + 2.</p>
                    <p className="mb-1 italic">The other numbers are the negative of the total matches between the player at that index position. This shows how many games have been played between each player. It is worth noting here that not all players have to have played each other to get a ranking in the group. That is one of the benefits of this Colley Ranking System.</p>
                    <p className="mb-1 italic">i.e. [ 43, -13, -7, -21 ] has played 41 games and played 13 games against the second player, 7 against the third etc.</p>
                    <div className="flex items-center justify-center mb-4">
                        <table className="text-center">
                            <thead className="text-sm sm:text-base tracking-tight font-semibold">
                                <tr>
                                    <td className="border-r border-b"></td>
                                    {players.map((player) => (
                                        <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {players.map((player, i) => (
                                    <tr key={i}>
                                        <td className="text-sm sm:text-base tracking-tight font-semibold p-1 border-r">{player.name}</td>
                                        {fixtureMatrix[i].map((fixture, j) => (
                                            <td key={j}>{fixture}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-col items-center justify-center text-gray-600">
                        <p>Weighted fixture matrix</p>
                        <table className="text-center">
                            <thead className="text-sm sm:text-base tracking-tight font-semibold">
                                <tr>
                                    <td className="border-r border-b"></td>
                                    {players.map((player) => (
                                        <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {players.map((player, i) => (
                                    <tr key={i}>
                                        <td className="text-sm sm:text-base tracking-tight font-semibold p-1 border-r">{player.name}</td>
                                        {weightedFixtureMatrix[i].map((fixtureValue, j) => (
                                            <td key={j}>{fixtureValue.toFixed(1)}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-6 italic">
                    <H3Heading classes="not-italic">Results Vector</H3Heading>
                    <p className="mb-1">The second major component of the ranking is the results. Here we calculate a score based on the wins and losses of the player. Note here that the total score isn't necessarily the 'best' player; we have to use the fixture matrix to adjust to the number of games and who they have played.</p>
                    <div className="flex items-center justify-center">
                        <table className="text-center">
                            <thead className="text-sm sm:text-base tracking-tight font-semibold">
                                <tr>
                                    <td className="border-r border-b"></td>
                                    {players.map((player) => (
                                        <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Results Score</td>
                                    {players.map((player, i) => (
                                        <td key={i} className="p-2">{resultsVector[i].toFixed(2)}</td>
                                    ))}
                                </tr>
                                <tr className="text-gray-600">
                                    <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Weighted</td>
                                    {players.map((player, i) => (
                                        <td key={i} className="p-2">{weightedResultsVector[i].toFixed(2)}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-6 italic">
                    <H2Heading classes="not-italic">Generating Player Ratings</H2Heading>
                    <p className="mb-2">We can then bring the two elements together and solve them linearly to calculate a rating for each player.</p>
                    {/* <p className="mb-2">{JSON.stringify(fixtureMatrix)} * {JSON.stringify(resultsVector.map(i => i.toFixed(2)))}</p> */}
                    <div className="flex flex-col items-center justify-center">
                        <table className="text-center">
                            <thead className="text-sm sm:text-base tracking-tight font-semibold">
                                <tr>
                                    <td className="border-r border-b"></td>
                                    {players.map((player) => (
                                        <th key={player.name} className="px-2 py-1 border-b">{player.name}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Rating</td>
                                    {ratings.map((rating, i) => (
                                        <td key={i} className="p-2">{rating}</td>
                                    ))}
                                </tr>
                                <tr className="text-gray-600">
                                    <td className="text-sm sm:text-base tracking-tight font-semibold px-2 py-1 border-r">Weighted</td>
                                    {weightedRatings.map((weightedRating, i) => (
                                        <td key={i} className="p-2">{weightedRating}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-6 italic">
                    <H2Heading classes="not-italic">Fixture Weight Intuition</H2Heading>
                    <p className="mb-2 not-italic">If we split out the results on the basis of performance during those fixture weight groups, then we can start to understand why the ratings have changed.</p>
                    <div className="flex flex-wrap">
                        {players.map((player, i) => (
                            <div key={i} className="w-full sm:w-1/3 flex flex-col items-center justify-center mb-3">
                                <p className="text-sm sm:text-base tracking-tight font-semibold">{players[i].name}</p>
                                <p className={`mb-2 flex items-center ${ratings[i] > weightedRatings[i] ? 'text-badger-red': 'text-badger-green'}`}>{ratings[i]} <ArrowRightIcon className="mx-1 w-4 h-4"/> {weightedRatings[i]}</p>
                                <table
                                    className="text-center"
                                >
                                    <thead className="text-xs sm:text-sm tracking-tight font-semibold">
                                        <tr>
                                            <td className="border-r border-b"></td>
                                            <th className="px-2 py-1 border-b">Played</th>
                                            <th className="px-2 py-1 border-b">Wins</th>
                                            <th className="px-2 py-1 border-b">Losses</th>
                                            <th className="px-2 py-1 border-b">Win %</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {weightings.map((weighting) => (
                                            <tr key={weighting} className={`${weightPerformance[weighting][i][0] === 0 ? 'text-gray-400' : ''}`}>
                                                <td className="text-xs sm:text-sm tracking-tight font-semibold p-1 border-r">{weighting}</td>
                                                {weightPerformance[weighting][i].slice(2).map((value, j) => ( //array for each player
                                                    <td key={j}>{weightPerformance[weighting][i][j]}</td>
                                                ))}
                                                <td>{weightPerformance[weighting][i][1] > 0 ? (100.0 * weightPerformance[weighting][i][1] / weightPerformance[weighting][i][0]).toFixed(1) : 0}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                        <div className="not-italic">
                            <p className="mb-2">The movements in ratings tend towards that those with better results more recently have seen the improvement in the downgrade of importance to older results.</p>
                            <p className="mb-2">It is difficult to pinpoint some players, but you have to keep in mind that wins against certain players become less value when the fixtures change. The Colley system doesn't award points for a win based on the strength of the opponent at the time of the game (like in the ELO ranking system), but instead balances out all results each time a game is played.</p>
                            <p className="mb-2">I.e. if you beat the top then-rated player early in the league when everyone is rated equally but they subsequently lost a lot of rating (they started stronger than their true skill, and eventually lost a lot more games to other players) that game becomes less valuable for your personal rating because the win was against a player that wasn't really as strong as they seemed at the time.</p>
                            <p className="mb-2"><span className="font-semibold italic">A win that lowers an opponent's rating impacts players that aren't playing in the game.</span> Lowering the rating of the top player belittles the wins that other players have against him as well as raising the impact of fixtures that they have had against you.</p>
                        </div>
                    </div>
                </div>
                <div className="text-sm sm:text-base">
                    <H2Heading>All Matches</H2Heading>
                    <p className="mb-1">See below a list of games, ordered by recency. I've added a weighting decay to each fixture, so those games that were played longer ago (based on number of games within the group) aren't as impactful on the final ratings.</p>
                    <p className="mb-2">Weighting the results in multiple ways would allow the rating system to be more dynamic in an attempt to capture the importance of wins. By not only decaying fixtures but by increasing the value of certain wins you could impact the final rating calculation.</p>
                    <div className="my-4 flex justify-center">
                        <Link
                            to={`/pool/predictions/${groupUrlSlug}`}
                            className="text-badger-blue border-badger-blue border-2 py-1 sm:py-2 px-2 sm:px-4 rounded-full hover:bg-badger-blue hover:text-white"
                        >
                            View group ratings over time
                        </Link>
                    </div>
                    <table className="w-full text-center mt-2">
                        <thead>
                            <tr className="bg-gray-200 tracking-tight">
                                <th className="p-1">Game #</th>
                                <th className="p-1">Player 1</th>
                                <th className="p-1">Score 1</th>
                                <th className="p-1">Score 2</th>
                                <th className="p-1">Player 2</th>
                                <th className="p-1">Game Date</th>
                                <th className="p-1">Fixture Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map(match => (
                                <tr key={match.new_id}>
                                    <td>{match.new_id}</td>
                                    <td>{match.player_1}</td>
                                    <td className={`${match.winner_player_id === match.player_1_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_1}</td>
                                    <td className={`${match.winner_player_id === match.player_2_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_2}</td>
                                    <td>{match.player_2}</td>
                                    <td>{match.game_datetime.toLocaleDateString()}</td>
                                    <td>{match.fixture_weight}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </PageBody >
    );
};
