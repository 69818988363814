import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { abs } from "mathjs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueries } from "react-query";
import { useParams } from "react-router-dom";
import { getGroup, getGroupMatches, getGroupPlayers } from "../../api/pool";
import { PageHeader, H2Heading } from "../../components/Headers";
import { Loading } from "../../components/Loading";
import { PageBody } from "../../components/PageBody";
import { createWeightedFixtureMatrix, createWeightedResultsVectors, generateRatings } from "../../components/utils/ColleyCalculations";
import { displayPercentage } from "../../helpers";


const calculateRankingsOverTime = (matches, players) => {
    // Ratings will be the ratings AFTER the match has taken place
    let rankings = Array(matches.length).fill({});

    // Copy and sort the matches from oldest to newest
    // Need to update fixture weight here to reflect the time of fixture being played
    const sortedMatches = matches.sort((a, b) => a.new_id - b.new_id);
    let loopMatches = sortedMatches;
    let fixtureMatrix = [];
    let resultsVector = [];
    let loopRankings = [];
    let playerLoopRankings = {};
    let matchWinner = "";
    let matchLoser = "";

    players.forEach((player, index) => {
        playerLoopRankings[player.name] = loopRankings[index];
    });

    // For each loop, isolate games INCLUSIVE of the rank
    for (let i = 0; i < rankings.length; i++) {
        // Slice games and then calculate the rating AFTER the result
        loopMatches = sortedMatches.slice(0, i + 1).map(match => {
            return {
                ...match,
                // You can use new_id here as the new_id is calculated when the matches are pulled from DB
                // Add attributes here that are required for fixture matrix and results; overall attributes can be added later with rankings[i]
                'win_weight': 1,
                'fixture_weight': 1, //match.new_id <= sortedMatches.slice(0, i + 1).length - 50 ? match.new_id <= sortedMatches.slice(0, i + 1).length - 100 ? 0.8 : 0.9 : 1,
            }
        });

        // Generate the rankings post loop match
        fixtureMatrix = createWeightedFixtureMatrix(players, loopMatches);
        resultsVector = createWeightedResultsVectors(players, loopMatches);
        loopRankings = generateRatings(fixtureMatrix, resultsVector);

        // eslint-disable-next-line
        players.forEach((player, index) => {
            playerLoopRankings[player.name] = loopRankings[index];
        });

        matchWinner = sortedMatches[i].winner_player_id === sortedMatches[i].player_1_id ? sortedMatches[i].player_1 : sortedMatches[i].player_2;
        matchLoser = sortedMatches[i].winner_player_id === sortedMatches[i].player_1_id ? sortedMatches[i].player_2 : sortedMatches[i].player_1;

        rankings[i] = {
            'match_new_id': sortedMatches[i]['new_id'],
            'session_id': sortedMatches[i]['session_id'],
            'match': sortedMatches[i],
            'matchWinner': matchWinner,
            'matchLoser': matchLoser,
            'winnersPreMatchRatingDifference': sortedMatches[i]['new_id'] === 1 ? 0.00 : rankings[i - 1]["playerRankings"][matchWinner] - rankings[i - 1]["playerRankings"][matchLoser],

            // 'matches': loopMatches,
            // 'players': players,
            // 'fixture_matrix': fixtureMatrix,
            // 'results_vector': resultsVector,
            // 'rankings': loopRankings,

            // Do we need a start of session and to create the loop rankings, for each session we need ranking for the start and end, does this require an additional window partiiton on the API call?
            // If first match or prior match was end of a session, then it is the start of a session
            'is_start_of_session': sortedMatches[i]['new_id'] === 1 ? 1 : sortedMatches[i]['session_id'] !== sortedMatches[i-1]['session_id'] ? 1 : 0,

            // Use API request tag for marking the end of session
            'is_end_of_session': sortedMatches[i]['session_match_end_id'] === 1 ? 1 : 0,

            // The rankings AFTER the match being played
            'playerRankings': { ...playerLoopRankings }
        };
    };

    return rankings;
};


export const PoolSessionSummary = (rankings) => {
    let sessionSummaries = [];
    let sessionSummary = {};
    let previousSessionMatches = [];
    let baseRankings = rankings[0]['playerRankings'];
    Object.keys(baseRankings).forEach(v => baseRankings[v] = '50.00');

    // Filter to return rankings for end of previous period and start of period, then return both?
    let filteredRankings = rankings.filter(match => match.is_start_of_session === 1 || match.is_end_of_session === 1);

    // For each start/end, if the start of session then take the end of previous session
    // Doesn't account for sessions of 1 match length at the moment - if no end record, then take the playerRankings as that is AFTER the match
    filteredRankings.map((record, index) => record.playerRankingsNew = record.match_new_id === 1 ? record.playerRankings : record.is_start_of_session === 1 ? filteredRankings[index-1]['playerRankings'] : record.playerRankings);

    for (let i = 1; i <= rankings[rankings.length - 1]['session_id']; i++) {
        sessionSummary = {};
        previousSessionMatches = rankings.filter(match => match['session_id'] === i - 1);

        sessionSummary['session_id'] = i;
        sessionSummary['sessionDate'] = rankings.filter(match => match['session_id'] === i)[0]['match']['game_datetime'];
        sessionSummary['matches'] = rankings.filter(match => match['session_id'] === i);
        sessionSummary['nMatches'] = sessionSummary['matches'].length;
        sessionSummary['startRankings'] = i === 1 ? baseRankings : previousSessionMatches[previousSessionMatches.length - 1]['playerRankings'];
        sessionSummary['endRankings'] = sessionSummary['matches'][sessionSummary['nMatches'] - 1]['playerRankings'];

        sessionSummaries.push(sessionSummary);
    };

    return sessionSummaries;
};


export const PoolPredictions = () => {
    let { groupUrlSlug } = useParams();
    // Given a set of matches, calculate a running ranking system
    // Did the highest ranked player win
    // Include streaks if possible
    // Head to head
    // Margin of victory
    const [group, setGroup] = useState(null);
    const [players, setPlayers] = useState(null);
    const [matches, setMatches] = useState(null);
    const [incrementalRankings, setIncrementalRankings] = useState(null);
    const [winnersPreMatchRatingDifferences, setWinnersPreMatchRatingDifferences] = useState(null);
    const [sessionStartEndRankings, setSessionStartEndRankings] = useState(null);

    const results = useQueries([
        { queryKey: ["poolGroup", groupUrlSlug], queryFn: () => getGroup(groupUrlSlug) },
        { queryKey: ["poolGroupPlayers", groupUrlSlug], queryFn: () => getGroupPlayers(groupUrlSlug) },
        { queryKey: ["poolGroupMatches", groupUrlSlug], queryFn: () => getGroupMatches(groupUrlSlug) },
    ]);

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });

    useEffect(() => {
        if (!isError && !isLoading) {
            setGroup(results[0].data.data);
            setPlayers(results[1].data.data);
            let matches = results[2]['data']['data'].map(match => {
                return {
                    ...match,
                    game_datetime: new Date(match.game_datetime.replace(/-/g, "/")), // Define game_datetime as Date
                    win_weight: 1,
                    // fixture_weight: match.new_id <= results[2]['data']['data'].length - 50 ? match.new_id <= results[2]['data']['data'].length - 100 ? 0.8 : 0.9 : 1
                }
            }).sort((a, b) => b.new_id - a.new_id);

            setMatches(matches);

            let incrementalRatings = calculateRankingsOverTime(results[2]['data']['data'], results[1].data.data);
            setIncrementalRankings(incrementalRatings);
            setWinnersPreMatchRatingDifferences(incrementalRatings.map(matchRating => matchRating.winnersPreMatchRatingDifference));

            let sessionStartEndRankings = PoolSessionSummary(incrementalRatings);
            setSessionStartEndRankings(sessionStartEndRankings);
        }
    // eslint-disable-next-line
    }, [isError, isLoading]);

    if (isLoading) {
        return <Loading />
    };

    if (isError) {
        return <span>Error: {errors[0].message}</span>
    };

    if (group === null || players === null || matches === null) {
        return <Loading />
    };

    if (!isError && !isLoading && group === null) {
        return <div>Group not found.</div>
    };

    return (
        <PageBody>
            <Helmet>
                <title>Amateur Pool Match Predictions | VizBadger</title>
                <meta name="description" content="Predicting outcomes of pool matches based on a dynamic Colley ranking system."></meta>
            </Helmet>
            <PageHeader
                title="Amateur Pool Match Predictions"
                subtitle="Amateur Pool Matches"
                subtitleIsLink={true}
                linkDestination="/pool/matches"
            />
            <div className="text-sm sm:text-base">
                <div className="mb-4">
                    <p className="mb-2">Predicting a game of pool between two amateur players is based on gut feeling during the moment. That person just won convincingly, or that person is in the other's head, easy win.</p>
                    <p className="mb-2">Using a rating system, we can engineer a feature to help quantify the chance of one player beating another. The ELO rating uses skill distributions for each player, with the overlap in performance ranges telling you the likelihood of player A beating player B.</p>
                    <p className="mb-2">Colley rating systems update all ratings each time a game is played. So a player has a starting rating and an ending rating post-match. This seems like a good indicator of win probability. Let's start by creating pre-match and post-match ratings see if there are correlations between the ranking and the result of the game (as you'd expect there to be).</p>
                </div>

                <div className="mb-4">
                    <H2Heading>Pre-match Rating Differences</H2Heading>
                    <p className="mb-2">Below is the distribution of results grouped by the difference in the winners rating vs the player that they beat. Positive shows that the winner was higher rated before the game. We see an initial trend of the higher rated player generally winning the games they play.</p>
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="flex flex-wrap items-center justify-center mb-2">
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r"></div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">Wins</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">%</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">-ve</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => i < 0).length / winnersPreMatchRatingDifferences.length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">=</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => i === 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => i === 0).length / winnersPreMatchRatingDifferences.length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">+ve</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => i > 0).length / winnersPreMatchRatingDifferences.length)}</div>
                            </div>
                        </div>
                    </div>

                    <p className="mb-2">Seeing the win percentage of the higher rated players when the difference is at certain thresholds shows us that there is generally a higher +ve win percentage where the difference is greater.</p>
                    <div className="flex flex-wrap items-center justify-center">
                        <div className="flex flex-wrap items-center justify-center mb-2">
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">| Pre-match Rating Diff |</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r"># Matches</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">+ve Wins</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">-ve Wins</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">%</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">&#62;= 20</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 20).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 20 & i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 20 & i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => abs(i) >= 20 & i > 0).length / winnersPreMatchRatingDifferences.filter(i => abs(i) >= 20).length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">20-15</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 15 & abs(i) < 20).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 15 & abs(i) < 20 & i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 15 & abs(i) < 20 & i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => abs(i) >= 15 & abs(i) < 20 & i > 0).length / winnersPreMatchRatingDifferences.filter(i => abs(i) >= 15 & abs(i) < 20).length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">15-10</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 10 & abs(i) < 15).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 10 & abs(i) < 15 & i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 10 & abs(i) < 15 & i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => abs(i) >= 10 & abs(i) < 15 & i > 0).length / winnersPreMatchRatingDifferences.filter(i => abs(i) >= 10 & abs(i) < 15).length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">10-5</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 5 & abs(i) < 10).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 5 & abs(i) < 10 & i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 5 & abs(i) < 10 & i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => abs(i) >= 5 & abs(i) < 10 & i > 0).length / winnersPreMatchRatingDifferences.filter(i => abs(i) >= 5 & abs(i) < 10).length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">5-1</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 1 & abs(i) < 5).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 1 & abs(i) < 5 & i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) >= 1 & abs(i) < 5 & i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => abs(i) >= 1 & abs(i) < 5 & i > 0).length / winnersPreMatchRatingDifferences.filter(i => abs(i) >= 1 & abs(i) < 5).length)}</div>
                            </div>
                            <div
                                className="flex flex-col text-center my-2"
                            >
                                <div className="flex justify-center items-center h-8 px-2 italic p-3 border-b text-center border-r">&#60; 1</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) < 1).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) < 1 & i > 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r">{winnersPreMatchRatingDifferences.filter(i => abs(i) < 1 & i < 0).length}</div>
                                <div className="flex justify-center items-center h-8 px-2 italic border-r pt-2 text-sm text-gray-500">{displayPercentage(winnersPreMatchRatingDifferences.filter(i => abs(i) < 1 & i > 0).length / winnersPreMatchRatingDifferences.filter(i => abs(i) < 1).length)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <H2Heading>All Matches</H2Heading>
                <p className="mb-2 italic text-sm">Note that these ratings don't account for time decay, but illustrate point difference in predicting results.</p>
                <table className="w-full text-center">
                    <thead>
                        <tr className="bg-gray-200 tracking-tight">
                            <th className="p-2">#</th>
                            <th className="p-2">P1</th>
                            <th className="p-2">P1 Change</th>
                            <th className="p-2">Score 1</th>
                            <th className="p-2">Score 2</th>
                            <th className="p-2">P2 Change</th>
                            <th className="p-2">P2</th>
                            <th className="p-2">Winner Pre Diff</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matches.map((match, index) => (
                            <tr
                                key={match.new_id}
                            >
                                <td className="py-1">{match.new_id}</td>
                                <td>{match.player_1}</td>
                                <td
                                    className="py-1 flex items-center justify-center"
                                >
                                    {matches[index].new_id === 1 ? '50.00' : incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id - 1)[0]['playerRankings'][match.player_1]} <ArrowRightIcon className="h-4 w-4 mx-2" /> {incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['playerRankings'][match.player_1]}
                                </td>
                                <td className={`${match.winner_player_id === match.player_1_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_1}</td>
                                <td className={`${match.winner_player_id === match.player_2_id ? 'text-badger-green' : ''} p-0.5 text-center`}>{match.score_2}</td>
                                <td
                                    className="py-1 flex items-center justify-center"
                                >
                                    {matches[index].new_id === 1 ? '50.00' : incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id - 1)[0]['playerRankings'][match.player_2]} <ArrowRightIcon className="h-4 w-4 mx-2" /> {incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['playerRankings'][match.player_2]}
                                </td>
                                <td>{match.player_2}</td>
                                <td
                                    className={`${incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['winnersPreMatchRatingDifference'] > 0 ? "text-badger-green" : incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['winnersPreMatchRatingDifference'] === 0 ? "" : "text-badger-red"}`}
                                >{incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['winnersPreMatchRatingDifference'] > 0 ? "+" + incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['winnersPreMatchRatingDifference'].toFixed(2) : incrementalRankings.filter(rank => rank.match_new_id === matches[index].new_id)[0]['winnersPreMatchRatingDifference'].toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            <div className="my-4">
                <H2Heading>Session by session breakdown</H2Heading>
                <div className="mb-4">
                    <p className="mb-2">Breaking down matches into sessions, or days of play, we are able to see what the ranking started as at the beginning of the session, and what the final ranking scores were.</p>
                </div>
                {sessionStartEndRankings.map((sessionSummary) => (
                    <div key={sessionSummary['session_id']} className="text-xs sm:text-base mb-8 w-full">
                        <table className="w-full text-center">
                            <thead>
                                <tr>
                                    <th>{sessionSummary['session_id']}</th>
                                    {players.map((player) => (
                                        <th key={player.name}>{player['name']}</th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Start</td>
                                    {players.map((player) => (
                                        <td key={player.name}>{sessionSummary['startRankings'][player.name]}</td>
                                        ))}
                                </tr>
                                <tr>
                                    <td>End</td>
                                    {players.map((player) => (
                                        <td key={player.name}>{sessionSummary['endRankings'][player.name]}</td>
                                        ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
                </div>
        </PageBody>
    );
};
