import { CheckIcon, ExclamationTriangleIcon, BoltIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SmallAccordion } from "./Accordion";
import { BasicShotOutcomeIcon } from "./GameShotSummary"

export const ShotByShotReport = ({ simulatedGame, highlightBreakBenefit = true, showBreakPlayer = false }) => {
    return (
        <div className="flex flex-wrap">
            <div
                className={`flex flex-col text-center my-2 ${!showBreakPlayer ? 'border-r' : ''}`}
            >
                <div className="h-6 w-8 text-sm italic text-gray-500 text-center">-</div>
                <div className="h-6 w-8 pr-2 italic border-b">P1</div>
                <div className="h-6 w-8 pr-2 italic">P2</div>
            </div>
            {showBreakPlayer &&
                <div
                    className="flex flex-col text-center my-2 border-r"
                >
                    <div className="h-6 w-6 text-sm italic text-gray-500 text-center"></div>
                    <div className="h-6 w-6 flex items-center justify-start text-center italic border-b">{simulatedGame.breakPlayer === 'player1' ? <BoltIcon className="h-4 w-4 text-badger-yellow" /> : ""}</div>
                    <div className="h-6 w-6 flex items-center justify-start text-center italic">{simulatedGame.breakPlayer === 'player2' ? <BoltIcon className="h-4 w-4 text-badger-yellow" /> : ""}</div>
                </div>
            }
            <div
                className="flex flex-col text-center my-2 border-r"
            >
                <div className="h-6 w-12 text-sm italic text-gray-500 text-center">#</div>
                <div className="h-6 w-12 text-center italic border-b">{simulatedGame.player1Score}</div>
                <div className="h-6 w-12 text-center italic">{simulatedGame.player2Score}</div>
            </div>

            {simulatedGame.shots.map((shot, i) =>
                // <>
                // {i===7 | (i > 20 & i % 15 === 0) ? <hr className="w-full h-0 border-none" /> : <></>}

                <div
                    className="flex flex-col text-center my-2"
                    key={i}
                >
                    {/* Add a plus when the spread benefit is active */}
                    <div className="h-6 w-8 text-sm italic text-gray-500 text-center">{i + 1}{highlightBreakBenefit && shot[5] ? '+' : ''}</div>
                    <div className="h-6 w-8 flex justify-center items-center text-center border-b">{shot[0] === 'player1' ? <BasicShotOutcomeIcon outcome={shot[1]} /> : ""}</div>
                    <div className="h-6 w-8 flex justify-center items-center text-center">{shot[0] === 'player2' ? <BasicShotOutcomeIcon outcome={shot[1]} /> : ""}</div>
                </div>
                // </>
            )}

            <div
                className="flex flex-col text-center my-2 border-l border-r"
            >
                <div className="h-6 w-12 text-sm italic text-gray-500 text-center">#</div>
                <div className="h-6 w-12 text-center italic border-b">{simulatedGame.player1Score}</div>
                <div className="h-6 w-12 text-center italic">{simulatedGame.player2Score}</div>
            </div>
        </div>
    );
};


const FoulOutcomeIcon = ({ outcome, isFoul, isGameLosing }) => {
    if (isGameLosing) {
        return <ExclamationTriangleIcon className="h-6 w-6 text-black" />
    };

    if (outcome) {
		if (isFoul) {
			return <ExclamationTriangleIcon className="h-6 w-6 text-badger-green" />
		};
        return <CheckIcon className="h-6 w-6 text-badger-green" />
    } else {
		if (isFoul) {
			return <ExclamationTriangleIcon className="h-6 w-6 text-badger-red" />
		}
        return <XMarkIcon className="h-6 w-6 text-badger-red" />
    };
};


export const Key = () => {
    return (
        <SmallAccordion
            title="Symbol Glossary"
            defaultActive={false}
        >
            <div className="border-b pb-2 text-sm mb-4">
                <p className="flex items-center py-1"><CheckIcon className="h-4 w-4 text-badger-green" /> - Made the shot</p>
                <p className="flex items-center py-1"><XMarkIcon className="h-4 w-4 text-badger-red" /> - Failed to pot a ball</p>
                <p className="flex items-center py-1"><span className="italic text-gray-500">N+</span> - Shot has benefit from break split</p>
                <p className="flex items-center py-1"><BoltIcon className="h-4 w-4 text-badger-yellow" /> - Player took the break</p>
                <p className="flex items-center py-1"><ExclamationTriangleIcon className="h-4 w-4 text-badger-green" /> - Made the shot but fouled</p>
                <p className="flex items-center py-1"><ExclamationTriangleIcon className="h-4 w-4 text-badger-red" /> - Missed the shot and fouled</p>
                <p className="flex items-center py-1"><ExclamationTriangleIcon className="h-4 w-4 text-badger-black" /> - Fouled and potted the black (losing the game)</p>
            </div>
        </SmallAccordion>
    );
};


export const ShotByShotReportFouls = ({ simulatedGame, highlightBreakBenefit = true, showBreakPlayer = false }) => {
    return (
        <div className="flex flex-wrap">
            <div
                className={`flex flex-col text-center my-2 ${!showBreakPlayer ? 'border-r' : ''}`}
            >
                <div className="h-6 w-8 text-sm italic text-gray-500 text-center">-</div>
                <div className="h-6 w-8 pr-2 italic border-b">P1</div>
                <div className="h-6 w-8 pr-2 italic">P2</div>
            </div>
            {showBreakPlayer &&
                <div
                    className="flex flex-col text-center my-2 border-r"
                >
                    <div className="h-6 w-6 text-sm italic text-gray-500 text-center"></div>
                    <div className="h-6 w-6 flex items-center justify-start text-center italic border-b">{simulatedGame.breakPlayer === 'player1' ? <BoltIcon className="h-4 w-4 text-badger-yellow" /> : ""}</div>
                    <div className="h-6 w-6 flex items-center justify-start text-center italic">{simulatedGame.breakPlayer === 'player2' ? <BoltIcon className="h-4 w-4 text-badger-yellow" /> : ""}</div>
                </div>
            }
            <div
                className="flex flex-col text-center my-2 border-r"
            >
                <div className="h-6 w-12 text-sm italic text-gray-500 text-center">#</div>
                <div className="h-6 w-12 text-center italic border-b">{simulatedGame.player1Score}</div>
                <div className="h-6 w-12 text-center italic">{simulatedGame.player2Score}</div>
            </div>

            {simulatedGame.shots.map((shot, i) =>
                <div
                    className="flex flex-col text-center my-2"
                    key={i}
                >
                    {/* Add a plus when the spread benefit is active */}
                    <div className="h-6 w-8 text-sm italic text-gray-500 text-center">{i + 1}{highlightBreakBenefit && shot[6] > 0 ? '+' : ''}</div>
                    <div className="h-6 w-8 flex justify-center items-center text-center border-b">{shot[0] === 'player1' ? <FoulOutcomeIcon outcome={shot[1]} isFoul={shot[7]} isGameLosing={shot[7] && shot[8]} /> : ""}</div>
                    <div className="h-6 w-8 flex justify-center items-center text-center">{shot[0] === 'player2' ? <FoulOutcomeIcon outcome={shot[1]} isFoul={shot[7]} isGameLosing={shot[7] && shot[8]}/> : ""}</div>
                </div>
            )}

            <div
                className="flex flex-col text-center my-2 border-l border-r"
            >
                <div className="h-6 w-12 text-sm italic text-gray-500 text-center">#</div>
                <div className="h-6 w-12 text-center italic border-b">{simulatedGame.player1Score}</div>
                <div className="h-6 w-12 text-center italic">{simulatedGame.player2Score}</div>
            </div>
        </div>
    );
};
