import { useState } from "react";

// Component to display and highlight possible colours for points
export const ColourPicker = ({ handleColourChange, display }) => {
    const colours = ["#27ae60", "#f1c40f", "#3498db", "#e67e22", "#e74c3c", "#9b59b6", "#34495e"];

    if (!display) {
        return;
    }

    return (
        <div className="flex flex-row w-full items-center justify-between">
            {colours.map((colour, i) => (
                <span
                    style={{ color: 'white', backgroundColor: colour }}
                    className="h-8 sm:h-auto w-full text-center p-2 hover:cursor-pointer hover:font-bold hover:underline rounded-lg first:ml-0 m-1 last:mr-0"
                    onClick={() => handleColourChange(colour)}
                    key={colour + i}
                >
                    <span className="hidden sm:block">{colour}</span>
                </span>
            ))}
        </div>
    );
};

export const ColourPickerSelector = ({ chartColours, setNewChartColour, centralColourBreakpoint, updateCentralColourBreakdpoint }) => {
    const [selectedColour, updateSelectedColour] = useState(null);

    const selectNewColour = (newPositionId) => {
        if (newPositionId === selectedColour) {
            updateSelectedColour(null);
        } else {
            updateSelectedColour(newPositionId);
        };
    };

    const changeColour = (newColour) => {
        // Update original colour to be the new colour
        setNewChartColour(selectedColour, newColour);
        updateSelectedColour(null);
    };

    return (
        <div className="mb-4">
            <label htmlFor="colourBreakpointRange" className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Colour breakpoint ({centralColourBreakpoint})</label>
            <input
                id="colourBreakpointRange"
                type="range"
                value={centralColourBreakpoint}
                min="0"
                max="100"
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 fill-red-500"
                onChange={(e) => updateCentralColourBreakdpoint(e.target.value)}
            />
            <div className="flex flex-row w-full items-center justify-between">
                {chartColours.map((colour, i) => (
                    <p
                        className={`h-8 sm:h-auto text-center w-full p-2 hover:cursor-pointer rounded-lg first:ml-0 m-2 last:mr-0 ${selectedColour === i ? 'border-4 -px-4 text-black' : 'text-white'}`}
                        style={{ borderColor: colour, backgroundColor: selectedColour === i ? 'white' : colour }}
                        onClick={() => selectNewColour(i)}
                        key={colour + i}
                    >
                        <span className="hidden sm:block">{colour}</span>
                    </p>
                ))}
            </div>
            <ColourPicker display={selectedColour !== null} handleColourChange={changeColour} />
        </div>
    );
}
