import { Link } from "react-router-dom";

export const CricketProfileHeader = ({ title }) => {
    return (
        <>
            <h1 className="capitalize text-3xl pb-2">{title}</h1>
            <hr className="mb-4"></hr>
        </>
    )
};

export const PageHeader = ({ title, subtitle = false, subtitleIsLink = false, linkDestination = "home" }) => {
    if (!subtitleIsLink) {
        return (
            <>
                <h1 className="text-xl sm:text-3xl pb-2 text-center sm:text-left">{title}</h1>
                {subtitle &&
                    <p className="text-sm sm:text-base pb-2 text-center sm:text-left text-gray-500 italic">{subtitle}</p>
                }
                <hr className="mb-4"></hr>
            </>
        );
    };
    return (
        <>
            <h1 className="text-xl sm:text-3xl pb-2 text-center sm:text-left">{title}</h1>
            {subtitle &&
                <p className="text-sm sm:text-base pb-2 text-center sm:text-left text-gray-500 italic">
                    <Link
                        to={linkDestination}
                        className="text-sm sm:text-base pb-2 text-center sm:text-left text-gray-500 italic hover:underline">{subtitle}
                    </Link>
                </p>
            }
            <hr className="mb-4"></hr>
        </>
    );
};


export const ArticleTitle = ({ title }) => {
    return (
        <>
            <h1 className="text-3xl sm:text-3xl pb-2 text-center tracking-tight my-4 sm:my-6">{title}</h1>
            <hr className="mb-4"></hr>
        </>
    );
};


export const H2Heading = ({ children, classes = "" }) => (
    <h2 className={`flex text-center items-center justify-center text-lg sm:text-xl mb-2 tracking-tight ${classes}`}>
        {children}
    </h2>
);


export const H3Heading = ({ children, classes = "" }) => (
    <h3 className={`flex items-center justify-center text-base sm:text-lg mb-2 tracking-tight ${classes}`}>
        {children}
    </h3>
);
