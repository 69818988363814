import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQueries } from "react-query";
import { getPlayerInfo, getPlayerInnings } from '../../api/cricket';
import { AnimatedGraph } from "../../components/graphs/animatedGraph";
import { Loading } from "../../components/Loading";
import { Player } from "../../simulation/pool/Player";
import { PoolSimulationAccuracy } from "../../simulation/pool/PoolSimulation";


export function Playground() {
    const playerName = "joe-root";
    const results = useQueries([
        { queryKey: ["playerDetails", playerName], queryFn: () => getPlayerInfo(playerName) },
        { queryKey: ["playerInnings", playerName], queryFn: () => getPlayerInnings(playerName) },
      ]);

	const [filteredPlayers, updateFilteredPlayers] = useState([]);
    const [playerDetails, updatePlayerDetails] = useState({});

    const isLoading = results.some(query => query.isLoading);
    const isError = results.some(query => query.isError);
    const errors = results.map((query) => {
        return query.error;
    });
    const dataStatuses = results.map((query) => {
        return query.status
    });

	// Wait for query to complete
	useEffect(() => {
		// If the query hasn't errored then update the array
		if(!isLoading && !isError) {
			updatePlayerDetails(results[0]['data']['data']);
			updateFilteredPlayers(results[1]['data']['data']);
		}
	}, [results, isLoading, isError]); // React to these variables changing

	if (isLoading) {
		return <Loading></Loading>
	};

    if (isError) {
        return <div>Error:
            <ol>
                {errors.filter((e) => e != null).map(error => <>
                    <li key={JSON.stringify(error)}>{error.message}</li>
                </>
                )}
            </ol>
        </div>
    };

    // Test OOP Pool Simulations
    const PlayerA = new Player("Will");
    const PlayerB = new Player("Fred");
    const simulation = new PoolSimulationAccuracy(PlayerA, PlayerB);

    return <>
        <Helmet>
            <title>Dynamic D3.js Graph | VizBadger</title>
            <meta
                name="description"
                content="Interact with a dynamic graph that automatically scales to device size with D3.js."
            ></meta>
            <meta name="robots" content="noindex,nofollow"></meta>
        </Helmet>
        {JSON.stringify(dataStatuses)}
        <hr></hr>
        {JSON.stringify(playerDetails)}
        <hr></hr>
        {JSON.stringify(filteredPlayers)}
    </>
};


const data = [{
    x1: 0, x2: 1, y: 20
},
{
    x1: 2, x2: 2, y: 8
},{
    x1: 1, x2: 2, y: 6
},{
    x1: -1, x2: 10, y: 8
},{
    x1: 100, x2: 3, y: 2
}];

const xAccessor = d => d.x1;
const yAccessor = d => d.y;

export const AnimatedPlayground = () => {
    return (
        <div>
            <AnimatedGraph
                data={data}
                chartSettings={{ height: 400, marginBottom: 30, marginLeft: 30 }}
                xAccessor={xAccessor}
                yAccessor={yAccessor}
            />
        </div>
    );
};
