import { DynamicGraph } from "../../components/graphs/DynamicGraph";
import { ColourPickerSelector } from "../../components/graphs/ColourPicker";
import { ArticleTitle, H2Heading } from "../../components/Headers";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { PageBody } from "../../components/PageBody";
import { Gauge } from "../../components/graphs/Gauge";

export const DynamicD3 = () => {
	const [chartSettings, updateChartSettings] = useState({ height: 300, marginLeft: 40 });
	const [chartColours, updateChartColours] = useState(["#e74c3c", "#f1c40f", "#27ae60"]);
	const [centralColourBreakpoint, updateCentralColourBreakdpoint] = useState(50);
	const [gaugeGroups, updateGaugeGroups] = useState([
		{ label: "Green", colour: "#27ae60", value: 0.9 },
		{ label: "Blue", colour: "#3498db", value: 0.7 },
		{ label: "Purple", colour: "#9b59b6", value: 0.65 },
		{ label: "Red", colour: "#e74c3c", value: 0.81 },
	]);

	const setNewChartColour = (positionId, newColour) => {
		chartColours[positionId] = newColour;
		updateChartColours([...chartColours]);
	};

	const updateGroupValues = () => {
		for (let index = 0; index < gaugeGroups.length; index++) {
			gaugeGroups[index]['value'] = Math.random();
		};

		updateGaugeGroups([...gaugeGroups]);
	};

	const updateChartHeight = () => {
		if (chartSettings.height === 300) {
			updateChartSettings({ ...chartSettings, height: 400 })
		} else {
			updateChartSettings({ ...chartSettings, height: 300 })
		};
	};

	return (
		<PageBody>
			<Helmet>
				<title>Dynamic D3.js Graph | VizBadger</title>
				<meta name="description" content="Experience the magic of dynamic data visualisation with our JavaScript D3.js components! Our responsive designs ensure that interactive charts and graphs scale seamlessly for any device size. Discover how to create your own stunning dynamic visuals today, perfect for showcasing data insights."></meta>
				<meta name="keywords" content="D3 programming, D3 visuals, scalable visuals, data visualisation"></meta>
                <link rel="canonical" href="https://www.vizbadger.com/data-visualisation/dynamic-d3-components" />
			</Helmet>
			<ArticleTitle title="Dynamic D3.js Graph" />
			<div className="mb-4 text-sm sm:text-base">
				<p className="mb-2">Graphics have a range of available tools to display information. Colour, form and scale all play a part in that. Below is a scatterplot with random data points to illustrate dynamic graphs for online use. Scaling is important. A graph must be visible, more than ever, on smaller screens but shouldn't break on a desktop of landscape view, visualisations must be responsive to ensure optimal user experience. From scatter plots to bar graphs, ensuring the scalability of your visuals is key to users being able to effectively unlock the insights hidden within the data our are presenting.</p>
				<p>This example will scale with width changes as you resize the window (but no larger than the HTML page's constraints). You can update the height as well by toggling the button below. Note how the actual graph gets larger, but the scales also adjust, adding and removing ticks if necessary to stay legible.</p>
			</div>
			<hr className="mb-4" />
			<DynamicGraph
				chartSettings={chartSettings}
				updateChartHeight={updateChartHeight}
				chartColours={chartColours}
				centralColourBreakpoint={centralColourBreakpoint}
			/>
			<div>
				<H2Heading classes="mt-3">Dynamic Colour Scale</H2Heading>
				<p className="text-sm sm:text-base text-center mb-2">Colour scales play a crucial role in data visualisation by allowing viewers to easily distinguish between different data points and identify patterns and trends. Color can also be used to highlight important information and draw attention to specific areas of a visualisation (try selecting 2 grays an a red with the picker). Over or misuse of colour scales can make visualisations confusing and difficult to interpret. The example I've produced is more as a technical D3.js integration rather than a guide about use of colour palettes.</p>
				<p className="text-sm text-center">Use the selections below to update the colours immediately to see how the points change colour.</p>
				<ColourPickerSelector
					chartColours={chartColours}
					setNewChartColour={setNewChartColour}
					centralColourBreakpoint={centralColourBreakpoint}
					updateCentralColourBreakdpoint={updateCentralColourBreakdpoint}
				/>
			</div>
			<hr className="mb-4" />
			<div className="flex flex-col items-center justify-center">
				<p className="text-lg sm:text-xl mb-2">Gauge Concept Visual</p>
				<p
					className="mb-6 sm:mb-4 rounded-lg px-2 py-1 bg-badger-green text-white font-semibold cursor-pointer"
					onClick={() => updateGroupValues()}
				>
					Randomise Values
				</p>
				<div className="w-full">
					<Gauge
						groups={gaugeGroups}
						chartSettings={{ height: 310, marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5 }}
					/>
				</div>
			</div>
		</PageBody>
	);
};
