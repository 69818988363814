import { useEffect, useState, useRef } from "react";
import { getPlayerInnings } from "../../api/cricket";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import { CricketGraph, RunDistributionGraph } from "../../components/graphs/cricketGraphs";
import { CricketProfileHeader, H2Heading } from "../../components/Headers";
import { Helmet } from "react-helmet-async";
import { PageBody } from "../../components/PageBody";
import { Loading } from "../../components/Loading";


const CareerSummaryTable = ({ careerStats }) => {
	if (careerStats === null) {
		return <></>
	};

	return <table className="w-full text-sm sm:text-base text-center mb-4 border border-gray-100">
			<tbody>
				<tr className="bg-gray-100 whitespace-nowrap">
					<td className="p-2">Innings</td>
					<td className="p-2">Not Outs</td>
					<td className="p-2">Runs Scored</td>
					<td className="p-2">Balls</td>
					<td className="p-2">Average</td>
					<td className="p-2">SR</td>
					<td className="p-2">100s</td>
					<td className="p-2">50s</td>
					<td className="p-2">4s</td>
					<td className="p-2">6s</td>
				</tr>
				<tr>
					<td className="p-2">{careerStats.did_bat.toLocaleString()}</td>
					<td className="p-2">{careerStats.not_outs.toLocaleString()}</td>
					<td className="p-2">{careerStats.runs.toLocaleString()}</td>
					<td className="p-2">{careerStats.balls_faced.toLocaleString()}</td>
					<td className="p-2">{careerStats.average.toLocaleString()}</td>
					<td className="p-2">{careerStats.strike_rate.toLocaleString()}</td>
					<td className="p-2">{careerStats.centuries.toLocaleString()}</td>
					<td className="p-2">{careerStats.fifties.toLocaleString()}</td>
					<td className="p-2">{careerStats.fours.toLocaleString()}</td>
					<td className="p-2">{careerStats.sixes.toLocaleString()}</td>
				</tr>
			</tbody>
		</table>
};

function groupByKey(array, key) {
	let groupedArray = [];
	let groupedObject = array
		.reduce((hash, obj) => {
			// If the object doesn't contain the groupBy key, return the object
			if (obj[key] === undefined) return hash;
			return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
		}, {});

	for (const [groupKey, value] of Object.entries(groupedObject)) {
		if (!isNaN(groupKey)) {
			groupedArray.push({ [key]: parseFloat(groupKey), innings: value });
		} else {
			groupedArray.push({ [key]: groupKey, innings: value });
		}
	};

	return aggregateInnings(groupedArray);
};


function aggregateInnings(groupedArray) {
	for (let ind = 0; ind < groupedArray.length; ind++) {
		let runs = 0;
		let ballsFaced = 0;
		let didBat = 0;
		let fours = 0;
		let sixes = 0;
		let outs = 0;
		let fifties = 0;
		let centuries = 0;

		const group = groupedArray[ind];
		group['innings'].forEach(innings => {
			runs += innings['runs'];
			ballsFaced += innings['balls_faced'];
			didBat += innings['did_bat'];
			fours += innings['fours'];
			sixes += innings['sixes'];
			outs += innings['is_out'];
			fifties += innings['is_fifty'];
			centuries += innings['is_century'];
		});

		groupedArray[ind]['summary'] = {
			'runs': runs,
			'balls_faced': ballsFaced,
			'did_bat': didBat,
			'not_outs': didBat - outs,
			'strike_rate': 100.0*runs/ballsFaced,
			'average': runs/outs,
			'fours': fours,
			'sixes': sixes,
			'outs': outs,
			'fifties': fifties,
			'centuries': centuries
		}
	};

	return groupedArray;
};

const careerSummary = (inningsArray) => {
	let runs = 0;
	let ballsFaced = 0;
	let didBat = 0;
	let fours = 0;
	let sixes = 0;
	let outs = 0;
	let fifties = 0;
	let centuries = 0;

	inningsArray.forEach(innings => {
		runs += innings['runs'];
		ballsFaced += innings['balls_faced'];
		didBat += innings['did_bat'];
		fours += innings['fours'];
		sixes += innings['sixes'];
		outs += innings['is_out'];
		fifties += innings['is_fifty'];
		centuries += innings['is_century'];
	});

	return {
		'runs': runs,
		'balls_faced': ballsFaced,
		'did_bat': didBat,
		'not_outs': didBat - outs,
		'strike_rate': 100.0*runs/ballsFaced,
		'average': runs/outs,
		'fours': fours,
		'sixes': sixes,
		'outs': outs,
		'fifties': fifties,
		'centuries': centuries
	};
};


function OptionButton(props) {
	const commonClasses = "w-full sm:w-1/5 text-center p-2 my-1 hover:cursor-pointer";

	if (props.isActive) {
		return (
			<div
				className={`${commonClasses} bg-badger-green text-white`}
			>
				{props.buttonText}
			</div>
		)
	}

	return (
		<div
			className={`${commonClasses} bg-gray-100 hover:bg-gray-200`}
			onClick={(e) => props.clickFunction(props.clickFunctionInput)}
		>
			{props.buttonText}
		</div>
	);
};


const formatNameFromSlug = (urlSlug) => {
	return urlSlug.replace('-', ' ').toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
}

export function TestCricketProfile() {
	let { playerName } = useParams();
	// https://stackoverflow.com/questions/68105012/what-is-the-correct-way-to-pass-parameters-to-a-react-query-usequery-method-that
	const { isLoading, isError, data, error } = useQuery(["playerInnings", playerName], () => getPlayerInnings(playerName));
	const [aggregate, updateAggregate] = useState([]);

	const breakdownOptions = [
		{
			key:"match_year",
			display:"Year"
		},
		{
			key:"opposition",
			display:"Opposition"
		},
		{
			key:"batting_position",
			display:"Batting Pos."
		},
		{
			key:"ground",
			display:"Ground"
		},
		{
			key:"host_country",
			display:"Host Country"
		},
	];

	const [activeBreakdown, updateActiveBreakdown] = useState(breakdownOptions[0]);
	const ref = useRef({});
	const [isMobileView, updateIsMobileView] = useState(false);
	const [careerStats, updateCareerStats] = useState(null);
	const [runBinData, updateRunBinData] = useState(null);
	const [innings, setInnings] = useState(null);
	// const [runBinDataBase, updateRunBinDataBase] = useState(null);

	useEffect(() => {
		if (ref.current.offsetWidth < 450) {
			updateIsMobileView(true);
		};

		const handleResize = () => {
			if (ref.current.offsetWidth > 450) {
				updateIsMobileView(false);
			} else {
				updateIsMobileView(true);
			};
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [ref.current.offsetWidth]);

	// Wait for query to complete
	useEffect(() => {
		// If the query hasn't errored then update the array
		if(!isError && !isLoading) {
			setInnings(data.data);
			updateAggregate(groupByKey(data.data, activeBreakdown.key));
			const updateCS = careerSummary(data.data);
			updateCareerStats(updateCS);
			updateRunBinData(groupByKey(data.data, "run_bin"));
			// const baseRunBin = groupByKey(data.data, "run_bin").map(obj => {
			// 	obj.summary.did_bat = 0
			// 	return obj
			// });
			// updateRunBinDataBase(baseRunBin);
		}
	// eslint-disable-next-line
	}, [data, isError, isLoading, isMobileView, ref.current.offsetWidth]); // React to these variables changing

	function refreshGroupedData(breakdownOption) {
		updateActiveBreakdown(breakdownOption);
		updateAggregate(groupByKey(data.data, breakdownOption.key));
	};

	// const updateBaseData = () => {
	// 	const newData = runBinDataBase.map(obj => {
	// 		obj.summary.did_bat += 2
	// 		return obj
	// 	});
	// 	updateRunBinDataBase(newData);
	// };

	if (isLoading) {
		return <Loading></Loading>
	};

	if (isError) {
		return <span>Error: {error.message}</span>
	};

	if (runBinData === null | innings === null) {
		return <Loading></Loading>
	};

	return (
		<PageBody widthClass="max-w-screen-lg">
			<Helmet>
				<title>{formatNameFromSlug(playerName)} | Cricket Test Match Batting Career | VizBadger</title>
				<meta
					name="description"
					content={`Explore and analyse ${formatNameFromSlug(playerName)}'s test match batting career through data visualisations and analytics.`}
				></meta>
				<meta name="keywords" content={`Mens Test Cricket, Test Cricket Average, Test Cricket Hundreds, ${formatNameFromSlug(playerName)} Top Score, ${formatNameFromSlug(playerName)} Test Average, ${formatNameFromSlug(playerName)} Test Dismissals`}></meta>
            </Helmet>
			<p className="text-sm italic text-gray-500 mb-3">
				<Link to="/cricket" className="hover:text-badger-blue">Test Players</Link> &gt; <span className="capitalize">{playerName.replace('-', ' ')}</span>
			</p>
			<CricketProfileHeader title={playerName.replace('-', ' ')} />
			<p className="italic text-sm sm:text-base mb-3">Explore the test match batting career of <span className="capitalize">{playerName.replace('-', ' ')}</span>. View breakdowns of his career by year, opponent and match location.</p>
			{(!isError && !isLoading) &&
				<div className="w-full overflow-x-auto">
					<H2Heading>Test Career Summary</H2Heading>
					<CareerSummaryTable careerStats={careerStats} />
				</div>
			}
			<H2Heading classes="mt-4">Career Innings Visualised</H2Heading>
			<div className="flex sm:flex-row flex-col">
				<CricketGraph
					data={data}
					chartSettings={{ height: 350, marginBottom: 65 }}
					xAccessor={d => d.balls_faced}
					yAccessor={d => d.runs}
					divClasses="sm:w-1/2 w-full"
				/>
				<RunDistributionGraph
					data={runBinData}
					// baseData={runBinDataBase}
					chartSettings={{ height: 350, marginBottom: 65 }}
					xAccessor={d => d.run_bin}
					yAccessor={d => d.summary.did_bat}
					divClasses="sm:w-1/2 w-full"
				/>
			</div>
			<div>
				<H2Heading classes="mt-4">Innings Breakdowns</H2Heading>
				<p className="text-sm text-gray-500 italic">Select grouping to view career splits</p>
				<div className="flex flex-row flex-wrap">
					{breakdownOptions.map(breakdownOption =>
						<OptionButton
							buttonText={breakdownOption.display}
							isActive={breakdownOption.key === activeBreakdown.key}
							clickFunction={refreshGroupedData}
							clickFunctionInput={breakdownOption}
							key={breakdownOption.key}
						/>
					)}
				</div>
				<div className="w-full overflow-x-auto">
					<table className="w-full text-sm sm:text-base text-center my-4">
						<thead>
							<tr className="bg-gray-100 whitespace-nowrap font-medium">
								<td className="p-2">{activeBreakdown.display}</td>
								<td className="p-2">Innings</td>
								<td className="p-2">Runs</td>
								<td className="p-2">Balls</td>
								<td className="p-2">Average</td>
								<td className="p-2">SR</td>
								<td className="p-2">50s</td>
								<td className="p-2">100s</td>
							</tr>
						</thead>
						<tbody className="overflow-x-auto">
							{aggregate.map(grouping =>
								<tr key={grouping[activeBreakdown.key]}>
									<td >{grouping[activeBreakdown.key]}</td>
									<td className="p-2 sm:p-1">{grouping.summary.did_bat}</td>
									<td className="p-2 sm:p-1">{grouping.summary.runs.toLocaleString()}</td>
									<td className="p-2 sm:p-1">{grouping.summary.balls_faced.toLocaleString()}</td>
									<td className="p-2 sm:p-1">{(grouping.summary.runs / grouping.summary.outs).toFixed(2)}</td>
									<td className="p-2 sm:p-1">{(100.0 * grouping.summary.runs / grouping.summary.balls_faced).toFixed(2)}</td>
									<td className="p-2 sm:p-1">{grouping.summary.fifties}</td>
									<td className="p-2 sm:p-1">{grouping.summary.centuries}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			<div className="relative">
				<H2Heading classes="mt-4">All Test Match Innings</H2Heading>
				<table className="w-full text-sm sm:text-base text-center my-4">
					<thead>
						<tr className="bg-gray-100 whitespace-nowrap font-medium sticky top-18">
							<td className="p-2">Runs</td>
							<td className="p-2">Balls</td>
							<td className="p-2">4s</td>
							<td className="p-2">6s</td>
							<td className="p-2">SR</td>
							<td className="p-2">Pos.</td>
							<td className="p-2">Dismissal</td>
							<td className="p-2">Match Inns.</td>
							<td className="p-2">Opposition</td>
							<td className="p-2">Match Date</td>
						</tr>
					</thead>
					<tbody className="text-sm sm:text-sm">
						{innings.map((inning, i) =>
							<tr key={i}>
								<td className="p-1">{inning.runs}{inning.did_bat & !inning.is_out ? '*' : ' '}</td>
								<td className="p-1">{inning.balls_faced}</td>
								<td className="p-1">{inning.fours}</td>
								<td className="p-1">{inning.sixes}</td>
								<td className="p-1">{inning.strike_rate}</td>
								<td className="p-1">{inning.batting_position}</td>
								<td className="p-1">{inning.dismissal}</td>
								<td className="p-1">{inning.match_inns}</td>
								<td className="p-1">{inning.opposition}</td>
								<td className="p-1">{inning.match_start_date}</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</PageBody>
	);
};
