export const QuoteBlock = ({ quoteText }) => (
    <div className="flex items-center justify-center w-full my-4">
        <div className="max-w-3xl w-full p-2 text-gray-800 bg-gray-100 rounded-lg shadow">
            <div>
                <div className="h-3 text-3xl text-left text-gray-600">“</div>
                <p className="px-4 text-sm sm:text-base text-center text-gray-800">
                    {quoteText}
                </p>
                <div className="h-3 text-3xl text-right text-gray-600 flex items-center justify-end">”</div>
            </div>
        </div>
    </div>
);
