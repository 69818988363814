import { CheckIcon, ExclamationTriangleIcon, FireIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { H2Heading } from '../../../../components/Headers';
import { displayPercentage } from "../../../../helpers";


export const BasicShotOutcomeIcon = ({ outcome }) => {
    if (outcome) {
        return <CheckIcon className="h-6 w-6 text-badger-green" />
    } else {
        return <XMarkIcon className="h-6 w-6 text-badger-red" />
    };
};


export const GameShotSummary = ({ simulatedGame, showFouls = false }) => (
    <div className="flex items-center justify-center flex-col">
        <H2Heading>Shot Summary</H2Heading>
        <div className="flex flex-wrap">
            <div
                className="flex flex-col text-center my-2 border-r"
            >
                <div className="h-6 w-8 text-sm italic text-gray-500 text-center font-bold flex items-center justify-center">-</div>
                <div className="h-6 w-8 text-center italic border-b">P1</div>
                <div className="h-6 w-8 text-center italic">P2</div>
            </div>
            <div
                className="flex flex-col text-center my-2 border-l border-r"
            >
                <div className="h-6 w-12 text-sm italic text-gray-500 text-center font-bold flex items-center justify-center">#</div>
                <div className="h-6 w-12 text-center italic border-b">{simulatedGame.player1Score}</div>
                <div className="h-6 w-12 text-center italic">{simulatedGame.player2Score}</div>
            </div>

            {/* Makes and Misses */}
            <div
                className="flex flex-col text-center my-2"
            >
                <div className="h-6 w-8 flex justify-center items-center flex items-center justify-center"><BasicShotOutcomeIcon outcome={true} /></div>
                <div className="h-6 w-8 text-center italic border-b">{simulatedGame.player1ShotsMade}</div>
                <div className="h-6 w-8 text-center italic">{simulatedGame.player2ShotsMade}</div>
            </div>

            <div
                className="flex flex-col text-center my-2 border-r"
            >
                <div className="h-6 w-8 flex justify-center items-center flex items-center justify-center"><BasicShotOutcomeIcon outcome={false} /></div>
                <div className="h-6 w-8 text-center italic border-b">{simulatedGame.player1Shots - simulatedGame.player1ShotsMade}</div>
                <div className="h-6 w-8 text-center italic">{simulatedGame.player2Shots - simulatedGame.player2ShotsMade}</div>
            </div>

            <div
                className="flex flex-col text-center my-2 border-r"
            >
                <div className="h-6 px-2 text-sm italic text-gray-500 text-center font-bold flex items-center justify-center">%</div>
                <div className="h-6 px-2 text-center italic border-b">{displayPercentage(simulatedGame.player1Shots > 0 ? simulatedGame.player1ShotsMade / simulatedGame.player1Shots : 0)}</div>
                <div className="h-6 px-2 text-center italic">{displayPercentage(simulatedGame.player2Shots > 0 ? simulatedGame.player2ShotsMade / simulatedGame.player2Shots : 0)}</div>
            </div>
            {showFouls &&
                <div
                    className="flex flex-col text-center my-2 border-r"
                >
                    <div className="h-6 px-2 text-sm italic text-gray-500 text-center font-bold flex items-center justify-center"><ExclamationTriangleIcon className="h-6 w-6 text-gray-500" /></div>
                    <div className="h-6 px-2 text-center italic border-b">{simulatedGame.player1Fouls}</div>
                    <div className="h-6 px-2 text-center italic">{simulatedGame.player2Fouls}</div>
                </div>
            }
            <div
                className="flex flex-col text-center my-2"
            >
                <div className="h-6 px-2 text-sm italic text-gray-500 text-center flex items-center justify-center"><FireIcon className="h-6 w-6 text-badger-orange" /></div>
                <div className="h-6 px-2 text-center italic border-b">{simulatedGame.player1TopStreak}</div>
                <div className="h-6 px-2 text-center italic">{simulatedGame.player2TopStreak}</div>
            </div>
        </div>
    </div>
);
