import { ArticleTitle, H2Heading } from "../../../components/Headers";
import { PageBody } from "../../../components/PageBody";
import { Helmet } from "react-helmet-async";
import { ArticleParagraph } from "../../../components/Paragraphs";
import { SportsModelsHomeButton } from "../../../components/HomeLinks";
import { GridDiv, GridDivContainer } from "../../../components/GridDiv";
import { FlagIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";


export const GolfHome = () => {
    return (
        <PageBody>
            <Helmet>
                <title>Predicting Golf Results | VizBadger</title>
                <meta
                    name="description"
                    content="Predict the outcome of a golf event using monte carlo simulations and modeling a round of golf programmatically. Apply statistics and knowledge of players to calculate the likelihood of things happening and results."
                ></meta>
                <meta name="keywords" content="Sports models, sports prediction, golf modeling, golf tips, golf stats, golf predictions, predictive analytics, sports analytics, monte carlo simulation, sports machine learning"></meta>
            </Helmet>

            <SportsModelsHomeButton />

            <ArticleTitle
                title="How To Predict Golf Tournaments"
            />

            <ArticleParagraph
                lines={[
                    "What is the best way to tell whether someone is likely to win a golf tournament? That is what I'm going to explore.",
                    "As with my other Monte Carlo predictions, I will approach this golf model incrementally. I find that this is a good way to work and makes the potentially daunting task easier to digest. By starting simply you will produce a model that ‘predicts’.",
                    "It will not necessarily be very good, but is likely better than no model at all. Iterating the model can then help add complexity, and change your predictions, making them more nuanced and taking into consideration more factors.",
                    "The end goal is to produce a model that works with known statistics and predicts a range of outcomes for what might happen; given they are 2 shots off the lead, with 14 holes left, what is the likelihood that they will win the tournament? Which golfers are likely to do well on this course?",
                ]}
            />

            <H2Heading>Our starting point</H2Heading>
            <ArticleParagraph
                lines={[
                    "The simplest way to predict golf is to just predict the final round score of a player without any course context. On a par 72 course, how likely are they to shoot a 68? You can work this out using just their average round score, and a measure of the spread of their scores (how reliably they play). This model will have high bias as you are oversimplifying the data that you’re working with; we are compressing the complicated systems into 2 numbers.",
                ]}
            />

            <GridDivContainer>
                <GridDiv
                    widthClasses="w-full sm:w-1/2"
                >
                    <Link
                        to="/sports-models/golf/round-prediction"
                        className="sm:w-auto w-full border border-gray-200 p-4 shadow rounded-lg lg:mx-0 mt-4 sm:mt-6 flex justify-center items-center cursor-pointer hover:shadow-lg"
                    >
                        <FlagIcon className="h-7 w-7 mr-2 text-badger-red" />
                        <span className="mr-2 text-center text-black w-full">How To Predict A Golf Round</span>
                    </Link>
                </GridDiv>
            </GridDivContainer>
        </PageBody>
    );
};
