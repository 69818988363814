export class Player {
    constructor(name) {
        this.name = name;
    };

    greeting = () => {
        return this.name;
    };
};

