export const GridDivContainer = ({ children, className }) => (
    <div className={`flex flex-wrap -mx-2 ${className}`}>
        {children}
    </div>
);

export const GridDiv = ({ children, widthClasses = "w-full sm:w-1/2 md:w-1/3" }) => (
    <div
        className={`px-2 ${widthClasses}`}
    >
        {children}
    </div>
);